import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { getAPI, getAPIAuth } from "../../../services/apiInstance";
import { RESPONSIVE_WIDTH } from "../../../constants";
import GameCard from "../../../components/GameCard/GameCard";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NoData from "../../../components/noData/NoData";

const TrendingGames = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userDetails = useSelector((state) => state.userDetails);
  const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);

  const { t } = useTranslation();
  const favouriteLabels = t("favouriteLabels", { returnObjects: true });
  const commonText = t("commonText", { returnObjects: true });
  const getSports = async () => {
    setIsLoading(true);
    try {
      const res = await getAPI("games/popular-games");
      setData(res?.data?.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSports();
  }, []);

  // const updateFavGame = async () => {
  //     try {
  //         const res = await getAPIAuth(
  //             `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`
  //         );
  //         setGameList(res.data.data);
  //     } catch (error) {
  //         //   errorToaster(error.message);
  //     }
  // };
 

  return (
    <>
      <div className="row row-cols-3 row-cols-sm-5 row-cols-xl-6 g-2 g-md-3 gameCardGrid sectionSpacing">
        {!isLoading ? (
          data?.length > 0 ? (
            data?.map((item) => {
              return (
                <div
                  key={item?._id}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <GameCard data={item} />
                </div>
              );
            })
          ) : (
            <NoData />
          )
        ) : (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" size="sm" />
          </div>
        )}
      </div>
    </>
  );
};

export default TrendingGames;
