import React, { useEffect, useState } from "react";
import LiveSportsSection from "./components/LiveSportsSection";
import BonusSlider from "./components/BonusSlider";
import DesktopHero from "./components/DesktopHero/DesktopHero";
import { useMediaQuery } from "../../utils/useMediaQuery";
import {
  RESPONSIVE_WIDTH,
  AUTH_MODAL_TABS,
  ROUTE_CONST,
} from "../../constants";
import BetTable from "../../components/betTable/BetTable";
import DepositBanner from "../../components/depositBanner/DepositBanner";
import livsports from "../../assets/images/casino.png";
import popularGames from "./../../assets/images/populargame.png";
import CasinoSlider from "../../components/casinoSlider/CasinoSlider";
import SportsSlider from "../../components/sportsSlider/SportsSlider";
import { getAPI, getAPIAuth } from "../../services/apiInstance";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useTranslation } from "react-i18next";
import SearchPopNew from "../../components/searchpop/SearchPopNew";
import SkeletonLoader from "../../components/skeletonLoader/SkeletonLoader";
import SportsBoxSec from "./././components/DesktopHero/SportsBoxSec";
import NewSportsBoxSec from "./components/DesktopHero/NewSportsBoxSec";
import SportsBannerSec from "./components/DesktopHero/SportsBannerSec";
import NewSportsSlider from "../../components/sportsSlider/NewSportsSlider";
import TopEventWidgets from "./components/DesktopHero/TopEventWidgets";
import LiveEventWidgets from "./components/DesktopHero/LiveEventWidgets";
import UpperCategories from "../../components/sportsSlider/UpperCategories";
import { set } from "date-fns";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import FavGames from "../../components/sportsSlider/FavGames";
import SpecialGamesSlide from "../../components/sportsSlider/SpecialGamesSlide";

const Home = () => {
  const { setAuthModalObject } = useAuthModal();
  const { token } = useAuth();
  const userDetails = useSelector((state) => state.userDetails);
  const isLgScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);
  const isSMScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);
  const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);
  const [games, setGames] = useState([]);
  const [gamesLoading, setGamesLoading] = useState(false);
  const [allCats, setAllCats] = useState([]);
  const [specialCategory, setSpecialCategory] = useState([]);
  const [allCatsLoading, setAllCatsLoading] = useState(false);
  const [selecedSpecialCat, setSelectedSpecialcat] = useState("");
  const [selectedSpecialCatName, setSelectedSpecialCatName] = useState("");
  const [selectedSpecialCatImage, setSelectedSpecailcatImage] = useState("");
  const [specialGames, setSpecialGames] = useState([]);
  const [isLoadingSpecialCat, setIsLoadingSpecialCat] = useState(true);
  const [selectedUpperCategory, setSelectedUpperCategory] = useState({
    name: "Slots",
  });
  const [favGameList, setFavGameList] = useState([]);
  const [isFavGamesLoading, setIsFavGamesLoading] = useState(true);
  const { t } = useTranslation();
  const casinoLabels = t("casinoLabels", { returnObjects: true });

  const handleRegister = () => {
    setAuthModalObject((prev) => ({
      isAuthOpen: true,
      selectedTab: AUTH_MODAL_TABS.SIGN_UP,
    }));
  };

  const getSpecialCategories = async () => {
    try {
      const res = await getAPI("/get-special-category");
      if (res.data.success) {
        setSpecialCategory(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSpecialCatGames = async  () =>{
    try {
      const res = await getAPI("/get-games-by-special-category");
      console.log({res},"specila cate gamssssssss")
      if(res.data.success){
        setSpecialGames(res.data.data)
      }
    } catch (error) {
      console.log(error)

    }
  }

  const getGamesBySpecailCatId = async () => {
    try {
      setIsLoadingSpecialCat(true);
      const res = await getAPI(
        `/get-games-by-special-category/${selecedSpecialCat}`
      );

      if (res.data.success) {
        setSpecialGames(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSpecialCat(false);
    }
  };

  const getGames = async () => {
    setGamesLoading(true);
    try {
      const res = await getAPI(
        `games/top-rated?is_mobile=${isSMScreen ? "true" : "false"}&limit=2`
      );
      setGames(res?.data?.data);
    } catch (error) {
    } finally {
      setGamesLoading(false);
    }
  };

  useEffect(() => {
    // getSpecialCategories();
    getSpecialCatGames()
  }, []);

  useEffect(() => {
    if (selecedSpecialCat) {
      getGamesBySpecailCatId();
    }
  }, [selecedSpecialCat]);

  useEffect(() => {
    getGames();
  }, [isSMScreen]); //eslint-disable-line

  const getAllGames = async () => {
    setAllCatsLoading(true);
    try {
      const res = await getAPI(
        `games/get-games-by-cat?is_mobile=${
          isSMScreen ? "true" : "false"
        }&gameLimit=10`
      );
      if (res?.data?.success) {
        setAllCats(res?.data?.data);
      } else {
        setAllCats([]);
      }
    } catch (error) {
      setAllCats([]);
    } finally {
      setAllCatsLoading(false);
    }
  };

  useEffect(() => {
    if (specialCategory.length > 0) {
      setSelectedSpecialcat(specialCategory[0]._id);
      setSelectedSpecialCatName(specialCategory[0].category_name);
      setSelectedSpecailcatImage(specialCategory[0].image);
    }
  }, [specialCategory]);

  useEffect(() => {
    getAllGames();
  }, []);

  useEffect(() => {
    if (token && userDetails) {
      getFavGames();
    }
  }, [token, userDetails]);


  

  const getFavGames = async () => {
    try {
      setIsFavGamesLoading(true);
      const res = await getAPIAuth(
        `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${
          isMobile ? "true" : "false"
        }`
      );

      setFavGameList(res.data.data);
    } catch (error) {
    } finally {
      setIsFavGamesLoading(false);
    }
  };

  console.log({ favGameList });


  return (
    <>
      {/* {!isLgScreen ? <DesktopHero /> : <BonusSlider />} */}
      {/* {true ? <DesktopHero /> : <BonusSlider />} */}
      <DesktopHero />
      <SportsBoxSec
        selectedUpperCategory={selectedUpperCategory}
        setSelectedUpperCategory={setSelectedUpperCategory}
      />

      {/* <LiveEventWidgets /> */}

      {<UpperCategories selectedUpperCategory={selectedUpperCategory} />}

      {token && favGameList.length ? (
        <FavGames
          isFavGamesLoading={isFavGamesLoading}
          favGameList={favGameList}
        />
      ) : (
        ""
      )}

      <TopEventWidgets />

      {/* <RecentBigWin /> */}

      {!gamesLoading ? (
        <CasinoSlider
          title={"Casino"}
          icon={livsports}
          nextBtn={"casinoNext1"}
          prevBtn={"casinoPrev1"}
          showTabs={true}
          data={games}
          loading={gamesLoading}
        />
      ) : (
        <SkeletonLoader type={"gameCategory"} multiple={true} />
      )}

      {/* {isLgScreen && <LiveSportsSection />} */}

      <SportsSlider />

{
  specialGames.map((cat) =>{
     return <SpecialGamesSlide  cat={cat} />

  })
}

      {/* <NewSportsBoxSec
        selecedSpecialCat={selecedSpecialCat}
        setSelectedSpecialcat={setSelectedSpecialcat}
        setSelectedSpecialCatName={setSelectedSpecialCatName}
        specialCategory={specialCategory}
        setSelectedSpecailcatImage={setSelectedSpecailcatImage}
      /> */}

      {/* <SportsBannerSec
        selectedSpecialCatImage={selectedSpecialCatImage}
        specialGames={specialGames}
        isLoadingSpecialCat={isLoadingSpecialCat}
        selectedSpecialCatName={selectedSpecialCatName}
        selecedSpecialCat={selecedSpecialCat}
      /> */}

      <NewSportsSlider games={games} />

      {isLgScreen && <DepositBanner />}

      {!allCatsLoading ? (
        allCats.length ? (
          allCats.map((item, index) => (
            <CasinoSlider
              data={item?.data}
              loading={allCatsLoading}
              title={item?.name?.category_name}
              icon={item?.name?.image}
              nextBtn={`casinoNext${index + 2}`}
              prevBtn={`casinoPrev${index + 2}`}
              showTabs={false}
              navigateUrl={ROUTE_CONST.CASINO}
              selected_id={item?._id}
            />
          ))
        ) : null
      ) : (
        <>
          {[1, 1, 1, 1]?.map((item) => (
            <SkeletonLoader type={"gameCategory"} multiple={true} />
          ))}
        </>
      )}

      {!isLgScreen && <DepositBanner />}
      <div className="col-12">
        <BetTable />
      </div>
      {/* <SearchPopNew/> */}
    </>
  );
};
export default Home;
