import React, { useEffect, useState } from "react";
import livsports from "../../assets/images/home/Popular-Games.png";
// import card1 from '../../assets/images/myBets.png'
// import card2 from '../../assets/images/soccer.png'
// import card3 from '../../assets/images/basketball.png'
// import card4 from '../../assets/images/cricket.png'
// import card5 from '../../assets/images/tennins.png'
import gameCard1 from "../../assets/images/home/game1.png";
import NoData from "../noData/NoData";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { getAPI } from "../../services/apiInstance";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { RESPONSIVE_WIDTH, ROUTE_CONST } from "../../constants";
import { btUrlAction } from "../../store/action";
import { useDispatch } from "react-redux";
import SkeletonLoader from "../skeletonLoader/SkeletonLoader";
import { useMediaQuery } from "../../utils/useMediaQuery";
import GameCard from "../GameCard/GameCard";

const FavGames = ({ favGameList, isFavGamesLoading }) => {
  const { t } = useTranslation();
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  return (
    <>
      <div className="col-12 pt-3 pt-lg-0 px-0 d-flex d-lg-flex justify-content-between mb-1 pb-1 mb-lg-3 align-items-center">
        <div className="mainHeading d-flex align-items-center gap-2">
          {/* <img src={livsports} alt="" /> */}
          <svg stroke="#0e95fa" fill="#0e95fa" stroke-width="0" viewBox="0 0 512 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
          {/* {casinoLabels.Sports} */}
          Favourite Games
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="mainSubHeading">
            <Link to={ROUTE_CONST.CASINO} className="headingAnchor">
              {casinoLabels.View_all}
              Upcoming Events
            </Link>
          </div> */}

          <div className="d-flex align-items-center ">
            <div className="mainSubHeading mainSubHeadingCasino d-flex align-items-center">
              <Link
                to={ROUTE_CONST.PROVIDERS}
                className="headingAnchor text-white"
              >
                {PlaySlotLabels.View_All}
              </Link>{" "}
              <img
                className="viewIcon"
                src="./assets/img/nextIcon.png"
                alt=""
              />
            </div>
            <div className="casinoSliderBtns casinoPageSliderBtns d-flex d-lg-flex align-items-center">
              <button
                className={`border-0 sliderBtn sportPrevUppergamesFavGames
                ${disablePrev ? "disable" : ""} 
                `}
              >
                <img src="./assets/img/prevIcon.png" alt="" />
              </button>
              <button
                className={`border-0 sliderBtn sportNextUppergamesFavGames   
                
                ${disableNext ? "disable" : ""} 
                `}
              >
                <img src="./assets/img/nextIcon.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="sportsCard pb-3 py-lg-0 sectionSpacing">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={{
              prevEl: ".sportPrevUppergamesFavGames",
              nextEl: ".sportNextUppergamesFavGames",
            }}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 3,
            //   },
            //   576: {
            //     slidesPerView: 3.2,
            //   },
            //   768: {
            //     slidesPerView: 4.5,
            //   },
            //   992: {
            //     slidesPerView: 4.6,
            //   },
            //   1200: {
            //     slidesPerView: 5,
            //   },
            //   1400: {
            //     slidesPerView: 6,
            //   },
            // }}
            modules={[Navigation]}
            onSwiper={(swiper) => {
              setDisablePrev(swiper.isBeginning);
              setDisableNext(swiper.isEnd);
            }}
            onSlideChange={(swiper) => {
              setDisablePrev(swiper.isBeginning);
              setDisableNext(swiper.isEnd);
            }}
            // loop={true}
            // className="mySwiper casinoSwiperWrapper "
            className="mySwiper casinoSwiperWrapper "
          >
            {!isFavGamesLoading ? (
              favGameList?.length ? (
                favGameList.map((item) => (
                  <SwiperSlide className="popularcardsWidth">
                    {/* <div
                      className="sportsCardBox"
                      onClick={() => {
                        dispatch(btUrlAction(item?.category));
                        navigate(ROUTE_CONST.CASINO + "/" + item.uuid);
                      }}
                    >
                      <div className="sportsImg">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="sportsTitle">
                        <h5>{item.provider}</h5>
                      </div>
                    </div> */}
                    <GameCard data={item.gameData} />
                  </SwiperSlide>
                ))
              ) : (
                <NoData />
              )
            ) : (
              [1, 1, 1, 1, 1, 1, 1, 1]?.map((item) => (
                <SwiperSlide>
                  <SkeletonLoader type={"gameCategory"} />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default FavGames;
