import React from "react";
import { calculateDays } from "../../utils/calculateDays";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RESPONSIVE_WIDTH, ROUTE_CONST } from "../../constants";
import { Spinner } from "react-bootstrap";
import NoData from "../noData/NoData";
import { useMediaQuery } from "../../utils/useMediaQuery";
const LowBetsTable = ({ data, loading }) => {
  const { t } = useTranslation();
  const casinoLabels = t("casinoLabels", { returnObjects: true });
  const navigate = useNavigate();
  const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);

  return (
    <div className="col-12 px-0 betTable">
      <div className="table-responsive">
        <table className="table mb-0">
          <thead>
            <tr>
              <th>Game</th>
              {/* <th className="mobHiddenTableData">Time</th> */}
              <th className="mobHiddenTableData">User</th>
              {isMobile ? "" : <th>Time</th>}
              {/* <th className="mobHiddenTableData">{casinoLabels.BET_AMOUNT}</th> */}
              <th>
                {/* {casinoLabels.MULTIPLIER} */}
                Multiplier
              </th>
              <th>{casinoLabels.PAYOUT}</th>
              
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              data?.length > 0 ? (
                data?.map((item) => (
                  <tr
                    onClick={() =>
                      navigate(`${ROUTE_CONST.CASINO}/${item?.game_id}`)
                    }
                    className="animatedTr cursor-pointer"
                    key={item?._id}
                  >
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="gameImg me-2">
                          <img className="img-fluid" src={item?.image} alt="" />
                        </div>
                        <span className="gameName">{item?.name}</span>
                      </div>
                    </td>
                    <td className="mobHiddenTableData"> {item?.fullname}</td>
                    {isMobile ? (
                      ""
                    ) : (
                      <td className="activeVl align-middle">
                        {new Date(item.createdAt)
                          .toISOString()
                          .substring(11, 19)}
                      </td>
                    )}
                    {/* <td className="mobHiddenTableData">
                      {calculateDays(item?.createdAt)}
                    </td> */}
                    {/* <td className="mobHiddenTableData">
                      $
                      {item?.amount < 1
                        ? Number(item?.amount).toFixed(2)
                        : Math.floor(item?.amount)}
                    </td> */}
                    <td className="activeVl">
                      {item?.multiplier?.toFixed(2)}
                    </td>

                    <td className="activeVl">${item?.payout?.toFixed(2)}</td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    className="text-light text-center bg-transparent"
                  >
                    <NoData />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td></td>
                <td colSpan={3} className="text-light">
                  <div className="m-auto d-flex justify-content-center py-2">
                    <Spinner
                      className="m-auto"
                      animation="border"
                      variant="light"
                      size="sm"
                    />
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LowBetsTable;
