
import { http ,createStorage} from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";

export const rainBowConfig = getDefaultConfig({
  appName: 'memecoin',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID, 
  chains: [mainnet, sepolia],
  storage: createStorage({
    storage: typeof window !== 'undefined' ? window.localStorage : undefined,
  }),
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
});

