import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSpin } from "../../../hooks/useSpin";
import {
  AUTH_MODAL_TABS,
  FREE_SPIN_STRING,
  SPIN_MODAL_OPTIONS,
  SPIN_STRING,
  SPIN_TOKEN,
} from "../../../constants";
import { useAuthModal } from "../../../hooks/useAuthModal";
// import logo from './../../assets/images/logo.jpeg'
import logo from '../../../assets/images/logo.jpeg'
import coin from '../../../assets/images/coin2.png'
import shine from '../../../assets/images/shine.png'

const SpinWheelWinnerModal = () => {
  const { spinObject, setSpinObject } = useSpin();
  const { setAuthModalObject } = useAuthModal();
  const [freeSpin, setFreeSpin] = useState(() => {
    const encodedSpin = localStorage.getItem(FREE_SPIN_STRING)
    if (!!encodedSpin) {
      const decodedParam = JSON.parse(atob(encodedSpin));
      // setFreeSpin(decodedParam);
      return decodedParam
    }
  })

  useEffect(() => {
    // to check if we have to show the free spin message
    const encodedSpin = localStorage.getItem(FREE_SPIN_STRING)
    if (!!encodedSpin) {
      const decodedParam = JSON.parse(atob(encodedSpin));
      setFreeSpin(decodedParam);
    }
  }, []);

  // const [searchParams, setSearchParams] = useSearchParams();

  const claimBonus = () => {
    const isTrue = localStorage.getItem(SPIN_TOKEN);

    const encodedSN = btoa(JSON.stringify({ bonus: true }));
    // console.log({ encodedSN });

    if (!!isTrue) {
      // setSearchParams({ bonus: encodedSN })
      localStorage.setItem(SPIN_STRING, encodedSN);
      setSpinObject({
        selectedModal: SPIN_MODAL_OPTIONS.SPIN_MODAL,
        isSpinOpen: false,
      });
      setAuthModalObject((pre) => ({
        selectedTab: AUTH_MODAL_TABS.SIGN_UP,
        isAuthOpen: true,
      }));
    }
  };



  const handleClose = () => {
    setSpinObject(prev => ({
      ...prev,
      isSpinOpen: false
    }))
  }

  console.log({ freeSpin })
  return (
    <>
      <Modal
        className="spinWinnerModal overflow-hidden"
        onHide={handleClose}
        // show={true}
        show={spinObject.isSpinOpen}

        centered
      >
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <div className="winnerInnerBox">
            <div className="row">
              <div className="shine-bg position-relative">
                <div className="tittle-1">Congratulations!</div>
                <div className="tittle-2">

                  {
                    !!freeSpin?.freeSpin ? "You've got free money to bet on our games!" : "You've got free spins to play our games!s"
                  }
                </div>
                <div className="money">{!!freeSpin?.freeSpin ? `50 free spins` : "5 USDT"}</div>
                <button className="claimClick" onClick={claimBonus}>Claim rewards</button>
                <div className="coinImage">
                  <img src={coin} alt="claim reward" className="w-100 h-100" />
                </div>
                <div className="shine">
                  <img src={shine} alt="" className="w-100 h-100" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

  // return (
  //   <>
  //     <Modal className='spinWinnerModal'
  //     // show={spinObject.isSpinOpen}
  //     show={true}
  //     centered>
  //       <Modal.Body className='d-flex align-items-center justify-content-center'>
  //           <div className="winnerInnerBox">
  //             <div className="row">
  //               <div className="col-12 congTxt">Congratulations!</div>
  //               <div className="col-12 congMsg">You’ve got free money to bet on our games!</div>
  //               <div className="col-12 congPrz">5 USDT</div>
  //               <div className="col-12">
  //                 <button className='claimBtn' onClick={claimBonus}>Claim rewards</button>
  //               </div>
  //             </div>
  //             <img className='coinGroupImage' src="assets/img/coinGroup1.png" alt="" />
  //             <img className='coinGroupImage2' src="assets/img/coinGroup2.png" alt="" />
  //           </div>
  //           <div className="winnerBackImg">
  //             <img src="assets/img/spinBackgroundImage.png" className='w-100 h-100 object-fit-contain' alt="" />
  //           </div>
  //       </Modal.Body>
  //     </Modal>
  //   </>
  // )
};

export default SpinWheelWinnerModal;
