import React, { useEffect, useRef, useState } from "react";
import game from "./../../assets/images/game.png";
import verify from "./../../assets/images/verify1.png";
import logo from "./../../assets/images/logo.jpeg";
import full from "./../../assets/images/swich.png";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { RESPONSIVE_WIDTH } from "../../constants";
import { useLocation, useParams } from "react-router-dom";
import { getAPI, getAPIAuth, postAPIAuth } from "../../services/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { useSelector } from "react-redux";
import BetTable from "../../components/betTable/BetTable";
import ProviderSlider from "../../components/ProviderSlider/ProviderSlider";
import CasinoSlider from "../../components/casinoSlider/CasinoSlider";
import livsports from "../../assets/images/casino.png";
import ModeOptionModal from "../../components/modeOptionModal/ModeOptionModal";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useTranslation } from "react-i18next";
import GameStar from "../../assets/images/game-screen/game-star.svg";
import GameLike from "../../assets/images/game-screen/game-like.svg";
import GameShare from "../../assets/images/game-screen/game-share.svg";
import GameBigScreen from "../../assets/images/game-screen/game-bigScreen.svg";
import GameVideo from "../../assets/images/game-screen/game-video.svg";
import Copy from "../../assets/images/game-screen/copy.svg";
import Facebook from "../../assets/images/game-screen/facebook.svg";
import Instagram from "../../assets/images/game-screen/instagram.svg";
import Whatsapp from "../../assets/images/game-screen/whatsapp.svg";
import Telegram from "../../assets/images/game-screen/telegram.svg";
import Twitter from "../../assets/images/game-screen/twitter.svg";
import { Modal } from "react-bootstrap";

const GameScreen = () => {
  const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);
  const userDetails = useSelector((state) => state.userDetails);
  const iframeRef = useRef();
  const isSmScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);
  const { gameId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [game, setGame] = useState({});
  const { token } = useAuth();
  const location = useLocation();
  const userBalance = useSelector((state) => state.userBalance);
  const [games, setGames] = useState([]);
  const [gamesLoading, setGamesLoading] = useState(false);
  const [data, setData] = useState({});
  const [relatedGames, setRelatedGames] = useState([]);
  const [relatedGamesLoading, setRelatedGamesLoading] = useState(true);
  const [moreGames, setMoreGames] = useState([]);
  const [moreGamesLoading, setMoreGamesLoading] = useState(true);
  const [showIframe, setShowIframe] = useState(false);
  const { t } = useTranslation();
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });

  const [demo, setDemo] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showError, setShowError] = useState({
    show: false,
    msg: "",
  });
  const [showOptionModal, setShowOptionModal] = useState(true);
  const [modeType, setModeType] = useState("");
  const [gameList, setGameList] = useState([]);
  const [favGame, setFavGame] = useState(null);
  // Function for generating url
  const generateUrl = async () => {
    setIsLoading(true);
    try {
      if (token) {
        const resp = await getAPIAuth(
          `games/url-generate?game_uuid=${gameId}&type=${
            demo ? "demo" : "real"
          }`,
          token
        );
        if (resp?.data.success) {
          // handleNewLink(resp.data.data.url)
          setShowError((prev) => ({
            ...prev,
            show: false,
            msg: "",
          }));
          if (location.search?.includes("freeSpinGame")) {
            setTimeout(() => {
              setGame(resp?.data?.data);
            }, 2000);
          } else {
            setGame(resp?.data?.data);
          }
        } else {
          // errorToaster("Something went wrong while playing game")
          setGame(false);
          setShowError((prev) => ({
            ...prev,
            show: true,
            msg: resp?.data?.message,
          }));
        }
      }
    } catch (error) {
      errorToaster("Something went wrong while playing game");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token && userDetails?.id) {
      // getGameData()
      generateUrl();
    }
  }, [
    location?.pathname,
    token,
    userDetails?.id,
    demo,
    userBalance.setCurrencyData?.code,
    userDetails?.fiatStatus,
  ]);

  const getGames = async () => {
    setGamesLoading(true);
    try {
      const res = await getAPI(
        `games/top-rated?is_mobile=${isSmScreen ? "true" : "false"}`
      );
      setGames(res?.data?.data);
    } catch (error) {
    } finally {
      setGamesLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, [isSmScreen]); //eslint-disable-line

  // Function for getting gamedata
  const getGameData = async () => {
    let params = {
      userId: userDetails?.id,
      gameUuid: gameId,
      is_mobile: isSmScreen ? "true" : "false",
    };
    const result = await postAPIAuth(`crypto/get-games`, params);
    if (result.data.success) {
      setData(result.data.data[0]);
    }
  };

  useEffect(() => {
    if (token && userDetails?.id) {
      getGameData();
    }
  }, [location?.pathname, token, userDetails?.id]); //eslint-disable-line

  const getRelatedData = async () => {
    if (!data?.type) {
      setRelatedGamesLoading(false);
      return;
    }
    try {
      const res = await getAPIAuth(
        `games/releated-games?type=${data?.type}&is_mobile=${isSmScreen}`
      );
      if (res.data.success) {
        setRelatedGames(res.data.data);
      }
    } catch (error) {
    } finally {
      setRelatedGamesLoading(false);
    }
  };

  useEffect(() => {
    getRelatedData();
  }, [data?.type]); //eslint-disable-line

  const getMoreGamesData = async () => {
    setMoreGamesLoading(true);
    if (!data?.provider) {
      setMoreGamesLoading(false);

      return;
    }
    try {
      const res = await getAPIAuth(
        `games/game-by-providers?provider=${data?.provider}&is_mobile=${isSmScreen}`
      );
      if (res.data.success) {
        setMoreGames(res.data.data);
      }
    } catch (error) {
    } finally {
      setMoreGamesLoading(false);
    }
  };

  useEffect(() => {
    getMoreGamesData();
  }, [data?.provider]);

  useEffect(() => {
    if (location?.search?.includes("freeSpinGame")) {
      // if (userDetails?.freeSpinLeft === 0) {
      //     setShowOptionModal(true)
      // } else {
      //     setShowOptionModal(false)
      // }
      setShowOptionModal(false);
    } else {
      // setShowOptionModal(true)
    }
  }, [location, userDetails]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setShowIframe(true);
      }, 4000);
    }
  }, [isLoading]);

  const toggleFullScreen = (e) => {
    e.stopPropagation();
    const iframe = iframeRef.current;
    if (iframe) {
      if (iframe?.requestFullscreen) {
        iframe?.requestFullscreen();
      } else if (iframe?.mozRequestFullScreen) {
        iframe?.mozRequestFullScreen();
      } else if (iframe?.webkitRequestFullscreen) {
        iframe?.webkitRequestFullscreen();
      } else if (iframe?.msRequestFullscreen) {
        iframe?.msRequestFullscreen();
      }
    }
  };

  const getFavGames = async () => {
    if (userDetails?.id) {
      try {
        const res = await getAPIAuth(
          `crypto/get-fav-games?userId=${userDetails?.id}&is_mobile=${
            isMobile ? "true" : "false"
          }`
        );
        if (res.data.success) {
          setGameList(res.data.data);
        }
      } catch (error) {
        console.log(error);
        // errorToaster(error.message);
      }
    }
  };

  const addFavGame = async () => {
    try {
      const res = await postAPIAuth(
        `crypto/create-favourite-games`,
        {
          userId: userDetails?.id,
          gameId: data?._id,
        },
        token
      );
      if (res.data.success) {
        succesToaster(res.data.message);
        getFavGames();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDetails?.id) {
      getFavGames();
    }
  }, [userDetails?.id]);

  useEffect(() => {
    const findFavGames = gameList.find((game) => {
      return game.gameData.uuid === gameId;
    });

    setFavGame(findFavGames);
  }, [gameList]);

  console.log(data, "this is game datat");
  return (
    <>
      <div className="bgsection ">
        {token ? (
          <>
            {!isSmScreen ? (
              <>
                <div className="screenImg">
                  {/* <img src={game} alt="" className='h-100 w-100 object-fit-cover' /> */}
                  {!isSmScreen ? (
                    <>
                      <div className="iframeMain bg-black rounded-2 overflow-hidden position-relative h-100">
                        <iframe
                          ref={iframeRef}
                          key={game?.url}
                          title="game iframe"
                          className="game-iframe w-100 h-100"
                          src={showOptionModal ? "" : game?.url}
                          // src={game?.url}
                          allow="autopaly fullscreen"
                          sandbox="allow-scripts allow-same-origin"
                          allowFullScreen
                        />
                        {showOptionModal ? (
                          <ModeOptionModal
                            setModeType={setModeType}
                            isLoading={isLoading}
                            setDemo={setDemo}
                            setShowOptionModal={setShowOptionModal}
                            showError={showError}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="iframeMain rounded-2 bg-black overflow-hidden d-flex align-items-center justify-content-center"> */}

                      {/* </div> */}
                      {/* <InGameModal game={game} /> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3">
                  <div>
                    <div className="d-flex align-items-center justify-content-between ">
                      <div className="d-flex align-items-center gap-3">
                        
                          <div className="game-icons">
                            <p>
                              <div onClick={() => addFavGame()}>
                                <img
                                  className={` ${
                                    favGame ? "" : "unlike-game"
                                  } `}
                                  src={GameLike}
                                  alt=""
                                />
                              </div>
                            </p>
                          </div>
                        

                        <div className="game-icons">
                          <button
                            onClick={() => setShowShareModal(true)}
                            className="bg-transparent border-0"
                          >
                            <img src={GameShare} alt="" />
                          </button>
                        </div>
                        {/* <div className="game-icons">
                          <p>
                            <img src={GameVideo} alt="" />
                          </p>
                        </div> */}
                        <div onClick={toggleFullScreen} className="game-icons">
                          <p>
                            <img src={GameBigScreen} alt="" />
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="gameName">{data?.name} </div>
                      </div>
                      <div className="play-opions">
                        <div className="d-flex align-items-center">
                          <div
                            style={{
                              backgroundColor:
                                modeType === "free" ? "#2F364C" : "transparent",
                            }}
                            className={`   free-play `}
                          >
                            Free Play
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                modeType === "real" ? "#2F364C" : "transparent",
                            }}
                            className={`real-play`}
                          >
                            Real Play
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {isSmScreen ? (
              <>
                {!showIframe ? (
                  // <div className="vh-100 vw-100 iframeLoader position-fixed d-flex align-items-center justify-content-center top-0 start-0">
                  //     <video src="assets/img/gameLoader.mp4" alt="" className="h-100 w-100 object-fit-contain" style={{ mixBlendMode: 'screen' }} autoPlay muted loop />
                  // </div>
                  // <LoadingScreen timer={0}/>
                  <></>
                ) : (
                  ""
                )}
                <div
                  className={`modal fade p-0 show d-block gameModalScreen ${
                    showIframe ? "" : "invisible"
                  }`}
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content p-0">
                      <div
                        className="modal-body p-0"
                        style={{ background: "#0d131c" }}
                      >
                        <div className="iframeMain bg-black position-relative overflow-hidden h-100">
                          <iframe
                            ref={iframeRef}
                            className="game-iframe w-100 h-100"
                            src={game?.url}
                            allow="autopaly fullscreen"
                            allowFullScreen
                            title="game mobile iframe"
                            style={{ backgroundColor: "#0d131c" }}
                            sandbox="allow-scripts allow-same-origin"
                            key={`${game?.url}-old`}
                          />
                          {showOptionModal ? (
                            <ModeOptionModal
                              setModeType={setModeType}
                              isLoading={isLoading}
                              setDemo={setDemo}
                              setShowOptionModal={setShowOptionModal}
                              showError={showError}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div className="col-12 ">
              <div className="blinkLayer">
                <div className="msgText">{PlaySlotLabels.Please_Log_In}</div>
                {/* <div className="msgText text-capitalize">Please login to play</div> */}
              </div>
            </div>
          </>
        )}
      </div>
      {!isSmScreen ? (
        <>
          <div className="mt-3">
            <BetTable />
          </div>
          <CasinoSlider
            title={"Related Games"}
            icon={livsports}
            nextBtn={"casinoNext1"}
            prevBtn={"casinoPrev1"}
            showTabs={true}
            data={relatedGames}
            loading={relatedGamesLoading}
          />
          <CasinoSlider
            title={"Related Games"}
            icon={livsports}
            nextBtn={"casinoNext2"}
            prevBtn={"casinoPrev2"}
            showTabs={true}
            data={moreGames}
            loading={moreGamesLoading}
          />
          <ProviderSlider />
        </>
      ) : (
        ""
      )}

      <Modal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
        centered
        fullscreen={"sm-down"}
        scrollable
        className="shareModalWrapper"
      >
        <div>
          <div className="shareModalHeader">
            <div className="share-title">Share This Game</div>
          </div>
          <div className="shareMain">
            <div className="p-4 pt-5">
              <div className="share-heading text-center ">
                Share via web link
              </div>
              <div className="d-flex align-items-center justify-content-center gap-3 my-4">
                <div className="share-icons">
                  <a href="">
                    <img src={Telegram} alt="" />
                  </a>
                </div>
                <div className="share-icons">
                  <a href="">
                    <img src={Instagram} alt="" />
                  </a>
                </div>
                <div className="share-icons">
                  <a href="">
                    <img src={Twitter} alt="" />
                  </a>
                </div>
                <div className="share-icons">
                  <a href="">
                    <img src={Facebook} alt="" />
                  </a>
                </div>
                <div className="share-icons">
                  <a href="">
                    <img src={Whatsapp} alt="" />
                  </a>
                </div>
              </div>
              <div className="share-heading mb-2">Share via web link</div>
              <div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="button-addon2"
                  />
                  <button class="copy-btn" type="button" id="button-addon2">
                    Copy <img src={Copy} alt="" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GameScreen;
