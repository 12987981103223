import React, { useEffect, useState } from "react";
import livsports from "../../assets/images/home/Popular-Games.png";
// import card1 from '../../assets/images/myBets.png'
// import card2 from '../../assets/images/soccer.png'
// import card3 from '../../assets/images/basketball.png'
// import card4 from '../../assets/images/cricket.png'
// import card5 from '../../assets/images/tennins.png'
import gameCard1 from "../../assets/images/home/game1.png";
import NoData from "../noData/NoData";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { getAPI } from "../../services/apiInstance";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { RESPONSIVE_WIDTH, ROUTE_CONST } from "../../constants";
import { btUrlAction } from "../../store/action";
import { useDispatch } from "react-redux";
import SkeletonLoader from "../skeletonLoader/SkeletonLoader";
import { useMediaQuery } from "../../utils/useMediaQuery";
import GameCard from "../GameCard/GameCard";

const UpperCategories = ({ selectedUpperCategory }) => {
  const isSMScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });
  const casinoLabels = t("casinoLabels", { returnObjects: true });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  const getSports = async () => {
    setIsLoading(true);
    try {
      const res = await getAPI(
        `games/get-games-by-cat?is_mobile=${
          isSMScreen ? "true" : "false"
        }&gameLimit=10&categoryId=${
          selectedUpperCategory?.id ? selectedUpperCategory?.id : ""
        }`
      );
      setData(res?.data?.data[0]?.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUpperCategory?.id) {
      getSports();
    }
  }, [selectedUpperCategory?.id]);

  console.log({ disablePrev, disableNext });

  return (
    <>
      <div className="col-12 pt-3 pt-lg-0 px-0 d-flex d-lg-flex justify-content-between mb-1 pb-1 mb-lg-3 align-items-center">
        <div className="mainHeading d-flex align-items-center">
          <img src={selectedUpperCategory?.image} alt="" />
          {selectedUpperCategory?.name}
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="mainSubHeading">
            <Link to={ROUTE_CONST.CASINO} className="headingAnchor">
              {casinoLabels.View_all}
              Upcoming Events
            </Link>
          </div> */}

          <div className="d-flex align-items-center ">
            <div className="mainSubHeading mainSubHeadingCasino d-flex align-items-center">
              <Link
                to={`${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                  selectedUpperCategory.name
                )}&catid=${selectedUpperCategory.id}`}
                className="headingAnchor text-white"
              >
                {PlaySlotLabels.View_All}
              </Link>{" "}
              <img
                className="viewIcon"
                src="./assets/img/nextIcon.png"
                alt=""
              />
            </div>
            <div className="casinoSliderBtns casinoPageSliderBtns d-flex d-lg-flex align-items-center">
              <button
                className={`border-0 sliderBtn sportPrevUppergamesUpperCat ${
                  disablePrev ? "disable" : ""
                }`}
              >
                <img src="./assets/img/prevIcon.png" alt="" />
              </button>
              <button
                className={`border-0 sliderBtn sportNextUppergamesUpeerCat  ${
                  disableNext ? "disable" : ""
                } `}
              >
                <img src="./assets/img/nextIcon.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="sportsCard pb-3 py-lg-0 sectionSpacing">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={{
              prevEl: ".sportPrevUppergamesUpperCat",
              nextEl: ".sportNextUppergamesUpeerCat",
            }}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 3,
            //   },
            //   576: {
            //     slidesPerView: 3.2,
            //   },
            //   768: {
            //     slidesPerView: 4.5,
            //   },
            //   992: {
            //     slidesPerView: 4.6,
            //   },
            //   1200: {
            //     slidesPerView: 5,
            //   },
            //   1400: {
            //     slidesPerView: 6,
            //   },
            // }}

            onSwiper={(swiper) => {
              setDisablePrev(swiper.isBeginning);
              setDisableNext(swiper.isEnd);
            }}
            onSlideChange={(swiper) => {
              setDisablePrev(swiper.isBeginning);
              setDisableNext(swiper.isEnd);
            }}
            modules={[Navigation]}
            className="mySwiper casinoSwiperWrapper "
          >
            {!isLoading ? (
              data?.length ? (
                data.map((item) => (
                  <SwiperSlide className="popularcardsWidth">
                    {/* <div
                      className="sportsCardBox"
                      onClick={() => {
                        dispatch(btUrlAction(item?.category));
                        navigate(ROUTE_CONST.CASINO + "/" + item.uuid);
                      }}
                    >
                      <div className="sportsImg">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="sportsTitle">
                        <h5>{item.provider}</h5>
                      </div>
                    </div> */}
                    <GameCard data={item} />
                  </SwiperSlide>
                ))
              ) : (
                <NoData />
              )
            ) : (
              [1, 1, 1, 1, 1, 1, 1, 1]?.map((item) => (
                <SwiperSlide>
                  <SkeletonLoader type={"gameCategory"} />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default UpperCategories;
