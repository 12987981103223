import React, { useEffect } from 'react'
import { useAuth } from '../../../../hooks/useAuth'
import { getAPI, getAPIAuth } from '../../../../services/apiInstance'
import sportIcon from "../../../../../src/assets/img/sportsIcon.png"

const TopEventWidgets = () => {
  const {token} = useAuth()

  const renderTopEventsBeforeLogin = async () => {
    const  topEventsWidget = await window.BetSdk.initTopEventsWidget({
      mainFrameUrl:  "https://memecoin.io/sports", 
      host : 'https://gameosys-mu83s8.turbostars.gg',
      cid: "gameosys",
      lang:  "en", 
      containerId:  "sport-container",
      height:  "372px", 
      customStyles:  false,
    })
  }

  const renderTopEventsAfterLogin = async () => {

          try {
              // get uuid to generate url--->>>>
              const res = await getAPI('sport/getSportbooks')
              if (res?.data?.status) {
                  //generate url --------------------------------->>
                  const response = await getAPIAuth(`sport/urlGenerate?uuid=${res.data.data?.[0]?.uuid}`, token, "fromsports")
    
                  if (response?.data?.status) {
                      // ----extract token value 
                      const tokenRegex = /token:\s*'([^']+)'/;
                      const tokenMatch = tokenRegex.exec(response.data?.data?.gethtml);
                      const tokenValue = tokenMatch && tokenMatch[1]; // Extracted token value
                      // ----extract cid value 
                      const cidRegex = /cid:\s*'([^']+)'/;
                      const cidMatch = cidRegex.exec(response.data?.data?.gethtml);
                      const cidValue = cidMatch && cidMatch[1]; // Extracted cid value
                      
                      try {
                        const  topEventsWidget = await window.BetSdk.initTopEventsWidget({
                          mainFrameUrl:  "https://memecoin.io/sports", 
                          host : 'https://gameosys-mu83s8.turbostars.gg',
                          cid: cidValue,
                          token: tokenValue,
                          lang:  "en", 
                          containerId:  "sport-container",
                          height:  "372px", 
                          customStyles:  false,
                        })
                      } catch (error) {
                        console.log('bet error', error)
                      }
                  } 
              }
          } catch (error) {
          } 
  }

  useEffect(()=> {
    if(!token) {
      renderTopEventsBeforeLogin()
    } else {
      renderTopEventsAfterLogin()
    }
  }, [])

  return (
    <>

<div className="mainHeading d-flex align-items-center">
          <img src={sportIcon} alt="" />
          Sports
        </div>
      <div id="sport-container"></div>
    </>
  )
}

export default TopEventWidgets