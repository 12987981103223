import Modal from "react-bootstrap/Modal";
import AuthBanner from "./components/AuthBanner";
import SignUp from "./components/signUp/SignUp";
import { useAuthModal } from "../../hooks/useAuthModal";
import {
  AUTH_MODAL_TABS,
  CLICK_ID,
  NEW_CLICK_ID,
  OPEN_CAGE_API_KEY,
  SPIN_STRING,
} from "../../constants";
// import SignInComponent from "./components/SignInComponent/SignInComponent";

import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ipAddressFunc from "../../utils/ipAddressFunc";
import { countryAction, ipAddressAction } from "../../store/action";
import SignInComponent from "./components/signIn/SignIn";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";

const AuthModal = () => {
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const [query, setQuery] = useSearchParams();
  const [browserName, setBrowserName] = useState();
  const [isForgotPassword, setIsForgotPassword] = useState(
    query.get("action") === "forgotPassword"
  );
  const [checks, setChecks] = useState({
    terms: false,
    promotions: false,
  });

  const click_id =
    sessionStorage.getItem(CLICK_ID) === "null"
      ? null
      : sessionStorage.getItem(CLICK_ID);
  const new_click_id =
    localStorage.getItem(NEW_CLICK_ID) === "null"
      ? null
      : localStorage.getItem(NEW_CLICK_ID); // setup in appHeader
  // setup in appHeader
  const [bonus, setBonus] = useState({ bonus: false });
  const { token } = useAuth();
  const { t } = useTranslation();
  const authLabels = t("AuthLabels", { returnObjects: true }); //string has to be same as language json key
  const dispatch = useDispatch();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);

  //ip Address
  const fetchIpAddress = async () => {
    const res = await ipAddressFunc();
    if (res) {
      dispatch(ipAddressAction(res));
    }
  };
  //get country name
  const countryNameFunc = async () => {
    try {
      navigator.geolocation?.getCurrentPosition(async (data) => {
        axios
          .get(
            `https://api.opencagedata.com/geocode/v1/json?q=${data?.coords?.latitude}+${data?.coords?.longitude}&key=${OPEN_CAGE_API_KEY}`
          )
          .then((res) => {
            dispatch(countryAction(res?.data?.results[0]?.components?.country));
          });
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (authModalObject.isAuthOpen) {
      if (!ipAddress) fetchIpAddress();
      if (!country) countryNameFunc();
    }
    // if(!authModalObject.isAuthOpen){
    //     setQuery({action: ''})
    //      }
  }, [authModalObject.isAuthOpen]);

  useEffect(() => {
    if (token) {
      if (
        query.get("action") === "forgotPassword" &&
        authModalObject?.selectedTab == AUTH_MODAL_TABS.LOG_IN
      ) {
        setIsForgotPassword(true);
      }
    }
  }, [query.get("action"), token]);

  useEffect(() => {
    // const encodedParam = searchParams.get("bonus")
    const encodedParam = localStorage.getItem(SPIN_STRING);
    if (!!encodedParam) {
      const decodedParam = JSON.parse(atob(encodedParam));
      setBonus(decodedParam);
    }
  }, [authModalObject.isAuthOpen]);

  const handleClose = () =>
    setAuthModalObject((prev) => ({
      ...prev,
      isAuthOpen: false,
    }));

  return (
    <>
      <RainbowKitProvider>
        <Modal
          show={authModalObject?.isAuthOpen}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          fullscreen={"sm-down"}
          className="signUpModalWrapper"
          // dialogClassName="modal-dialog-scrollable"
        >
          <Modal.Body className="modalBody overflow-x-hidden">
            <div className="row  h-100 heightDiv">
              <div className="col-md-6 col-12">
                <AuthBanner handleClose={handleClose} />
              </div>

              <div className="col-md-6 col-12">
                {authModalObject?.selectedTab === AUTH_MODAL_TABS.LOG_IN ? (
                  <SignInComponent
                    handleClose={handleClose}
                    browserName={browserName}
                    isForgotPassword={isForgotPassword}
                    setIsForgotPassword={setIsForgotPassword}
                  />
                ) : authModalObject?.selectedTab === AUTH_MODAL_TABS.SIGN_UP ? (
                  // <SignUp
                  //     handleClose={handleClose}
                  //     checks={checks}
                  //     browserName={browserName}
                  //     click_id={click_id}
                  //     new_click_id={new_click_id}
                  //     setChecks={setChecks}
                  //     bonus={bonus}
                  // />
                  <SignInComponent
                    handleClose={handleClose}
                    browserName={browserName}
                    isForgotPassword={isForgotPassword}
                    setIsForgotPassword={setIsForgotPassword}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </RainbowKitProvider>
    </>
  );
};

export default AuthModal;
