import React from 'react'

function SportsPolicy() {
    return (
        <div className='aml-policy-section'>
            <div className="heading">
                Sports policy
            </div>
            <div className="sub-heading">
                Last updated: August, 08 2023.
            </div>
            <div className="sub-heading">
            BASIC TERMS AND DEFINITIONS
            </div>
            <div className="sub-txt">
            <ul>
                <li><span className='fw-normal text-white'>Client (bettor)</span> is a betting participant who entered into an agreement with a Betting company.</li>
                <li><span className='fw-normal text-white'>Bet</span>  is an agreement concluded between the Client and the Betting company on accepting bets from the participants of the agreement on the terms offered in the line of the Betting company. According to the terms of the bet, a losing party shall fulfill its obligation.</li>
                <li><span className='fw-normal text-white'>Line</span> is a list of events offered by the Betting company for betting on their outcomes that have certain winning odds.</li>
                <li><span className='fw-normal text-white'>Bet</span> is a cash deposit used for wagering. </li>
                <li><span className='fw-normal text-white'>Outcome </span> is the result of an event on which the Client made a bet with a Betting company.</li>
                <li><span className='fw-normal text-white'>Winning odds</span> means quotes of various event outcomes offered by the Betting company.  Teams in the betting line are labeled "Team 1" (indicated by "1") and "Team 2" (indicated by "2"). At the same time, details of the event venue are for information purposes only. </li>
            </ul>
                
            </div>
            
            <div className="sub-heading"> GENERAL TERMS</div>
            <div className="sub-txt">
            The main activity of the Betting company is to accept bets on sports competitions and other events, such as public, political, entertainment, or any other (hereinafter referred to as the "Events"). All Bets are accepted in strict accordance with these rules, which is a confirmation that the Client (bettor) is familiar with and fully agrees with the current rules. 
            </div>
            <div className="sub-txt">
            The Betting company has the right to amend the above provisions, rules, and procedures of the payments without prior notice to the Clients. In this case, all subsequent bets are accepted in accordance with the amendments to the rules, and the terms of previously made bets remain unchanged.
            </div>
            <div className="sub-txt">
            Making bets are allowed only Clients of full age, who have full responsibility for the legality of gambling via the Internet in their country of residence and for providing information about wins or losses to the fiscal or law enforcement agencies of their region or country.
            </div>
            <div className="sub-txt">
            The Betting company guarantees non-disclosure of the Client's confidential and privileged information by its employees. It is not responsible for the consequences if the Client's confidential and privileged information gets to third parties.
            </div>
            <div className="sub-txt">
            The Betting company cannot be held liable for any loss or damage claimed as incurred due to the content of the website of the bookmaker's company or its use.
            </div>
            <div className="sub-txt">
            This provision applies equally to the Client's misuse of the content of the website of the bookmaker's company, or inability to connect to or use the website, any errors, typos or omissions in the content of the website, as well as delays in its operation or data transmission due to technical failures.
            </div>
            <div className="sub-txt">
            In relation to the general rules, priority is given to sport or competition rules.
            </div>
            <div className="sub-txt">
            The Betting company initially makes every effort to ensure that the content of the website is correct but due to various circumstances emphasizes that data on the current sports and other events is for information purposes only.  The Betting company is not liable for possible inaccuracies in the current score or time of the match, calling to use alternative sources of information on an ongoing basis.
            </div>
            <div className="sub-txt">
            Rule regarding the prohibition for users from Russia and Belarus to use the service, and in case of detection, their accounts will be immediately closed:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Users residing in the Russian Federation or the Republic of Belarus are strictly prohibited from using our service.</li>
                    <li>In the event of detecting the use of our service by users from Russia or Belarus, we reserve the right to immediately close their accounts without prior notice.</li>
                    <li>Account closure will result in the termination of all associated privileges, including access to the service, storage of data, and utilization of functional features.</li>
                    <li>We disclaim any liability for any losses, damages, or consequences arising from the violation of this rule and the use of the service by users from Russia or Belarus.</li>
                    <li>All information collected from users in Russia and Belarus will be promptly deleted in accordance with our privacy policy.</li>
                </ul>
                2.8.2. Free money - client gets a bet amount and the winning on his account. For example, freebet 5 on odd 3.5 client get  5*3.5 = 18.50
            </div>
            <div className="sub-heading"> TYPES OF BETS </div>
            <div className="sub-txt">
                 The Betting company offers the following betting options for sports and/or other events:
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'>"Ordinar bet"</span> means a bid on the outcome of an single event. The ordinar bet win is the product of the sum of bet and odds of the event.
            </div>
            <div className="sub-txt">
           <span className='fw-normal text-white'> "Combo"</span> means a bet on several independent events. The "Combo" win is the product of the sum of bet by "Combo" odds, received by multiplying the odds of the outcomes of all events included in the "Combo". The "Combo" may include any combination of outcomes of unrelated events from any sports or other events. If all events are correctly predicted, the "Combo" is considered won. At least one incorrectly predicted event outcome means that the entire bet of this option is lost. The maximum odds value for "Combo" bets is 10 000.  There shall be at least two events in one "Combo". 
            It is forbidden to include different outcomes in the same event, as well as different bets on the same athlete in the same tournament (for example, winning a match and winning a tournament) in one "Combo", even if bets and events are not directly related. If any events related to this match were included in the "Combo", then such a bet is subject to refund unless the software  for accepting bets initially blocked this "Combo". Taking into account the peculiarities of determining odds for certain types of outcomes (correctly predicted totals, handicaps, etc.), the final winning odds for all the above types of bets are determined. In case of cancellation or postponement of events for the period specified in these rules, odds equal to "1" are used to calculate such outcomes.

            </div>
            <div className="sub-heading">MAIN TYPES OF OUTCOMES OFFERED FOR BETTING</div>
            <div className="sub-txt">
            Bets are accepted on <span className='fw-normal text-white'>"To Win To Nil (Team or athlete 1)"</span> (indicated in the line by <span className='fw-normal text-white'>"1"</span>), a <span className='fw-normal text-white'>"draw"</span> (indicated by <span className='fw-normal text-white'>"X"</span>), or <span className='fw-normal text-white'>"To Win To Nil (Team or athlete 2)"</span> (indicated by <span className='fw-normal text-white'>"2"</span>). If the outcome of the match was predicted correctly, the bet is considered won.
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'>"Double Chance (Team or athlete 1)" or a draw</span> is indicated by <span className='fw-normal text-white'>"1X"</span>. To win a bet on such an outcome, the first team or athlete shall not fail. <span className='fw-normal text-white'>"Double Chance" (victory of the first or second team or athlete </span> ) is indicated by the combination <span className='fw-normal text-white'>"12"</span>. A victory of one of the teams or athletes is necessary to win a bet with such an outcome. <span className='fw-normal text-white'>Double Chance (Team or athlete 2) or a draw</span> is indicated by the combination <span className='fw-normal text-white'>"X2"</span>. To win a bet on such an outcome, the second team or athlete shall not fail.

            </div>
            <div className="sub-txt">
           <span className='fw-normal text-white'> Participant results.</span> In such bets, it is necessary to predict whether the team or athlete will reach a certain stage of the competition (for example, 1/8, quarter-finals, semi-finals, finals, etc.), or what place he will take in the tournament or group. If the team or athlete declared in the tournament did not participate in the competition for some reason, then all bets on events with his participation are calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'> "Who Will Go On"</span>. In the proposed pairs, it is recommended to name the team or athlete, who will go further in the tournament grid. The best will be considered the team or athlete who has gone further in the tournament grid. If both teams or athletes were eliminated from the competition at the same stage, or if one of the teams or athletes refused to participate in the competition before it began, winning odds for such bets are equal to "1". 
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'> "Winner"</span> . The team or athlete who takes first place in the tournament, or a participant in other non-sports events, who wins first place, is considered the winner. If a participant withdraws from the competition before the start of the tournament, winning odds for bets are considered to be equal to "1".
            </div>
            <div className="sub-txt">
            The <span className='fw-normal text-white'> "First Blood"</span>  bet involves killing the game character by the characters of the opposing team. The first kill on the map by the opponent's creeps/minions (without the help of allied characters) or neutral units is not counted. In this case, the bet remains valid until the first game character is killed by the opposing team.

            </div>
            <div className="sub-txt">
            The <span className='fw-normal text-white'> "First Roshan"</span>  bet means that the Client shall correctly predict which team will kill Roshan first.
            </div>
            <div className="sub-txt">
            The <span className='fw-normal text-white'> "Round Duration"</span>  bet means that the Client shall correctly predict the duration of the round in minutes (more or less than the suggested value). Only the whole number of minutes played is counted.
            </div>
            <div className="sub-txt">
            The Client can also bet on events with a <span className='fw-normal text-white'> handicap</span>  (indicated by <span className='fw-normal text-white'> "H"</span>). The handicap assumed by the Client is added to the result of the selected team. The bet is considered won if the If the score for the chosen team is correctly predicted after the addition of the handicap . If a draw is resulted after adding a handicap, the bet is refunded and the "Combo" is calculated with winning odds of this event as equal to <span className='fw-normal text-white'> "1"</span>. If the other team wins after the addition of the handicap, the bet is a loser.
            </div>
            <div className="sub-txt">
            The Client can bet on the <span className='fw-normal text-white'> Total </span>  (indicated by <span className='fw-normal text-white'> "T" </span> or <span className='fw-normal text-white'> "Total" </span>) which means the number of certain actions in the match. To win, you need a correct prediction of what number of specified actions will be performed in the match: over (<span className='fw-normal text-white'> "O" </span>) or under (<span className='fw-normal text-white'> "U" </span>).
            </div>
            <div className="sub-txt">
            A bet on the <span className='fw-normal text-white'> Individual Total of Teams </span>  (Participants) (indicated by <span className='fw-normal text-white'> "iT" </span> or <span className='fw-normal text-white'>"iTotal"</span>) provides a prediction for the number of actions performed in the match for one of the teams or one of the participants of the competition. To win, a Client need to correctly predict whether over (Ov) or under (Un) of the specified actions will be performed in the match by the selected team or participant. In the case of scoring <span className='fw-normal text-white'> Total </span>, the bet is refunded and "Combos" are calculated with winning odds of this event as equal to <span className='fw-normal text-white'> "1" </span>.
            </div>
            <div className="sub-txt">
            Unless otherwise specified in the line, the Total, Handicap, Even/Odd total of the round, or individual total is indicated in "Deaths". "Death" is considered the death of a game character for any reason (caused by enemy unit, creeps, Roshan, etc ).
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'> Double Asian Handicap </span>  is a bet on the game with a handicap in which the Handicap value (H) is multiple of 0.25, but not multiple of 0.5, for example: H = -0.25, +0.25, -0.75, +0.75, etc. Such a bet is interpreted as two (simple, "half") bets with the same odds and with the nearest common Handicap value (H1 = H - 0.25 and H2 = H + 0.25). The amount of each "half" bet is equal to the half of the "Double" bet amount.
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'>Double Total Bet</span>  is a Total bet in which the Total value (T) is multiple of 0.25, but not multiple of 0.5, for example: T = 2.25, 2.75, 3.25, etc. Such bet is interpreted as two (simple, "half") bets at the same odds and with the nearest common Total value (T1 = T - 0.25 and T2 = T + 0.25). The amount of each "half" bet is equal to the half of the "Double" bet amount.
            </div>
            <div className="sub-txt">
            The following examples are simplified four options of settlement for such "Double" bets
            </div>
            <div className="sub-txt">
            <span className='fw-normal text-white'>K(g)</span> - general odd for bet; <br />
            <span className='fw-normal text-white'>K(g)</span> - place bet odd; <br />
            k1 - the odd of the first half bet; <br />
            k2 - the odd of the second half bet; <br />
            </div>
            <div className="sub-txt">
            <div>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Result of the first half bet</th>
                            <th>Result of the second half bet</th>
                            <th>Calculation of K(g)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='fw-normal text-white' >Win</td>
                            <td className='fw-normal text-white'>Win</td>
                            <td className='fw-normal text-white'>K(g)= К</td>
                        </tr>
                        <tr>
                            <td>
                                <div className='fw-normal text-white'>Win</div>
                                <div>k1=K</div>
                            </td>
                            <td>
                                <div className='fw-normal text-white'>Refund</div>
                                <div>k2=1</div>
                            </td>
                            <td className='fw-normal text-white'>K(g)=(k1+1)/2</td>
                        </tr>
                        <tr>
                            <td>
                                <div className='fw-normal text-white'>Lose</div>
                                <div>k1=0</div>
                            </td>
                            <td>
                                <div className='fw-normal text-white'>Refund</div>
                                <div>k2=1</div>
                            </td>
                            <td className='fw-normal text-white'>K(g)=(0+1)/2=0,5</td>
                        </tr>
                        <tr>
                            <td>
                                <div className='fw-normal text-white'>Lose</div>
                                <div>k1=0</div>
                            </td>
                            <td>
                                <div className='fw-normal text-white'>Refund</div>
                                <div>k2=0</div>
                            </td>
                            <td className='fw-normal text-white'>K(g)=0</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            <div className="sub-txt">
                Example: <br />
                Bet amount is $200, Handicap 1 (-1.75), odd is 1.8.,  match result 2:0.
                <div>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <td>H1 (-2) is [(-1,75) - 0,25]</td>
                                <td>H1 (-1,5) is [(-1,75) + 0,25]</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='fw-normal text-white'>Refund</div>
                                </td>
                                <td>
                                    <div className='fw-normal text-white'>Win</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>k1=1</div>
                                </td>
                                <td>
                                    <div>k2=1,8</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>К(g)=(1+1,8)/2= 1,4</div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Payment amount: 1,4х200= $280.</div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="sub-heading">CONDITIONS FOR ACCEPTING BETS</div>
            <div className="sub-txt">
            Bets on the outcomes of the events are accepted based on the line, which is a list of upcoming events for which the Betting company sets certain quotes (winning odds). After any bet (winning odds, Totals, Handicaps, limits on the maximum bets, on "Combo", etc.), the line may be amended but conditions of previously placed bets remain the same.
            </div>
            <div className="sub-txt">
            The sum of bets on any event cannot exceed the maximum sum specified in the line during one game day (on a line dated by a single number). When the total maximum bet for the same event is reached, no more bets for this event will be accepted.
            </div>
            <div className="sub-txt">
            Bets are accepted before the event starts, except for bets on "live" matches. Bets made by the Client after the actual start of the event or competition, regardless of the reasons, are considered by the Betting company as invalid. They are either subject to refund or excluded from "Combos". Bets on "live" events are considered valid until the end of the competitions. If they are accepted for any reason after the end of the event or match or tournament, the bet is calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            The date and the time for the event start is listed on site is for information purposes only. The incorrectly specified date or time in such a case is not sufficient reason to cancel the bet. The Betting company is not responsible for the accuracy of the results information during the "live" event. Also, the start date and time of the event indicated in the line are for information purposes only, and therefore the wrong date and time are also not sufficient grounds for canceling the bet. In such a case, bets placed before the actual start of the event are considered valid. When calculating outcomes, the actual start time of the event is considered as the actual start time of the competition according to official information from the matches or tournaments organizer, as well as the websites that the Betting company refers to when collecting statistics.
            </div>
            <div className="sub-txt">
            The Betting company is not responsible for accurate translation or spelling of the names of athletes, teams, and cities where competitions (matches, tournaments) are held.
            </div>
            <div className="sub-txt">
            In the event of software failures when accepting bets (discrepancy of odds in one or more positions, obvious typos in odds, etc.) or staff errors, the Betting company has the right to consider these bets invalid. The management of the Betting company has the right to cancel a bet made after the start of the event or on a deliberately "incorrect" line.
            </div>
            <div className="sub-txt">
            Technical Errors. <br />
            We reserve the right to suspend odds during an event due to failed transmission or other technical related issues or if fraud is suspected. We reserve the right to void bets even subsequently if any such bet was won by the customer because of a technical fault or error, inclusive of but not limited to an error or fault in transmission.
            </div>
            <div className="sub-txt">
            The Client cannot cancel or change the bet after it has been placed on the server, and the confirmation message with the bet number has been appeared on the website page and/or bet slip received. If the bet has already been registered on the server, A connection failure and other technical mistakes in communication are no reasons to change or cancel a bet. 
            </div>
            
            <div className="sub-heading"> SPECIAL CONDITIONS</div>
            <div className="sub-txt">
            If:
            </div>
            <div className="sub-txt">
               <ul>
                <li>
                The event did not start on the scheduled date and/or was officially postponed by the organizer for more than 36 - 48 hours from the start time indicated in the bet slip, - all bets on this event are calculated with the odds 1 (unless otherwise specified in the rules for that sport).
                The exact settlement interval is determined by the bookmaker individually within the specified time range.
                </li>
                <li>The event was interrupted and, according to official data, was not finished within 12 - 48 hours from the start time indicated in the bet slip - all bets on this event are calculated based on the results at the time of the event interruption. Odds equal to "1" are used to calculate bets on outcomes, the results of which are not defined (unless otherwise specified in the rules for that sport). <br /> The exact settlement interval is determined by the bookmaker individually within the specified time range. </li>
               </ul>
            </div>
            <div className="sub-txt">
            The initial result of the event is counted for calculation in case of cancellation or change of the result (protest, disqualification for doping, etc.).
            </div>
            <div className="sub-txt">
            If more than one participant (team or athlete) is declared the competition winner, then bets placed on winning the competition of these participants (teams or athletes) are calculated with odds equal to "1".
            The actual results of the competition are announced by the Betting company based on official protocols and other reliable sources of information. In case results from different sources do not match or there are obvious errors, the Betting company makes the final decision on determining the results for calculating bets in the event of disputes. 

            </div>
            <div className="sub-txt">
            If the Betting company has reason to believe that the bet was made after the outcome of an event became known or after the selected athlete/ team got a clear advantage (significant advantage in the score, sending off or substitution of player, etc.), it gets the right to cancel these bets (make a refund), both winning and losing.
            </div>
            <div className="sub-txt">
            The Betting company uses its own data on the actual course of competitions for betting settlement. If the results of events cannot be determined (broadcast failure, lack of results in official information sources, or other reasons), these events will be settled with odds 1. 
            </div>
            <div className="sub-txt">
            The Client must check the correctness of the bet slip because, in case of an error, the Betting company can refund the bet, regardless of the reason caused this situation and the culprit of the incident. 
            </div>
            <div className="sub-txt">
            Claims on disputed issues are accepted within 10 days from the date of disputed bet calculation. No claims will be accepted after this application date expired. In the questionable situations, which do not have precedents the Betting company has a right to make a final decision.
            </div>

            <div className="sub-heading"> FINANCIAL RESTRICTIONS</div>
            <div className="sub-txt">
            The maximum bet sum can be determined by the Betting company specifically for each event or sports, and is subject to change without prior written notice. The maximum bet sum for individual events may be limited by the Betting company.
            </div>
            <div className="sub-txt">
            The maximum odds for the "Combo" are 10 000. If the product of odds for a winning "Combo" exceeds the maximum amount, such an "Combo" is calculated with odds equal to 10 000. The winning sum cannot exceed the size of the maximum payout for a single bet. "Combo +" odd is a regular odd.
            </div>
            <div className="sub-heading"> BETS SETTLEMENT</div>
            <div className="sub-txt">
            Bets on the eSports events are calculated based on the General Rules, but the specific nature of the calculation is also taken into account. 
            </div>
            <div className="sub-heading"> Cash Out</div>
            <div className="sub-txt">
               <ul>
                <li>"CashOut" option is the ability to settle your bet before its outcome is known.</li>
                <li>"CashOut" amount depends on the course of events and changes of odds in the game that you selected. It may be higher or lower than your original bet.</li>
                <li>"CashOut" option is available only for your unsettled bets.</li>
                <li>"CashOut" option will be active both for Pre-match and Live betting.</li>
                <li>If you have several bets, then "CashOut" option will be available separately for each of these bets.</li>
                <li>"CashOut" option is available only for the whole Parlay but not for its separate events. </li>
                <li>"CashOut" option is provided on the condition that the markets, in which you have placed your bets, are still open.</li>
                <li>You can apply "CashOut" option to your unsettled bets in the "Account History" tab.</li>
                <li>The settlement amount will be displayed next to each of your bets for which "CashOut" is available.</li>
                <li>Betting company does not guarantee that "CashOut" option will be available at any time.</li>
                <li>If your "CashOut" request for an individual bet is successful, then your bet will be settled instantly and the appropriate amount of funds will be credited to your gaming account. In this case, the result of selected event will not matter for settlement of this bet.</li>
                <li>Betting company reserves the right to accept or reject any "CashOut" request for any kinds of sports, competitions or markets.</li>
               </ul>
            </div>
            <div className="sub-heading">RULES FOR ENROLLMENT WINNINGS</div>
            <div className="sub-txt">
            After the announcement of the actual sports events results on official websites of the competition organizers, the Betting company credites of winnings for client bets.
            </div>
            <div className="sub-txt">
            BC reserves the right to suspend the settlement of bets and accrual of winnings if the Betting company has substantial grounds to believe that the that the following happened:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>prerequisites to suspect a fixed match</li>
                    <li>certain doubts about event fairness;</li>
                    <li>manipulations with the turnover or bet odds.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Evidence for the above may be based on the volume, size and structure of certain types of bets in BC. Further proceedings and investigations on events, that show signs of any fraud, will be conducted by the official partners of the Betting company. Bets on events with signs of fraud will not be settled until all ongoing proceedings and investigations are completed. If the deception of the event is confirmed in the official conclusions of the partners, BC may decide to refuse payments and declare all bets on such events invalid. The verdict rendered, based on the final decision made in the course of proceedings and investigations by the company's official partners authorized to take such actions, is final. However, until the investigation is officially completed, BC reserves the right to review written applications from Clients requesting a refund of bets on any event that is being reviewed and/or investigated.
            </div>
            <div className="sub-txt">
            If there are certain suspicions of fraudulent actions detected (arbitrage betting, multi-accounting or using software to automate bets), the Betting company may suspend client's gaming account for any operations (future bets, cash withdrawal, others) for the investigation period. The detailed verification process can take up to fourteen business days.
            </div>
            <div className="sub-txt">
            If facts of fraudulent actions are confirmed, the Betting company may decide to cancel all bets made by the Client and refund all the funds credited to the balance of Client’s gaming account.
            </div>
            <div className="sub-txt">
            The Betting company may also declare an agreement concluded with a Client (before or after an event that shows signs of fraud) as invalid, refusing to fulfill any obligations under such an agreement (payment of winnings or refund of bets), if such winnings or bets are proved to be directly related to the fraudulent outcome of the event in which the Client:
            </div>
            <div className="sub-txt">
            1) is an athlete, coach, employee or owner of a sports club that took part in an event which is showing signs of fraud;
            </div>
            <div className="sub-txt">
            2) is an active athlete, referee or commentator;
            </div>
            <div className="sub-txt">
            3) appeared as a person in respect of whom bets were made on behalf of any persons involved, specified in subclauses "1" or "2".
            </div>
            <div className="sub-txt">
            This rule is applied by the Betting company regardless of the period (when it became known that its Client appears as one of the designated persons).
            </div>
            <div className="sub-txt">
            If the Betting company has reasonable suspicions about a dishonest sports game or influence on the immediate course of an event in respect of which the Client and the Betting company have entered into a bet, the Betting company may recognize the bet on the outcome of this event (in full or any part thereof) as doubtful. This will result in the suspension of bet calculation and payment of winnings to the Client on this bet until the official end of all proceedings initiated by the Betting company or other persons regarding suspicions of fraud and unfair athletic game. If the fact/facts of the unfair sport game during or the influence of the result of the event are established during investigations, the Betting company may refuse to pay the winnings or refund the bet to the Client, simultaneously contacting law enforcement officials for assistance in establishing the involvement/complicity of the Client in an unfair contest and holding him/her accountable as established by current legislation.
            </div>
            <div className="sub-txt">
            An sport game showing all the signs of deception includes situations where the game result was completely or partially predetermined (but not limited to):
            </div>
            <div className="sub-txt">
                <ul>
                    <li> fixed match as defined in the regulations for various sports competitions;</li>
                    <li>collusion of the event participants (with each other and/or with a third party);</li>
                    <li>Illegal influence on the result of an official sports competition or another event.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Checking the integrity of the game, the Betting company can request the Client to explain the principles of certain bets, why these events interested him/her, what considerations guided his/her decision-making regarding a bet or several bets, or any other information related to the bet that the Betting company considers appropriate to ask. If the answers provided by the Client do not dispel doubts about bet integrity and game transparency, this may lead to a loss of confidence of the Betting company toward the Client. And if, for some reason, Client's account balance has become negative, the Betting company may also take this fact into account before making any payments to the Client.
            </div>
            <div className="sub-txt fw-normal text-white">
            "Combo +"
            </div>
            <div className="sub-txt">
                <ul>
                    <li>All combo bets with size N is added the odds X. Where:
                    
                        <div>
                        N - the number of outcomes in the combo; <br />
                        X - is a multiplier/odd "Combo +" which increases the payout to the player.
                        </div>
                    </li>
                    <li>Size table "Combo +":
                        <ul>
                            <li>4 events - X odd is 1.08;</li>
                            <li>6 events - X odd is 1.15;</li>
                            <li>8 events - X odd is 1.2;</li>
                            <li>10 events - X odd is 1.5;</li>

                        </ul>
                    </li>
                    <li>Only outcomes with odds ≥ 1.4.</li>
                    <li>Live and prematch bets.</li>
                    <li>Any bet amount.</li>
                    <li>If there are more than 2 events in the combo that is calculated as a "refund", the odd X=1</li>
                    <li>Сashout cancels " Сombo +" multiplier. That is, if the client wants to calculate the bet by cashout, the combo service is canceled.</li>
                    <li>If at least one outcome is settled as HalfWon, HalfLost, or Refund, the booster odd will be recalculated according to the new odds.</li>
                </ul>
            </div>
            <div className="sub-heading">Football</div>
            <div className="sub-txt">
            Bets on football matches are accepted for the main time stipulated by the rules of the match or tournament that includes the compensated time added by the match referee after the main time of the half or the match. Events that took place during the time compensated by the referee are considered to have occurred at the 45th or 90th minute.
            </div>
            <div className="sub-txt">
            The football bets are available for one team to win or draw or with handicap. Bets in Cup matches are also available on whether one of the teams go to the next round, depends by the number of matches played.
            </div>
            <div className="sub-txt">
            The line also offers bets on double results:
            </div>
            <div className="sub-txt">
               <ul>
                <li>1Х – home team team victory or draw;</li>
                <li>X2 – draw or away team victory;</li>
                <li>12 – home or away team victory.</li>
               </ul>
            </div>
            <div className="sub-txt">
            Bets are accepted on handicaps and totals (total number of goals scored). The Client can choose different values of handicaps (both positive and negative) and totals in the line. If the Client bet on an integer "Total" and get into it, the bet is calculated with odds equal to "1". Similarly, the Client can bet on the individual "Total" of the team. If the Client get into the "Total", the bet is calculated with odds equal to "1". 
            </div>
            <div className="sub-txt">
            For cup competitions consisting of multiple matches, the market <span className='fw-normal text-white'>"To qualify"</span> is offered. Qualification is determined by the total of all completed matches between the respective pair of teams.
            </div>
            <div className="sub-txt">
            The bet on " Who will score more goals " offers a comparison of two teams from different matches when the team that scores more goals will be recognized as the best. Bets are not accepted for "Combos" and "Systems" with the proposed teams and are calculated with odds equal to "1" upon the same performance from both teams.
            </div>
            <div className="sub-txt">
            There are available bets on match listing:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>"Match Result" bet (the Client need to predict the final match score);</li>
                    <li>"Half time/ Full time Result" bet (the Client need to predict both the result of the first half and the entire match). Initial letters are used in the line to indicate these outcomes: V - victory, D - draw. The first place indicates the outcome of the first half, and the second one indicates the match outcome. The outcome of the second half does not matter for the calculation of this bet. For example, if the match finished 1:1, and the first half 1:0 - the bet on the "V1D" outcome is winning; if the match finished 1:0, and the first half 0:0 - the bet on the "DV1" outcome is winning; if both the first half and the match finished 1:0 - the bet on "V1V1" is winning;</li>
                    <li>"Goal in the First Half" bet means that Client can choose between "Yes/No" options to predict whether a goal will be scored before the break; </li>
                    <li>The Client can bet on the time and player scoring the first goal, on the totals of the first and/ or second half, etc.;</li>
                    <li>All players who took part in the match are considered as runners. If for any reason an unlisted player scores a goal all bets on listed players stand. If a player is listed but does not take part in the match, all related bets made on the player will be considered as void.</li>
                    <li>goals scored by replaced players are also taken into account for the "At Least One Players Scores Two or More Goals" bet.</li>
                </ul>
            </div>
            <div className="sub-txt">
            For the "Home/Away" game, the client is asked to predict whether the difference between the goals scored by the "Home" and "Away" teams will be more or less than the "handicap" offered.
            </div>
            <div className="sub-txt">
            For the "round total" game, the client is asked to predict whether the total number of goals scored by the "Home" and "Away" teams together will be more or less than the number offered by the Betting company. Additionally, Clients can bet on playing round:
            </div>
            <div className="sub-txt">
               <ul>
                <li>number of "Home" victories;</li>
                <li>number of "Away" victories;</li>
                <li>number of draws in the round;</li>
                <li>number of "Away" points, etc.</li>
               </ul>
            </div>
            <div className="sub-txt">
            "Home/ Away" and "round total" bets are not accepted in "Combos" with any other events of the same tour. If one or more matches in the tour were interrupted and were not completed within 12 hours, then bets for "Home/Away" and "round total" games are calculated with odds equal to "1".
            Bets on "Corner Kicks, Yellow/Red Cards, Fouls, Shots on Target, Offsides, and Percentage of Ball Possession, Substitutes" include the following: 

            </div>
            <div className="sub-txt">
            - Corner kicks assigned, but not completed are not counted in the statistics. If the referee does not count the corner kick but assigns it again, the corner kick will be counted only once.
            </div>
            <div className="sub-txt">
            - The second yellow card which will lead to the in the player's sending off, is considered both a second yellow card and a red card. A player will be considered as sent-off if he was in the field at the time of being sent off.). Cards shown to players on the bench or to team personnel (coaching staff, managers, etc.) are not counted. Cards shown after the end of the first half are considered as shown in the second half.
            </div>
            <div className="sub-txt">
            If two or more players from different teams get yellow or red cards based on a single game episode - bets on the "Match First Yellow/Red Card" will be calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            If two or more players from different teams get yellow or red cards based on a single game episode - bets on the "Match First Yellow/Red Card" will be calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            Booking Points Markets. Yellow card counts as 10 points and red or yellow red cards as 25. The 2nd yellow for one player which leads to a yellow red card is not considered. As a consequence one player cannot cause more than 35 booking points. 
            </div>
            <div className="sub-txt">
            For the bet on "Time of the First or Last Goal" in a match, indicating the time intervals, the Client needs to predict during which period the first or last goal in the match will be scored. If the game finishes 0:0, the bet will be calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            In the " Who will score the next goal " market, there are three possible outcomes for the Client: "Team 1", "Team 2" or "Zero Score".
            </div>
            <div className="sub-txt py-2">
            For bets on "Most Productive Match", "Least Productive Match" or "Match with Most (Least) Goals Scored", the Client needs to predict the match/matches from the selected list in which the most or least goals will be scored. If the highest number of goals is scored in two or more matches - bets on these matches are settled at the odds 1. 
            </div>
            <div className="sub-txt">
               <ul>
                <li>If the rules or format of a match differ from our accepted norm, we reserve the right to void any market.</li>
                <li>If one or more matches selected in the line were postponed for more than 36 - 48 hours from the originally officially specified time, or were interrupted and not finished within 12 - 48 hours, all bets on these games will be settled at the odds 1.The exact settlement interval is determined by the bookmaker individually within the specified time range.</li>
                <li>In case of unusual time periods (e.g. 3 periods of 30 minutes each), bets will stand as long as the total statutory time of the match is between 45 minutes and 120 minutes.</li>
               </ul>
            </div>
            <div className="sub-txt">
            The bookmaker line accepts bets on the event that first occurs in a certain game period. The Client need to predict what will happen during a certain time period: a goal scored, a corner kick, a goal kick, an offside, a foul, an out, or none of these. Similarly, bets on comparisons of the above events are available -  the Clients can bet which of the specified events (for example, a goal or offside, a goal kick or corner kick) will occur earlier in a certain period, or none of the above.
            </div>
            <div className="sub-txt">
            The following events are considered as occurred: a corner kick in the case of a kick executed from a corner flag; a goal kick in the case of an actual goal kick; offside in the case of a free kick performed upon recording the offside; out in the case of an actual throw-in; foul in the case of a free or penalty kick performed upon recording a violation.
            </div>
            <div className="sub-txt">
            The source for determining the results and matches statistics can be checked from the following sites:
            </div>
            <div className="sub-txt">
               <ul>
                <li><a href="http://www.premierleague.com/" className='fw-normal anchor-text'>http://www.premierleague.com/</a> - Premier League, England</li>
                <li><a href="http://www.bundesliga.de/de/liga/ " className='fw-normal anchor-text'>http://www.bundesliga.de/de/liga/ </a> - Bundesliga, Germany</li>
                <li><a href="http://www.lequipe.fr/Football/Ligue-1/ " className='fw-normal anchor-text'>http://www.lequipe.fr/Football/Ligue-1/ </a> - League 1, France</li>
                <li><a href="http://www.raisport.rai.it/" className='fw-normal anchor-text'>http://www.raisport.rai.it/</a> - UEFA Champions League and UEFA Europa League</li>
                <li>When determining the statistical indicators of football matches, the source for determining the results is the information posted on the website <a href="https://www.whoscored.com/ " className='fw-normal anchor-text'>https://www.whoscored.com/ </a> . In the absence of information on this resource, the calculation of statistical indicators will be carried out on the basis of an alternative source <a href="https://www.flashscore.com/ " className='fw-normal anchor-text'>https://www.flashscore.com/ </a> If a situation arises that none of these resources provides the necessary information on statistical indicators, the company reserves the right to make a calculation based on internal/alternative sources</li>
               </ul>
            </div>
            <div className="sub-txt">
            In the absence of the necessary information or obvious errors in the protocols on these sources, the Betting company can sattle bets based on statistical data from its own internal sources.
            </div>
            <div className="sub-txt">
            Time accounting of each event to calculate the following: goal – the same time as the timer at the moment of the ball crossing the goal line (scored goal);  corner kick – time at the moment of the kick from the corner mark; foul – time at the moment of the free or penalty kick upon recording a violation of the rules; offside – time at the moment of the free kick upon recording the offside; goal kick – time at the moment of the goal kick; out – the same time as the timer at the moment of the out thrown. The bet is sattled based on the match timer for the TV broadcaster, which will be specified in the comments to a specific match on the line. The parallel timer which is starled in parallel by the Betting company is considered the main timer for sattling the bet when there is no time indicator in the TV broadcast. To determine and make a final decision in disputed situations, the Betting company can operate information from internal sources about the match.
            </div>
            <div className="sub-txt">
            In the "First 5-Minute Match Prediction" the Client proposed to predict before the match (pre-match bet) what event will take place first within the first five minutes (see Clause 18 for live bets):
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Goal scored;</li>
                    <li>Penalty appointed;</li>
                    <li>Corner kick appointed;</li>
                    <li>Yellow or red card shown;</li>
                    <li>None of the above.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Bets on the first five minutes of the match will be sattled based on the period from 0:00 to 5:00 (from the first to the fifth minute inclusive) of the event. To consider a bet as a winning, the goal shall be scored in the specified period (the goal kick and the ball crossing the goal line shall be recorded in a certain period). If the kick was made in a given period, and the goal was scored beyond such period, the bet will be considered as lost. The corner kick shall be assigned within the specified time period and, if assigned, will be counted. The penalty shall also be assigned within the specified time period. Penalties will be ignored if the penalty event happened within the allotted time period, and its appointment by referee outside of the period . If the penalty is awarded within the specified period, and the attempt to execute it occurs later - the bet is still considered won. Yellow or red cards will be counted at the moment when they will be shown by the referee, but not at the moment of the foul commitment. If the reason for the yellow or red card belongs to the specified period but the direct display of the card does not, the card will not be counted. Only cards shown to the field players who participate in the match at the time of card shown will be counted. Appointed penalty is considered as the winning outcome if the penalty had been appointed before the referee showed the card for a foul.
            </div>
            <div className="sub-txt">
            It is proposed to the Client to specify the outcome of the match based on the European handicap. In case of a bet with a European handicap, the Client needs to predict the outcome of the match with condition that the match begins from a certain score, specified in the value of the handicap. The final score for such bets is calculated by adding the game result and the handicap value. The following outcomes are possible when betting with the European handicap:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Home win with handicap;</li>
                    <li>Away win with handicap;</li>
                    <li>Draw with handicap.</li>
                </ul>
            </div>
            <div className="sub-txt">
            The European handicap does not imply a partial or full refund of the bet: the client can either win or lose the bet in full.
            </div>
            <div className="sub-txt">
            Example 1. Handicap (0-1) means a bet assuming that after the end of the game, 0 is added to the goals scored by the home team, and 1 is added to the goals scored by the away team. The "home win with handicap" outcome will be a win if the match ends with the home team winning by 2 or more goals, for example, 2:0 or 3:1 (2:1 or 3:2 with 0-1 handicap), etc.  The "draw with handicap" outcome will only be a win if the home team wins exactly 1 goal, for example, 1-0 or 2:1 (1:1 or 2:2 with the selected handicap 0-1), etc. The "away win with handicap" outcome will be a win if the away team ends the match with a draw or win, for example, 0:0 or 0:1 (0:1 or 0: 2 with the selected handicap 0-1). 
            </div>
            <div className="sub-txt">
            Example 2. Handicap (1-0) means a bet assuming that after the end of the game, 1 is added to the goals scored by the home team, and 0 is added to the goals scored by the away team. The prediction for the "home win with handicap" outcome will play for any home win or draw, for example, 1:0 or 1:1 (2:0 or 2:1 with the selected handicap 1-0), etc. The bet on the "draw with handicap" outcome will only take place if the away team wins by a single goal difference, for example, 0:1 or 1:2 (1:1 or 2:2 with the selected handicap 1-0), etc. The "away win with handicap" outcome will only be won if the away team triumphs with a difference of two or more goals in 2 or more goals, for example, 0:2 or 1:3 (1:2 or 2:3 with the selected handicap 1-0), etc.
            </div>
            <div className="sub-txt">
            The "Shots on the goal/crossbar kick" market: when settled this bet, one takes into account shots at the goal post or crossbar, after which the ball remained in play (flying out into the pitch  or touching the referee, or a player of one of the teams, or another post or crossbar after a rebound). 
            </div>
            <div className="sub-txt">
                The ball hitting the post or crossbar is not considered in the following cases:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>the goal was scored (the ball flew into the net)</li>
                    <li>the game was stopped before the ball hit the post or crossbar;</li>
                    <li>after hitting the crossbar, the ball crossed the goal line without touching the pitch</li>
                </ul>
            </div>
            <div className="sub-txt">
            Bet on "goalless time periods" assumes that if "n" goals are scored during a match, then the minutes, when goals have been scored, divide a time period of a match (from 0 to 90 minutes) into "n+1" goalless time periods. The duration of goalless time periods is calculated as the difference between:

            </div>
            <div className="sub-txt">
               <ul>
                <li>minutes of the first goal and match start; </li>
                <li>minutes of successive goals;</li>
                <li>minutes of the match end and the last goal.</li>
               </ul>
            </div>
            
            <div className="sub-txt">
            The match starts at 0 minute. Goals scored in the time compensated by the referee for the first and second half are considered goals scored at the 45th and 90th minutes respectively. If there have not been scored any goals, thus, it is considered that there has been one 90-minute goalless period. 
            </div>
            <div className="sub-txt">
            Example 1. If a match has been completed within the main time with 0:0 score, thus, it is considered that there has been only one 90-minute goalless period.
            </div>
            <div className="sub-txt">
            Example 2. If there have been scored 2 goals in a match: at 23rd and 62nd minutes, then there have been only 3 goalless periods: 23 minutes (23-0), 39 minutes (62-23), and 28 minutes (90-62). 
            </div>
            <div className="sub-txt">
            Example 3. If there have been scored 3 goals in a match: at the 15th, 45th, and 90th minutes, then there have been only 4 goalless periods: 15 minutes (15-0), 30 minutes (45-15), 45 minutes (90-45), and a 0-minute period (90-90).
            </div>
            <div className="sub-txt">
            "Cyberfootball" is a betting on the FIFA PC game which is a football simulator with with 2 players take part in it. Games played with settings "two 5-minute halves" and the "beginner" complexity level. If a match is stopped due to technical reasons (disconnect or computer failure) and a re-match is scheduled, bets on the stopped match are settled according to the General Rules, and bets on the re-match are accepted as for a new event. 
            </div>
            <div className="sub-txt-heading pt-3">
            eFootball
            </div>
            <div className="sub-txt">
            Bets on the FIFA PC game (a football simulator with two players) are accepted for matches held with the game settings "two halves 5-minute each" and the “beginner” level.
            </div>
            <div className="sub-txt">
            If a match is stopped due to technical reasons (disconnect, computer failure, other) and re-match appointment - bets on the interrupted match are calculated according to the General Rules, and bets on the re-match are accepted as on a new event. It is not a reason to cancel bets, except in situations where it is impossible to define the final result of the match, or a technical malfunction that interrupts the video broadcast of the event.

            </div>
            <div className="sub-txt">
                <a href="https://esportsbattle.com/en" className='fw-normal anchor-text'>https://esportsbattle.com/en</a>
            </div>
            <div className="sub-txt-heading pt-3">
            Tennis
            </div>
            <div className="sub-txt">
            The Betting company offers the Clients the following betting markets for tennis:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>match winner;</li>
                    <li>score by sets (2:0 or 2:1 for three-set matches, 3:0, 3:1, 3:2 for five-set matches);</li>
                    <li>bet with a handicap on match (handicap can be either positive or negative);</li>
                    <li>bet with a set handicap in a match (handicap can be either positive or negative);</li>
                    <li>bet on the total number of games in a set or match;</li>
                    <li>bet on the number of aces per match for a certain player;</li>
                    <li>bet on the number of double faults for a certain player;</li>
                    <li>bet on a certain player victory in the specified set;</li>
                    <li>bet on whether the match will have a tie-break in one or more sets (the set score is 7:6 or 6:7).</li>
                    <li>The following live bets are also available:
                        <ul>
                            <li>who will win the "x" number of games in a set; </li>
                            <li>who will win the "x" number of points in the game (there is a difference between the number of points in the game and the score of the game: if the current score of the game is 30:15, then three points have played, but not forty five, while the fourth point will be played next in the game, and then the fifth);</li>
                            <li>game score;</li>
                            <li>whether there will be a score of 40:40 in a game, etc.</li>
                        </ul>
                    </li>
                    <li>The company uses its own data about the actual course of the game for the live bets settlement. If, for any reason (lack of results in official information sources or broadcast loss), the results of competition cannot be reliably established, then bets on these events are settled with odds equal to "1". Any claims for bets on a live event are accepted not later than 24 hours after the competition ends.</li>
                </ul>
            </div>
            <div className="sub-txt">
            All bets on tennis matches remain valid until the end of the tournament.
            </div>
            <div className="sub-txt">
            All bets on tennis matches remain valid if the tennis courts surface have been replaced during the tournament. The Betting company provides data about the surface of tennis courts (ground, grass, hard) for information purposes only and does not accept claims about inconsistencies in the data about the type of court surface in the betting line information
            </div>
            <div className="sub-txt">
            <ul>
                <li>In case of a retirement and walk over of any player all undecided bets are considered void (including the market for the Match Winner)</li>
                <li>If a player retires all undecided markets are considered void.</li>
                <li>If penalty point(s) are awarded by the umpire, all bets on that game will stand</li>
                <li>In case of a match is finished before certain points/games were finished, all affected point/game related markets are considered void.</li>
            </ul>
            </div>
            <div className="sub-txt">
            If the regulations have been changed (the planned five-set match was replaced by a three-set match, or vice versa), the bets on a match winner, first set winner, total of the first set and game handicap for the first set are sattled at the at the odds offered in the line. All other bets will be refunded.
            If one or more players of one of the teams in team competitions are replaced (for example, the Davis Cup), regardless of the reasons, bets on the match outcome and qualifying to the next round remain valid.
            </div>
            <div className="sub-txt">
            Bets on tennis matches in doubles are calculated with odds equal to "1", if the composition of the pair was initially specified when replacing at least one of the Clients. Otherwise, the bet remains relevant.
            If two or more tennis Clients win the tournament at the same time, then bets on the victory of these Clients in the tournament are calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            If a so-called "championship tie-break" (a race to 10 points won) is played instead of a decisive set in a match, a score of a decisive set is considered as 1:0.  Accordingly, the overall the handicap and the overall total of the match are settled basic on this score.

            </div>
            <div className="sub-txt">
            If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
            If the players/teams are displayed incorrectly, we reserve the right to void betting.

            </div>
            <div className="sub-txt-heading pt-3">
            Special settlement rules
            </div>
            <div className="sub-txt">
                <ul>
                    <li>In the event of a weather delay, schedule change to the order of play or change in time and date of the scheduled match; bets will remain valid unless a walkover or official result is declared. This includes if a match has already begun and been suspended due to time restrictions or bad light.</li>
                    <li>In the event of a walkover, retirement or a default from any player. All un-resulted markets will be made void except the match winner (provided the minimum game length has been played).</li>
                    <li>If a challenge is made or a penalty point awarded, all bets will be settled/resettled on the official score.</li>
                    <li>In the event a match finishes before certain points/games are completed, all affected markets with unsettled bets are void.</li>
                    <li>For settlement purposes, each tie-break or match tie-break is settled as 1 game.</li>
                    <li>In the event of a retirement, set and game related markets that were already settled as lost will remain, unsettled bets will be made void.</li>
                    <li>In the event of a venue or surface change. All bets will remain valid.</li>
                    <li>A minimum of 1 full set must be completed for the match winner to be settled. All other unsettled markets will be void.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Markets
            </div>
            <div className="sub-txt">
            Winner (Match/Set/Game): The player/team who officially wins the match or specified period. In the event of a tie, all bets will settled by the tie-break winner.
            </div>
            <div className="sub-txt">
            Total Games (Match/Set/Game): The total number of games played in the match or specified period. Bets settlement is determined on whether the result will be over or under the total line taken.
            Player/Team Total Games (Match/Set/Game): The total number of games won in the match or specified period by a specific player/team. Bets settlement is determined on whether the result will be over or under the line taken.

            </div>
            <div className="sub-txt">
            Correct Score (Match/Set/Game): Settled on the correct prediction of the final score in the match or specified period.

            </div>
            
            <div className="sub-txt">
            Handicap Games (Match/Set): Determined by which player/team will win the match or set once the specified handicap is applied to the overall match or specified period score.
            </div>
            <div className="sub-txt">
            Total Games Odd/Even (Match/Set): Determined by the total points scored in the match or specified period being either odd or even.
            </div>
            <div className="sub-txt">
            Total Sets: Determined by whether the total number of sets played is over or under the specified line.
            Tiebreak Yes/No: Settled on whether a tiebreak takes place in any set during the match.
            </div>
            <div className="sub-txt">
            Player To Win A Set: Settled on whether the named player wins at least one set during the match.
            Any Set To Nil: Settled on whether either player will win a set 6-0.

            </div>
            <div className="sub-txt">
            Double Result (1st Set/Match): Settled as the winner of the first set along with the winner of the match.

            </div>
            <div className="sub-txt">
            To come from behind: Determined on whether a player/team will be behind in set count but come back and win the match
            </div>
            <div className="sub-txt">
            Highest scoring set: Determined by which set has the greatest number of games played in it. Dead Heat rules apply.

            </div>
            <div className="sub-txt">
            Xth Set – Race to X games: Settled as the player/team who first reaches the specified number of games in the given set.
            </div>
            <div className="sub-txt">
            Xth Set – Xth Game - Break Point Yes/No: Determined by whether there is a break point in the game
                Xth Set – Xth Game – Result after 3rd Point: Determined by the exact score after the 3rd point in the given game is finished.

            </div>
            <div className="sub-txt">
            Event - Winner: The official classified winner of an event (Dead heat rules apply).
            </div>
            <div className="sub-txt">
            Stage of Elimination: Settled on the round a selected player is eliminated from the named competition.
            Winning Quarter/Group: Settled on which quarter / group of the tournament the winner of the tournament comes from

            </div>
            <div className="sub-txt">
            Winning Half: Settled on whether the tournament winner comes from the top or bottom half of the draw.
            </div>
            <div className="sub-txt">
            To Reach The Final: Settled on whether the selected player reached the final of the tournament.
            </div>
            <div className="sub-txt-heading pt-3">
            eTennis
            </div>
            <div className="sub-txt">
            Virtual tennis consists of two tournaments Grandstand Open and Britannia Open, each of which attended by 16 players. One tournament consists of 15 matches and goes in four stages: 1/8 finals, 1/4 finals, semifinals, finals. The duration of one tournament is 26 minutes. The duration of one stage\match is 3 min 45 sec. Matches are held with three sets format (before winning in two sets).
            The acceptance of bets on the forthcoming Grandstand Open stage is held during the Britannia Open and vice versa. The stages for each tournament changed by alternatively:

            </div>
            <div className="sub-txt">
               <ul>
                <li>1/8 Britannia Open - Grandstand Open final</li>
                <li>1/4 Britannia Open - 1/8 Grandstand Open Britannia</li>
                <li>Open semifinals - 1/4 Grandstand Open </li>
                <li>Britannia Open final - Grandstand Open semifinals</li>
               </ul>
            </div>
            <div className="sub-txt">
            Types of bets:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Winning the match (P1 - win 1st player, P2 – win 2nd player);</li>
                    <li>Total (more, less) is calculated by game;</li>
                    <li>Exact score (2:0; 2:1; 0:2; 1:2);</li>
                    <li>Winning the first set (P1 - win 1st player, P2 – win 2nd player);</li>
                    <li>First set Total (more, less) is calculated by game of first set.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Bets are calculated immediately after the match. The result of each event isn’t determined by the Betting Company, and also doesn’t depend on the Betting Company, nor on the client, nor on the amount of placed bets.
            </div>
            <div className="sub-txt">
            If the event didn’t take place due to an application malfunction and also due incorrect bets or odds, then bets will be refunded. Сonnection failure, communication failure, browser errors, closing the window and other technical reasons aren't grounds for canceling a bet, because they don't affect the course of the match, their result, or bets calculation. Broadcast delays also aren't a reason for canceling a bet. Client is responsible for the correct bet selection.
            </div>
            
            <div className="sub-txt-heading pt-3">
            Basketball
            </div>
            <div className="sub-txt">
            The Betting company accepts bets on basketball matches taking into account overtimes (OT), if the scheduled game time ends with a draw. In addition to individual bets on a draw or a clean victory of one of the teams (1, X, 2), bets on such outcomes are accepted for the scheduled game time. In basketball matches, determining the winner of the confrontation provided for two games (home and away games), the match can end in a draw without overtime.
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Example 1: play-off match (according to the regulations, a winner is determined based on the sum of two matches), the first match ends with a score of 85-80, the second match ends with a score of 75-75 (without overtime). In the second match, the rules do NOT provide for overtime, since the score of two matches is 160:155, which guarantees the first team to get to the next round.</li>
                    <li>Example 2: play-off match (according to the regulations, a winner is determined based on the sum of two matches), the first match ends with a score of 78-72 in favor of team 1, the second match ends with a score of 87-81 in favor of team 2. Since the sum of two matches is 159:159, overtime in the second match is appointed to determine a team to pass to the next round (this overtime is only considered when betting on the "Qualifying to the next round)" market).</li>
                </ul>
            </div>
            <div className="sub-txt">
            When betting on a combined total or individual total, the Betting company offers the Clients to predict the total number of the points in a match scored by teams combined or the score of one individual team. 
            </div>
            <div className="sub-txt">
            In some specific matches bets available for each quarter of the match. For example, the client must predict the most/least productive quarter of the match.
            </div>
            <div className="sub-txt">
            Bets on number of points, rebounds scored by the players of either team according to the line are also avaliable. The bet will be refunded if one of the basketball players does not appear on the pitch.
            In the "Duel of players" betting market, the Betting company offers the Client to predict which who will will score more points, make more assists or collect more rebounds. Such bets are settled based on the official match protocol (including overtime, if the scheduled game time ends in a draw). The bet will be refunded if one of the basketball players does not appear on the pitch.

            </div>
            <div className="sub-txt">
            Bets on general "Performance" for basketball players take into account the total sum of points, assists and rebounds of a particular athlete. Blocked shots and steals are not counted as Performance points.  Bets are settled according to the official match protocol (considering overtime). The bet will be refunded if one of the basketball players does not appear on the pitch.
            </div>
            <div className="sub-txt-heading pt-3">
            eBasketball
            </div>
            <div className="sub-txt">
            The calculation rules are in accordance with the rules for calculating basketball bets. Matches are held 4 quarters of 8 minutes each.

            </div>
            <div className="sub-txt">
            Basketball bets on the winning of any team, and a draw, as well as on any results of the second half and fourth quarter are accepted of main time. Bets on all other proposed results are accepted taking into overtime, unless otherwise specified in the coupon and/or line. Bets on Total for a quarter take into account points scored by teams only for this quarter, and not for the entire match right up until the end of this quarter.

            </div>
            <div className="sub-txt">
            Sources
            </div>
            <div className="sub-txt">
            Sources, which the company uses (not only these) for determining the results of eSports events:
            </div>
            <div className="sub-txt">
                <a href="https://esportsbattle.com/en" className='fw-normal anchor-text'>https://esportsbattle.com/en</a>
            </div>
            <div className="sub-txt-heading pt-3">
            Hockey
            </div>
            <div className="sub-txt">
            The Betting company offers clients to bet on hockey matches of various championships. The Client can bet on the match result (a clean victory for one of the teams or a draw), as well as on handicaps and totals. The Betting company offers a line of totals and handicaps (positive and negative) to bet. e of scoring the "total", such bets are calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            Bets on hockey matches are accepted only for the regular time, excluding overtimes (if this is assumed by the competition regulations), except for specially stipulated bets. 
            In the "Performance of Periods" betting market, the Client is asked to predict which of the three periods will be the most productive for the both teams in total (the highest number of goals will be scored).

            </div>
            <div className="sub-txt">
            The following types of bets are available in the betting line:
            </div>
            <div className="sub-txt">
            <ul>
                <li>bets on the clear victory of the selected team or a draw, as well as win with the selected handicap in any of the match periods;</li>
                <li>bets on the total number of goals scored in certain match periods;</li>
                <li>bets on the time of the first goal and the goalscorer;</li>
                <li>bets on the total points ("goal+pass") scored by a particular hockey player in a particular match;</li>
                <li>for NHL matches (regular championship and the playoffs of the Stanley Cup), bets on the number of penalty minutes, individual performance (excluding penalty shootouts) and others; </li>
                <li>For the matches held under the auspices of the International Ice Hockey Federation (IIHF), bets on shots on goal, penalty time and individual performance of players are accepted for the main time; 
                <div>Also, clients can use the "home/away" betting market, which includes the following options:</div>
                </li>
                <li>The Client needs to predict the difference between home and away shots on goal attempts (whether it will be less or more than the offered handicap);</li>
                <li>The Client needs to predict whether the sum of home and away shots on goal attempts will be less or more than the offered total;</li>
                <li>The Client needs to predict the overall result of the playing round: the number of home and away points gained, the number of draws, the number of home and away shots on goal attempts, etc</li>
            </ul>

            </div>
            <div className="sub-txt">
            The home/away market bets are not accepted in "Combo" bets along with any other matches of the corresponding round. If one or more matches have been interrupted and not completed within the time limit set by the rules - such bets of the "home/away" market will be calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            There is also a "Next Goalscorer" bet available for Clients in the betting line, where they can choose one of three options: "Team 1", "Team 2" or "No Score".
            </div>
            <div className="sub-txt">
            According to the terms of the "When will be scored the first (last) goal" bet, indicating the time periods, the clients need to predict in what time period the first/last goal will be scored in the match. If the regular time of the match ends in a draw, the bet will be calculated with odds equal to "1".
            </div>
            <div className="sub-txt">
            As part of the " Come From Behind and Win" bet in a match, the Client needs to predict which team will lose in the score during the game but will be able to win in the of the match in regular time.
            </div>
            <div className="sub-txt">
            If, for some reason, an ice hockey match has not been played on the scheduled date, then all bets on this match are calculated with odds equal to"1".
            </div>
            <div className="sub-txt">
            The Clients can verify the results of hockey matches on the following websites:
            </div>
            <div className="sub-txt">
            <ul>
                <li>NHL - <a href="www.nhl.com" className='fw-normal anchor-text'>www.nhl.com</a></li>
                <li>World Cup - <a href=" www.iihf.com" className='fw-normal anchor-text'> www.iihf.com</a></li>
                <li>Champions League -<a href=" www.championshockeyleague.net" className='fw-normal anchor-text'> wwwchampionshockeyleague.net</a></li>
                <li>Sweden - <a href=" www.swehockey.se" className='fw-normal anchor-text'> www.swehockey.se</a></li>
                <li>Czech Republic - <a href="www.hokej.cz" className='fw-normal anchor-text'>www.hokej.cz</a></li>
                <li>Finland -  <a href="www.finhockey.fi" className='fw-normal anchor-text'>www.finhockey.fi</a></li>
                <li>KHL - <a href="www.khl.ru" className='fw-normal anchor-text'>www.khl.ru</a></li>
                <li>Germany -<a href="www.deb-online.de" className='fw-normal anchor-text'>www.deb-online.de</a></li>
                <li>Norway - <a href="www.hockey.no " className='fw-normal anchor-text'>www.hockey.no </a></li>
                <li>Belarus - <a href="www.hockey.by" className='fw-normal anchor-text'>www.hockey.by</a></li>
                <li>VHL - <a href="www.vhlru.ru" className='fw-normal anchor-text'>www.vhlru.ru </a></li>
            </ul>
                
            </div>
            <div className="sub-txt-heading pt-3">
            eHockey
            </div>
            <div className="sub-txt">
            Bets are accepted on the computer game NHL (a hockey simulator in which is played by 2 players). Matches are held with the settings of the game “3 periods of 3 minutes”, the difficulty of the game and the goalkeeper “semi pros”, the match mode “Arcade”
            </div>
            <div className="sub-txt">
            In case of stopping the match due to technical reasons (computer failure, connection failure, etc) and appointing replay game, bets on a stopped game are settled according to the general rules for calculating bets on interrupted events.
            </div>
            <div className="sub-txt">
            On replay game, bets are accepted as a new event. If due to a technical malfunction the video broadcast of the event is interrupted (connection failure, DDoS, etc.), then this isn’t a reason for canceling bets, except in situations where it is impossible to find out the result of the match. 
            </div>
            <div className="sub-txt">
            <a href="https://esportsbattle.com/en" className='fw-normal anchor-text'>https://esportsbattle.com/en</a>
            </div>
            <div className="sub-txt-heading pt-3">
            Table Tennis
            </div>
            <div className="sub-txt">
            Bets on totals and handicaps in table tennis matches are indicated in points unless otherwise indicated in the betting line of the Betting company.
            </div>
            <div className="sub-txt">
            If the match was interrupted or one of the teams or an athlete has been disqualified, or for some reason has refused to continue the match, the following options are possible:
            </div>
            <div className="sub-txt">
            Example 1. If at least one set has been played in full, then all bets on the match winner are considered valid. All bets that have been actually completed by the time of the game interruption are also considered valid, but bets on handicaps on sets or points in a match, regardless of the score, will be refunded.
            </div>
            <div className="sub-txt">
            Example 2. If the first set has not been played in full, then all bets are calculated based on the current result at the time of the game interruption, and all other bets are calculated with odds equal to "1".

            </div>
            <div className="sub-txt">
            <a href="https://tabletennis.setkacup.com/en/" className='fw-normal anchor-text'>https://tabletennis.setkacup.com/en/</a>
            
            </div>
            <div className="sub-txt-heading pt-3">
            Volleyball
            </div>
            <div className="sub-txt">
            The Betting company offers clients to place bets on volleyball matches with the following markets in the line:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>a match or a set winner</li>
                    <li>whether there will be a tie-break in a match;</li>
                    <li>on totals or handicaps in a set or a match</li>
                </ul>
            </div>
            <div className="sub-txt">
            Handicaps and totals for volleyball matches are indicated in points unless otherwise indicated in the betting line.
            </div>
            <div className="sub-txt">
            If the rules of a volleyball match have been changed (sets have been played to 21 points), then bets on set or match winner and exact score of the match are calculated based on accepted odds. The "Gold Set" is not taken into account when bet is settled. All other bets will be refunded.
            </div>
            <div className="sub-txt-heading pt-3">
            Handball 
            </div>
            <div className="sub-txt">
            The Betting company accepts various bets on handball matches of national championships or European cups, taking into account the result of the game in the regular time and the match rules. Exceptions apply if otherwise specified in the betting line.
            </div>
            <div className="sub-txt">
               <ul>
                <li>Which team will win race to x points?- If a match ends before the Xth is reached, this market is considered void (cancelled). (incl. ot)</li>
                <li>If the match goes to a 7-metre shootout; the markets “Who scores Xth point?” and “Which team will win race to X points?” will be voided.</li>
               </ul>
            </div>
            <div className="sub-txt-heading pt-3">
                Badminton
            </div>
            <div className="sub-txt">
            Bets on totals and handicaps in badminton matches are indicated in points unless otherwise indicated in the betting line of the Betting company.
            </div>
            <div className="sub-txt">
            If the match was interrupted or one of the teams or an athlete has been disqualified, or for some reason has refused to continue the match, the following options are possible:
            </div>
            <div className="sub-txt">
            Example 1. If at least one set has been played in full, then all bets on the match winner are considered valid. All bets that have been actually completed by the time of the game interruption are also considered valid, but bets on handicaps on sets or points in a match, regardless of the score, will be refunded.
            </div>
            <div className="sub-txt">
            Example 2. If the first set has not been played in full, then all bets are calculated based on the current result at the time of the game interruption, and all other bets are calculated with odds equal to "1".
            Setka Cup Badminton Results:
            </div>
            <div className="sub-txt">
            <a href="https://www.facebook.com/setkacupbadminton/" className='fw-normal anchor-text'>https://www.facebook.com/setkacupbadminton/</a>
            
            </div>
            <div className="sub-txt-heading pt-3">
            American football
            </div>
            <div className="sub-txt">
                <ul>
                    <li>All markets are settled on the official result at the end of the scheduled regular time play unless otherwise stated. Overtime will not count unless specified in the market. Should a result for a market not be available, it will be made void after 48 hours.</li>
                    <li>Settlement result will be the published result from the official governing body wherever possible.</li>
                    <li>If a match is postponed or interrupted, bets will be void if it is not resumed within the current game week (Thursday – Wednesday, local stadium time), unless an official winner is declared by the organizers.</li>
                    <li>All bets will be void should the event take place at a venue other than originally advertised, or in the event the home and away team are reversed.</li>
                    <li>For bets to be paid out, a minimum of 55 minutes of a 60-minute match must be completed for markets to be settled. Only markets with a known result will be settled if less than this time is played in a match. Markets whose outcomes were already determined at the time of the match stoppage will be settled (e.g., bets on the first touchdown, first goal, winner of the first quarter, etc.)</li>
                    <li>Player specific markets will be settled on the official statistics. If a player does not play in a game, the bet will be void. Overtime counts for all player prop markets in a game.</li>
                    <li>For Other Outright Markets, all bets will be settled on the team or player that wins the overall event or award, unless otherwise specified in the market (for example; Regular Season Winner)</li>
                    <li>Dead Heat rules apply.</li>
                    <li>If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</li>
                    <li>Match Time and score are for information purposes only and should not be relied upon when placing a bet. We accept no liability for the incorrect display of this information. If you rely on this data to place bets, you do so entirely at your own risk.</li>
                    <li>In the event of a venue change, all markets are considered void.</li>
                    <li>If the teams are displayed incorrectly, we reserve the right to void betting.</li>
                    <li>Touchdown / Field Goal Markets (including ‘Time of First’, ‘Longest Scored’, ‘Shortest Scored’, and ‘Team to Score Longest’): In the event of no score (via the specified method) bets will be void.</li>
                    <li>Will Either Team Score 3 Unanswered Times - Forecast whether either team will score 3 consecutive times during the game. A Score excludes any PAT’s (point after touchdown or 2 point conversions)</li>
                </ul>
            </div>
            <div className="sub-txt">
            Markets
            </div>
            <div className="sub-txt">
            Winner / Moneyline (Match/Half/Quarter): The team which officially wins the match or specified period. In the event of a tie after overtime all bets will be voided unless the draw is a given option.
            Total (Match/Half/Quarter): The total number of points scored in the match or specified period. Bet settlement is determined on whether the result will be over or under the total line taken.

            </div>
            <div className="sub-txt">
            Handicap / Spread (Match/Half/Quarter): Determined by which team will win the match once the specified handicap is applied to the overall match or specified period score.
            Winner / Moneyline 3-Way (Match/Half/Quarter): The official winner in the match or specified period. Bets are settled on either the Home, Tie or Away team who score the most points.
            Total 3-Way (Match/Half/Quarter):  The total number of points scored in the match or specified period. Bets are settled on either the Home, Tie or Away team who score the most points.
            Home/Away Total (Match/Half/Quarter): The number of points scored in the match or specified period by a specific team. Bets settlement is determined on whether the result will be over or under the line taken.

            </div>
            <div className="sub-txt">
            Double Chance (Match/Half/Quarter): Settled as the official result of the match or specified period being included in the selection, where two of the three possible selections will be winners and one selection will be a loser.
            </div>
            <div className="sub-txt">
            Winning Margin (Match/Half/Quarter):  The specified margin (number of points) by which a team wins the match or specified period.
            </div>
            <div className="sub-txt">
            Halftime/Fulltime: Settled on the official winner of the first half of the game and the full-time result.
            Will There Be Overtime: Settled on whether the game ends in a tied score. If a tied score is the official result and no overtime is played, the market will still be settled as a winner.
            Race to X Points (Match/Half/Quarter): Settled on either the home team or away team being the first to score the specified number of points in the given period. Bets will be void if neither team scores enough points.

            </div>
            <div className="sub-txt">
            Odd/Even (Match/Half/Quarter):  Determined by the total points scored in the match or specified period being either odd or even. 
            </div>
            <div className="sub-txt">
            Highest Scoring Half / Quarter: Determined by the highest scoring half or quarter dependent on the selected market. Bets settled on the official result. In the event of a tie, dead heat results will apply.
            </div>
            <div className="sub-txt">
            NFL - Championship – Winner: Settled as the team who wins the NFL Superbowl.
            </div>
            <div className="sub-txt">
            NFL – American Football Conference (AFC) – Winner: Settled as the representative team from the AFC in the NFL Superbowl.
            </div>
            <div className="sub-txt">
            NFL – National Football Conference (NFC) – Winner: Settled as the representative team from the NFC in the NFL Superbowl.
            </div>
            <div className="sub-txt">
            NFL – Winning Conference: Settled as the conference which provides the team who win the NFL Superbowl
            </div>
            <div className="sub-txt">
            NFL – Winning Division: Settled as the division which provides the team who win the NFL Superbowl.
            </div>
            <div className="sub-txt">
            NFL – Division – Winner: Settled as the team who wins the named division at the end of the regular season.
            </div>
            <div className="sub-txt">
            Team – Regular Season Wins: Determined by the total number of wins the given team has at the end of the regular season, being under or over the given total of wins.
            </div>
            <div className="sub-txt pt-3">
            Will They Make the Playoffs – Team: Determined by whether the quoted team advances to the playoffs at the end of the regular season.
            </div>
            <div className="sub-txt">
            Event – Winner: Predict which team will win the named event.
            </div>
            <div className="sub-txt">
            Rapid Markets:
            </div>
            <div className="sub-txt">
                <ul>
                    <li>New first down & yards gained by play penalties will not be considered for settlement purposes</li>
                    <li>In case of no plays, all markets will be settled with the next play or voided if the drive ends before reaching the respective play</li>
                    <li>In case of the drive ended before the respective play number was reached, all markets for the respective play will be considered void. This includes punts and field goals</li>
                    <li>Field goal yardage will not be considered for total yards gained in a play</li>
                    <li>A touchdown is credited as a first down only when the offence scores a touchdown</li>
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Baseball 
            </div>
            <div className="sub-txt">
                <ul>
                    <li>For MLB matches, unless a starting pitcher is listed in the market, all bets will stand in the event of a change to either starting pitcher.</li>
                    <li>Should an event be interrupted, all remaining unsettled markets excluding the moneyline/match winner are considered void if the game does not resume within 12 hours and after 5 full innings of play from the start of the game</li>
                    <li>If a match is postponed or canceled, then bets will still stand providing the original fixture starts within 24 hours of the original start time and a minimum of 7 innings has been played. If the event does not start within 24 hours, all bets will be void.</li>
                    <li>All bets will be void should the event take place at a venue other than originally advertised.</li>
                    <li>All pre-match bets do not include extra innings unless otherwise stated. This also applies to Live-betting where all bets are settled after regular time unless otherwise stated.</li>
                    <li>Markets whose outcomes were already determined at the time of match stoppage will be settled (e.g., first home run, first hit, winner of the first five innings, etc.).</li>
                    <li>If a match is interrupted or canceled and won’t be continued within 48 hours, all undecided markets are considered void. However, moneyline bets are considered official if at least 5 innings are played; or 4.5 innings if the home team is leading.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Markets
            </div>
            <div className="sub-txt pt-3">
            Winner / Moneyline (Match/Innings): Determined by which team officially wins the match or specified period. In the event of a tie after regular time, all bets will be voided unless the draw is a given option.
            Total (Match/Innings): The total number of runs scored in the match or specified period. Bet settlement is determined on whether the result will be over or under the total line taken.

            </div>
            <div className="sub-txt">
            Handicap / Spread (Match/Innings): Determined by which team will win the match once the specified handicap is applied to the overall match or specified period score.
            Winner / Moneyline 3-Way (Match/Innings): The official winner in the match or specified period. Bets are settled on either the Home, Tie, or Away team that scores the most points.
            Total 3-Way (Match/Innings): The total number of runs scored in the match or specified period. Bets are settled on either the Home, Tie, or Away team that scores the most runs.
            Home/Away Total (Match/Innings): The number of runs scored in the match or specified period by a specific team. Bets settlement is determined on whether the result will be over or under the line taken.
            Odd/Even (Match/Innings): Determined by the total runs scored in the match or specified period being either odd or even.
            Winning Margin (Match/Innings): The specified margin (number of runs) by which a team wins the match or specified period.
            Total Hits: The total number of hits recorded in the specified period. Bets are settled on the total number of hits in the specified period being under or over the quoted line.
            Home/Away Total Hits: The total number of hits scored in the specified period. Bets are settled on either the Home, Tie, or Away team that records the most hits in the given period.
            Extra Inning: Determined on whether the game goes into extra innings. Unless otherwise stated, all markets are settled based on the result at the end of regular time.
            Player Props: If a player does not appear in the game, all bets related to that player will be void. If a player has participated in the game but does not achieve the required statistical outcome, the bet will be settled as a loss.

            </div>
            <div className="sub-txt ">
            Outright Markets
            </div>
            <div className="sub-txt">
            MLB - World Series – Winner: Settled as the team that wins the World Series Finals.
            </div>
            <div className="sub-txt">
            MLB – National League – Winner: Settled as the representative team from the National League in the World Series
            </div>
            <div className="sub-txt">
            MLB – American League – Winner: Settled as the representative team from the American League in the World Series.
            </div>
            <div className="sub-txt">
            MLB – Winning League: Settled as the league that provides the team that wins the World Series Finals.
            </div>
            <div className="sub-txt">
            MLB – Winning Division: Settled as the division that provides the team that wins the World Series Finals.
            </div>
            <div className="sub-txt">
            MLB – Division – Winner: Settled as the team that wins the named division at the end of the regular season.
            </div>
            <div className="sub-txt">
            Player awards outrights: Typically awards given to a specific player awarded at the end of the season and settled on the official outcome in accordance with the governing body.
            </div>
            <div className="sub-txt pt-3">
            Settlement and Cancellation Rules

            </div>
            <div className="sub-txt">
                <ul>
                    <li>Any and all onsite information relating to a game inclusive of scoreboards, stats, video streams etc are provided as a guide only. This information could be subject to a delay or be incorrect. No onsite information should be used as a basis for bet placement. Settlement is not based on the information displayed and thus we assume no liability for the delay in any video transmission or data available on the site</li>
                    <li>For doubleheader (shortened innings) matches, bets will be settled according to the official result.</li>
                    <li>In the event of a Mercy Rule being called, all bets will stand on the score at the time.</li>
                    <li>If a starting pitcher change occurs after bets are placed and the market is not listed as "listed pitcher," bets will remain valid.</li>
                    <li>If an incorrect score or match information is offered resulting in a significant error in price of a selection, we reserve the right to void all bets on that selection, market or price. We also may exercise the right to resettle at the correct price at our discretion.</li>
                </ul>
            </div>
            <div className="sub-txt pt-3">
            Rapid Markets
            </div>
            <div className="sub-txt">
               <ul>
                <li>If a hit and an error occur within the same play, a hit will be considered for settlement purposes.</li>
                <li>If a player does not return to bat but related player markets were offered, undecided markets are considered void.</li>
                <li>A foul ball will always be considered a strike for settlement purposes.</li>
               </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Baseball 
            </div>
            <div className="sub-txt-heading pt-3">
            1. To win/3-way betting: 
            </div>
            <div className="sub-txt pt-3">
            All match betting will be settled in accordance with official competition rules.
            In matches affected by adverse weather, bets will be settled according to the official result.
            If there is no official result, all bets will be void.

            </div>
            <div className="sub-txt">
            In the case of a tie, if the official competition rules do not determine a winner then dead-heat rules will apply. 
            In competitions where a bowl off or super over determines a winner, bets will be settled on the official result.
            In First Class Matches, if the official result is a tie, bets will be settled as a dead-heat between both teams and not as a draw. If the Draw was offered, bets on the Draw will be settled as losers in the case of a Tied match.
            If a match is abandoned due to external factors, then bets will be void unless a winner is declared based on the official competition rules.
            If a match is cancelled then all bets will be void if it is not replayed or restarted within 48 hours of its advertised start time. If there is a change in teams from originally advertised then all bets will be void.
            In First Class Matches, in case of Draw, To win(2-way) bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            2. Double Chance 
            </div>
            <div className="sub-txt pt-3">
            A tie will be settled as a dead heat.
            All match betting will be settled in accordance with official competition rules.
            If there is no official result, all bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            3. Tied Match:  
            </div>
            <div className="sub-txt">
            All bets will be settled according to the official result at the end of the match not including super overs, bowl offs or any other means of deciding the winner in the event of a tied match. Must be an official match result for bets to stand. 
            If the match is abandoned or there is no official result, all bets will be void.
            For First Class matches a tie is when the match has reached a natural conclusion with all innings being completed and the final scores are level. If all innings are not completed in their entirety at the end of the match then the official match result will stand. A declared innings counts as a completed innings. 

            </div>
            <div className="sub-txt-heading pt-3">
            4. Toss winner: 
            </div>
            <div className="sub-txt">
            If no toss takes place, all bets will be void.
            </div>
            <div className="sub-txt-heading pt-3">
            5. Most Fours/ Most Sixes:  
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined. or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion. 
            "In First Class matches resulting in a draw, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours. Overthrows, all run fours and extras do not count.
            Fours scored in a super over do not count.
            In First Class games and Test Matches, only first innings fours/sixes will count.

            </div>
            <div className="sub-txt-heading pt-3">
            6. Most Extras:   
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.
            Extras in a super over do not count.
            In First Class games, only first innings extras will count.

            </div>
            <div className="sub-txt-heading pt-3">
            7. Most Run Outs Whilst Fielding:

            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Run Outs in a super over do not count.
            In First Class games, only first innings run outs will count.

            </div>
            <div className="sub-txt-heading pt-3">
            8. Most catches/keeper catches: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. Catches in a super over do not count

            </div>
            <div className="sub-txt-heading pt-3">
            9. Most keeper stumpings: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined or the match has reaches its natural conclusion without either innings being shortened to less than 80% of the originally alloted overs. Bets will stand if either innings is shortened due to it reaching a natural conclusion.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Stumpings in a super over do not count.

            </div>

            <div className="sub-txt-heading pt-3">
            10. 3-way betting. Runs in over #:  
            </div>
            <div className="sub-txt">
            The specified over must be completed for bets to stand unless settlement has already been determined. If during the first over the innings is ended due to external factors, including bad weather, all bets will be void, unless settlement has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            In First Class matches the market refers only to each team’s first innings.
            </div>
            <div className="sub-txt-heading pt-3">
            11. Highest score in First 5/6/10/12/15/20 Overs: 
            </div>
            <div className="sub-txt">
            If the specified number of overs are not completed in both innings the bet will be void, unless settlement of the bet has already been determined.
            In limited overs matches, if the match is shortened due to external factors after the start of the first innings, the market will be void.
            In First Class matches the market refers only to each team’s first innings.
            The market will be settles according to the official result.

            </div>
            <div className="sub-txt-heading pt-3">
            12. The best result of the first partnership / Highest score at 1st dismissal:  
            </div>
            <div className="sub-txt">
            If the batting team reaches the end of their allotted overs, reaches their target or declares before the first wicket falls, the result will be the total amassed.
            For settlement purposes, a batsman retiring hurt does not count as a wicket.
            In limited overs matches, bets will be void if the innings has been reduced (by more than 10%)due to external factors, including bad weather, unless settlement has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            In First Class matches the market refers only to each team’s first innings.

            </div>
            <div className="sub-txt-heading pt-3">
            13. Total Fours / Total Sixes / Total Boundary:  
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.
In drawn First Class matches the whole match counts. Bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
Only fours/sixes/boundaries scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.
Fours/sixes/boundaries scored in a super over do not count


            </div>
            <div className="sub-txt-heading pt-3">
            14. Wickets Total: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Retired hurt does not count as a dismissal


            </div>
            <div className="sub-txt-heading pt-3">
            15. Total runs / Team total runs:   
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Runs scored in super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            16.  Total Ducks  
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. 
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. 
            Ducks scored in super-over do not count.


            </div>
            <div className="sub-txt-heading pt-3">
            17. Extras Total:   
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.
            Extras in a super over do not count.


            </div>
            <div className="sub-txt-heading pt-3">
            18. Total Run Outs:   
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In First Class matches the whole match counts but in the case of a drawn match bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Run outs in a super over do not count.
            </div>
            <div className="sub-txt-heading pt-3">
            19. Total in highest scoring over: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            All runs, including extras, count towards settlement.
            Super overs do not count.
            </div>
            <div className="sub-txt-heading pt-3">
            20. Top batter:    
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather unless the innings has reached a natural conclusion.
            Top batsmen bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player. If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand. If a batsman is substituted in after the in-play market has been offered, and scores the highest individual score, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI is able to bat in their teams first innings, regardless of if they actually bat or not , bets on the market will stand.
            When two or more players score the same number of runs, these bets will settle as winners.
            Runs scored in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            21. Top bowler:   
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to be bowled in either innings at the time the bet was placed due to external factors, including bad weather unless the innings has reached a natural conclusion.
            Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player. If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand. If a bowler is substituted in after the in-play market has been offered, and takes the most wickets, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI bowls at least one delivery in their teams first innings,bets on the market will stand.
            If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, these bets will settle as winners. Wickets taken in a super over don’t count.

            </div>
            <div className="sub-txt-heading pt-3">
            22. Player of the match:   
            </div>
            <div className="sub-txt">
            Bets will be settled on the officially declared man of the match.
            When two or more players are declared as Players of the match, these bets will settle as winners.
            If no man of the match is officially declared then all bets will be void

            </div>
            <div className="sub-txt-heading pt-3">
            23. First Ball Of Match / Second Ball Of Match / Third Ball Of Match:    
            </div>
            <div className="sub-txt">
            Dead balls do not count, at least one ball must be delivered or will be made void. 
            Wides/No Balls/Bye/leg byes - runs allotted to these do not apply in this market e.g. 5 wides is a settled as Wide.

            </div>
            <div className="sub-txt-heading pt-3">
            24. Wide balls total:    
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to be bowled due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Wides in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            25. Runs total in delivery:    
            </div>
            <div className="sub-txt">
            The result will be determined by the number of runs added to the team total, off the specified delivery.
            For settlement purposes, all illegal balls count as deliveries. For example, if an over starts with a wide, then the first delivery will be settled as 1 and, although there has not been a legal ball bowled, the next ball will be deemed as delivery 2 for that over.
            If a delivery leads to free hit or a free hit is to be re-bowled because of an illegal delivery, the runs scored off the additional delivery do not count.
            All runs, whether off the bat or not are included. For example, a wide with three extra runs taken equates to 4 runs in total off that delivery.

            </div>
            <div className="sub-txt-heading pt-3">
            26. Runs total in Over #:    
            </div>
            <div className="sub-txt">
            The specified over must be completed for bets to stand unless settlement has already been determined. If an innings ends during an over then that over will be deemed to be complete unless the innings is ended due to external factors, including bad weather, in which case all bets will be void, unless settlement has already been determined.
            If the over does not commence for any reason, all bets will be void.
            Extras and penalty runs will only count if they are accredited to a particular delivery within that over.

            </div>
            <div className="sub-txt-heading pt-3">
            27. Wicket in Over #:   
            </div>
            <div className="sub-txt">
            As “Runs total in Over”.
            For settlement purposes, any wicket will count, including run outs. A batsman retiring hurt does not count as a wicket. If a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball.

            </div>
            <div className="sub-txt-heading pt-3">
            28. Over Odd/Even:  
            </div>
            <div className="sub-txt">
            As “Runs total in Over”.
            Zero will be deemed to be an even number.
            </div>
            <div className="sub-txt-heading pt-3">
            29. Match Odd/Even:    
            </div>
            <div className="sub-txt">
            As “Total runs”.
            </div>
            <div className="sub-txt-heading pt-3">
            30. First 5/6/10/15/20 Overs total:    
            </div>
            <div className="sub-txt">
            If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.
            </div>
            <div className="sub-txt-heading pt-3">
            31. Total dismissals at first 5/10/15 Overs:    
            </div>
            <div className="sub-txt">
            If the specified number of overs are not complete the bet will be void, unless the team is all out, declares, reaches their target or settlement of the bet has already been determined.
            For settlement purposes, if a batsman is timed out or retired out then the wicket is deemed to have taken place on the previous ball.

            </div>
            <div className="sub-txt-heading pt-3">
            32. 3-way betting. Innings:   
            </div>
            <div className="sub-txt">
            Both teams must complete their first innings for bets to stand.
            </div>
            <div className="sub-txt-heading pt-3">
            33. Innings Runs: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. Bets will also be void in drawn first class matches, if less than 60 overs have been bowled in an incomplete innings, unless settlement of the bet has already been determined. If a team declares, that innings will be considered complete for the purposes of settlement.
            Penalty runs awarded after the conclusion of the innings, will not count towards innings runs.

            </div>

            <div className="sub-txt-heading pt-3">
            34. Innings Dismissals total: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Retired hurt does not count as a dismissal.

            </div>
            <div className="sub-txt-heading pt-3">
            35. Innings Fours total / Innings Sixes total:  
            </div>
            <div className="sub-txt">
            Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.
            Fours/sixes scored in a super over do not count.
            </div>
            <div className="sub-txt-heading pt-3">
            36. Innings Extras total:     
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.
            In first class or Test matches at least 60 overs must be bowled in the Innings unless team is all out or declares. All wide deliveries, no balls, byes, leg byes and penalty runs in the match count towards the final result. If there are runs off the bat as well as extras from the same delivery, the runs off the bat do not count towards the final total.
            Extras in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            37. Innings Run Outs total: 
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather, unless settlement of the bet has already been determined. Bets placed on a future innings will remain valid regardless of the runs scored in any current or previous innings.
            In first class or Test matches at least 60 overs must be bowled in the Innings unless team is all out or declares.
            Run outs in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            38. Innings Wide balls total:     
            </div>
            <div className="sub-txt">
            Wides in a super over do not count.
            </div>
            <div className="sub-txt-heading pt-3">
            39. Top batter in Innings: 
            </div>
            <div className="sub-txt">
            The result of this market is determined on the batsman with the highest individual score in a team’s innings.
            In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.
            Top batsman bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            If a player was named at the toss, but later is removed as a
            concussion sub, that player will still be counted, as will the replacement player.
            If a batsman does not bat, but was named in the starting XI, bets on that batsman will stand.
            If a batsman is substituted in after the in-play market has been offered, and scores the highest individual score, bets on the market will be void, unless there is a dead-heat.
            If a substitute (concussion, or otherwise) not named in the original XI is able to bat in their teams first innings, regardless of if they actually bat or not , bets on the market will stand.
            In the case of two or more batsmen winning with the same total the winner shall be the one who has faced the fewest deliveries, if that is the same the winner shall be who has hit the most boundaries, if there is still no clear winner then bets will be void
            Runs scored in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            40. Top Bowler in Innings:    
            </div>
            <div className="sub-txt">
            The result of this market is determined on the bowler with the highest individual number of wickets in an individual innings.
            In limited overs matches, bets will be void if it has not been possible to complete at least 50% of the overs scheduled to have been bowled at the time the bet was placed due to external factors, including bad weather.
            Top bowler bets for First Class matches apply only to the first innings of each team, and will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined. If a player was named at the toss, but later is removed as a concussion sub, that player will still be counted, as will the replacement player.  If a bowler does not bowl, but was named in the starting XI, bets on that bowler will stand.  If a bowler is substituted in after the in-play market has been offered, and takes the most wickets, bets on the market will be void, unless there is a dead-heat. If a substitute (concussion, or otherwise) not named in the original XI bowls at least one delivery in their teams first innings,bets on the market will stand."
            If two or more bowlers have taken the same number of wickets, the bowler who has conceded the fewest runs will be the winner. If there are two or more bowlers with the same wickets taken and runs conceded, dead heat rules will apply. Wickets taken in a super over don’t count.
            If no bowlers take a wicket in an innings then all bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            41. Total Runs Player / Player to score 25/50/75/100 runs / Highest individual score / Top batter total:    
            </div>
            <div className="sub-txt">
            If the batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his score will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting 11, bets will be void.
            If a batsman retires hurt, but returns later, the total runs scored by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.
            In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.
            Runs scored in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            42. Will score 50/100 runs:    
            </div>
            <div className="sub-txt">
            Same as "Total Runs Player"
            </div>
            <div className="sub-txt-heading pt-3">
            43. Player Total Fours / Player Total Sixes:   
            </div>
            <div className="sub-txt">
            If a batsman finishes the innings not out, as a result of a declaration, the team reaching the end of their allotted overs, or the team reaching their target; his number of fours will be the final result. If a batsman does not bat, the bet will be void. If a batsman is not in the starting XI, bets will be void.
            If a batsman retires hurt, but returns later, the total fours hit by that batsman in the innings will count. If the batsman does not return later, the final result will be as it stood when the batsman retired.
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the batsman is dismissed.
            In drawn First Class matches, bets will be void if fewer than 200 overs are bowled, unless settlement of the bet has already been determined.
            Only fours/sixes scored from the bat (off any delivery – legal or not) will count towards the total fours/sixes. Overthrows, all run fours and extras do not count.
            Fours scored in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            44. Dismissal method (2 ways) / Dismissal method (7 ways) / Player Dismissal method:    
            </div>
            <div className="sub-txt">
            If the specified batsman is not out, all bets will be void.
            If the specified batsman retires, and does not return to bat later, all bets will be void. If that batsman does return to bat later and is out, bets will stand. If the specified wicket does not fall, all bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            45. Fall of # wicket:     
            </div>
            <div className="sub-txt">
            If the batting team reaches the end of their allotted overs, reaches their target or declares before the specified wicket falls, the result will be the total amassed.
            For settlement purposes, a batsman retiring hurt does not count as a wicket.
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, unless settlement has already been determined, or goes on to be determined. Result will be considered determined if the line at which the bet was placed is passed, or the wicket in question falls.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.

            </div>
            <div className="sub-txt-heading pt-3">
            46. Batters H2H:    
            </div>
            <div className="sub-txt">
            Bets will settle based on the official scores for the specified batsmen in the innings, as detailed in the “Total Runs Player” section above.
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, after the bet is placed unless settlement has already been determined.

            </div>
            <div className="sub-txt-heading pt-3">
            47. Bowlers H2H:    
            </div>
            <div className="sub-txt">
            In limited overs matches, bets will be void if it has not been possible to complete at least 80% of the scheduled overs in either innings due to external factors, including bad weather, unless settlement has been determined.
            In drawn First Class matches, bets will be void if fewer than 200 overs have been bowled, unless settlement of the bet has already been determined.
            Both players must be named in the starting XI If either does not then subsequently bowl all bets are still settled.
            Wickets taken in a super over do not count.

            </div>
            <div className="sub-txt-heading pt-3">
            48. Series Winner:   
            </div>
            <div className="sub-txt">
            Bets on Series markets stand as long as at least one scheduled match is completed, unless stated otherwise.
            If there is no further play in a Series after bets are placed, then bets will be void.
            If a Series is drawn and no draw option is offered, bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            49. Series winner after 2 Tests:   
            </div>
            <div className="sub-txt">
            Bets on Series markets stand as long as at least two scheduled matches is completed, unless stated otherwise. 
            If there is no further play in a Series after bets are placed, then bets will be void.
            </div>
            <div className="sub-txt-heading pt-3">
            50. Series Handicap:    
            </div>
            <div className="sub-txt">
            Bets will be void if the number of scheduled games (after bets are placed) are not played, unless settlement of bets is determined.
            </div>
            <div className="sub-txt-heading pt-3">
            51. Team With Highest Innings Score:    
            </div>
            <div className="sub-txt">
            If a Series is drawn, bets will be void.
            </div>
            <div className="sub-txt-heading pt-3">
            52. Series Correct Score:
            </div>
            <div className="sub-txt">
            Series Correct Score - Bets will be void if the number of scheduled games are not played.
            </div>
            <div className="sub-txt-heading pt-3">
            53. Result of the 1st Match Test/Series:    
            </div>
            <div className="sub-txt">
            Bets on Series markets stand as long as at least one scheduled match is completed, unless stated otherwise.
            If there is no further play in a Series after bets are placed, then bets will be void.
            If a Series is drawn and no draw option is offered, bets will be void.

            </div>
            <div className="sub-txt-heading pt-3">
            54. Player Of The Series:    
            </div>
            <div className="sub-txt">
            Any quoted player, who takes no part in the specified series, will be void. Winner as declared by the ICC. Dead-heat rules apply.
            </div>
            <div className="sub-txt-heading pt-3">
            55. Double chance:    
            </div>
            <div className="sub-txt">
            All match betting will be settled in accordance with official results.
            If there is no official result, all bets will be void.
            </div>
            <div className="sub-txt-heading pt-3">
            56. Players sent off / retired out:   
            </div>
            <div className="sub-txt">
            A player being sent off is viewed as retired out, so will be settled as a wicket
            </div>
            <div className="sub-txt-heading pt-3">
            57. Concussion substitutions:   
            </div>
            <div className="sub-txt">
            When a player leaves the field as a concussion substitute, this will not count as a wicket. If the player does not return later, the final result will be as it stood when the player left the field. When a player enters the match as a concussion substitute, for settlement purposes both they and the player replaced will be looked upon as to have played a full part in the match.
            </div>

            <div className="sub-txt-heading pt-3">
                Ecricket   
            </div>
            <div className="sub-txt pt-3">
                <ul>
                    <li>
                        Match Winner
                        <div>If the Match is not completed for whatever reason, and there is no official result, bets will be void.Where there is a tie and a winner is declared due to a Super Over or something similar, bets will be settled on that declared result. In the event of disruptions due to technical issues, where no official winner is declared, bets will be void unless it is possible to resume the Match within 4 hours of the original start time, and a winner is declared.</div>
                    </li>
                    <li>
                    Innings Runs
                        <div>The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                    </li>
                    <li>
                    Runs in Session
                        <div>The specified Session in its entirety must be bowled, unless the batting team is all out or reaches its target within the Session, or bets will be void – unless settlement is already determined.</div>
                    </li>
                    <li>
                        Runs in Next Over
                        <div>The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined. Extras and Penalty Runs will count only where they are accredited to a particular delivery within that Over</div>
                    </li>
                    <li>
                    Fall of Wicket
                        <div>The Team score at the fall of the named wicket will be taken for settlement purposes. If the Innings is completed or the batting team reaches its target then the final Innings score will be deemed the result for settlement purposes.</div>
                    </li>
                    <li>
                    Innings Fours
                        <div>The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                    </li>
                    <li>
                    Innings Sixes
                        <div>The innings in its entirety must be bowled, unless the batting team is all out or reaches its target, or bets will be void – unless settlement is already determined.</div>
                    </li>
                    <li>
                    Runs off Ball x
                        <div>All runs accredited to that ball count, the ball must be a legal and completed. Any illegal deliveries (wides and no balls) will not be counted as balls for the purpose of this market. For example, even if there are two wides bowled in that over, there will still only be 6 legal balls bowled in the over. For clarity if the named ball is a wide and then followed by a boundary four, then the market result will be settled as 5. The ball must be legally bowled for bets to stand. If the named ball is a no ball and leads to a free hit, the runs scored off the free hit will count, for example if the ball is a no ball and then followed by a six off the free hit then the market result will be 7.</div>
                    </li>
                    <li>
                    Method of Dismissal
                        <div>Methods of dismissal only include: Bowled, Caught, LBW, Run Out, Stumped, Obstructing the Field, Hit the Ball Twice, Hit Wicket and Timed Out – any wicket that is not named will be considered ‘Others’. If a batsman Retires this does not count as a dismissal for settlement purposes. Market will be settled on the dismissal method for the specified wicket number, regardless of whether the players are different due to retirements or otherwise. If the Innings ends with the named wicket not falling, then all bets will be void.</div>
                    </li>
                    <li>
                    Most Fours
                        <div>There must be an official match result for bets to stand unless settlement is already determined (the team batting second has already surpassed its target. Fours in a Super Over do not count</div>
                    </li>
                    <li>
                    Most Sixes
                        <div>There must be an official match result for bets to stand unless settlement is already determined (the team batting second has already surpassed its target. Sixes in a Super Over do not count.</div>
                    </li>
                    <li>
                    Match Fours
                        <div>There must be an official match result for bets to stand unless settlement is already determined (the total has already been surpassed). Boundary fours scored from the bat only to count - overthrows, all run fours and extras do not. Fours scored in a super over do not count.</div>
                    </li>
                    <li>
                    Match Sixes
                        <div>There must be an official match result for bets to stand unless settlement is already determined (the total has already been surpassed). Boundary sixes scored from the bat only to count. Sixes scored in a super over does not count.</div>
                    </li>
                    <li>
                    Batsman Fours
                        <div>Boundary Fours scored from the bat only to count - overthrows, all run fours and extras do not. Fours hit in a Super Over do not count.
                    </div>
                    </li>
                    <li>
                    Batsman Sixes
                        <div>Boundary Sixes scored from the bat only to count. Sixes hit in a Super Over do not count</div>
                    </li>
                    <li>
                    Four in Next Over
                        <div>Fours accredited to batsmen only to count. Byes, Leg byes or Wides do not count. Fours hit from a No Ball will count if the Four is accredited to the batsman. The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result is already detemined. Overthrows do not count towards the batsman and so do not count for the purposes of this market</div>
                    </li>
                    <li>
                    Six in Next Over
                        <div>Sixes hit by a batsman only to count. The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined. If a total of Six runs is scored from an individual delivery in the Over but this is not a Six hit by a batsman this does not count for the purposes of this market.</div>
                    </li>
                    <li>
                    Wicket in Next Over
                        <div>The Over in its entirety must be bowled unless the batting team is All Out or reaches its target within the Over, unless the result of this market is already determined.Source:</div>
                    </li>
                </ul>
            </div>
            <div className="sub-txt">
            <a href="https://www.intelligentcricket.com/games/games-page?gameType=QUANTUM" className='fw-normal anchor-text'>https://www.intelligentcricket.com/games/games-page?gameType=QUANTUM</a>
            </div>
            <div className="sub-txt-heading pt-3">
            MMA   
            </div>
            <div className="sub-txt pt-3">
                <ul>
                    <li>The Betting company offers the Client the following types of bets:</li>
                    <li>Who will win the fight: winning by knockout or technical knockout, by submission or surrender of an opponent, decision of the judges or technical decision (based on points), disqualification or failure, other.</li>
                    <li>The bet on the ahead-of-time win by winning by knockout or technical knockout, by submission or surrender of an opponent, disqualification or failure, other.</li>
                    <li>The duration of the fight. Predict a specified number of rounds. Only the number of complete rounds counts for settlement purposes</li>
                    <li>The bet on winning a round means that the last round held is considered a win if on of the participating fighters refuses to continue the fight in the break between rounds, or fails to enter the fight after the beginning of the next round</li>
                    <li>If the number of rounds in a match changes, bets on the fight outcome and the method of achieving it remain relevant. Bets on the number of rounds will be refunded.</li>
                    <li>If the referees at MMA tournaments cannot determine the winner of the fight by setting a draw, bets on the fight victory are settled with odds equal to "1". All other bets are settled based on the actual fight outcome</li>
                </ul>
            </div>
            <div className="sub-txt">
            UFC has differences in rules:
            </div>
            <div className="sub-txt pt-3">
                <ul>
                    <li>In the case of a draw at UFC tournaments, bets on winning of the participants are settled at the odds "1". The remaining outcomes are settled according to the actual result.</li>
                    <li>Win - includes winning by Decision (DEC), winning by KO, TKO, winning by Technical Decision (TD) based on points, Disqualification or Failure, winning by submission or surrender of an opponent.</li>
                    <li>Ahead-of-time win – includes winning by KO, TKO, Disqualification or Failure, winning by submission or surrender of an opponent</li>
                    <li>The duration of the fight. Predict a specified number of rounds. Half of the 5 minute round considered 2 minutes. 30 sec. Therefore, 2.5 rounds - this will be 2 minutes 30 seconds of the 3rd round. If the match ends exactly in the middle (2 minutes 30 seconds) of the round, the over/under rate on the total number of rounds held is returned to the players.</li>
                    <li>Win in the round - if the fighter refuses to continue the fight in the break between rounds, or after hitting the gong about the beginning of the next round, does not go out, then the last round held is considered to be winning</li>
                    <li>If for any reason the selected number of rounds changes, then all bets on number of rounds will be refunded. The bets on the result of the fight and the method of its achieving remain in force.</li>
                    
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            eMMA   
            </div>
            <div className="sub-txt">
            Bets are accepted on a computer game UFC (fighting simulator with 2 players take part in).
            Matches are played with game settings “3 rounds by 3 minutes each”, complexity level of the game - «Legend», match mode – “accelerated”, fighting in the ground (wrestling) and painful techniques without prompting. vIf the match is stopped due to technical reasons (computer failure, disconnection, etc.) and re-match appointment, bets on stopped game will be settled according to main rules of settling interrupted events.
            Bets on re-match is accepted as for a new event.
            If due to technical failure the live streaming of the event is interrupted (disconnection, DDoS, etc.), this is not a reason for canceling bets, except for situations in which it is impossible to know the final result of a match.

            </div>
            <div className="sub-txt">
            <a href="https://www.facebook.com/esportsbattleufc" className='fw-normal anchor-text'>https://www.facebook.com/esportsbattleufc</a>
            </div>
            <div className="sub-txt-heading pt-3">
            Boxing   
            </div>
            <div className="sub-txt pt-3">
            Betting company offers Clients to make the following types of bets on boxing matches and various types of martial arts:
            Who will win the fight: winning by knockout or technical knockout, by submission or surrender of an opponent, decision of the judges or technical decision (based on points), disqualification or failure, other.
            The bet on the ahead-of-time win by winning by knockout or technical knockout, by submission or surrender of an opponent, disqualification or failure, other.
            The bet on the fight duration means that clients need to predict the number of rounds completed by boxers or fighters.
            The bet on winning a round means that the last round held is considered a win if on of the participating fighters refuses to continue the fight in the break between rounds, or fails to enter the fight after the beginning of the next round.
            If the number of rounds in a match changes, bets on the fight outcome and the method of achieving it remain relevant. Bets on the number of rounds will be refunded.
            If the referees cannot determine the winner of the fight by setting a draw, bets on the fighters' victories are settled with odds equal to "1". All other bets are settled based on the actual fight result.

            </div>
            <div className="sub-txt-heading pt-3">
            Boxing   
            </div>
            <div className="sub-txt-heading pt-3">
            Futsal
            </div>
            <div className="sub-txt pt-3">
            The Betting company accepts various bets on futsal matches of national championships or European cups, taking into account the result of the game in the regular time and the match rules. Exceptions apply if otherwise specified in the betting line.
            </div>
            <div className="sub-txt-heading pt-3">
            Snooker
            </div>
            <div className="sub-txt">
                <ul>
                    <li>In case of a frame starting but not being completed, all frame related markets will be voided unless the outcome has already been determined.</li>
                    <li>No fouls or free balls are considered for settlement of any Potted-Colour market.</li>
                    <li>In case of a re-rack settlement stays if the outcome was determined before the re-rack.
                    In the case of a retirement of a player or disqualification, all undecided markets are considered void.</li>
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Settlement and cancellation rules
            </div>
            <div className="sub-txt">
                <ul>
                    <li>If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</li>
                    <li>If the players/teams are displayed incorrectly, we reserve the right to void betting.</li>
                    <li>If a match is not completed all undecided markets are considered void.</li>
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Darts
            </div>
            <div className="sub-txt">
            1. In the case of a match not being finished all undecided markets are considered void.
            Settlement and cancellation rules

            </div>
            <div className="sub-txt">
            1. If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
            2. If the players/teams are displayed incorrectly, we reserve the right to void betting.

            </div>
            <div className="sub-txt ">
            3. If a match is not completed all undecided markets are considered void.
            </div>
            <div className="sub-txt">
            4. Bullseye counts as red check out colour.
            </div>
            <div className="sub-txt-heading pt-3">
            Aussie rules

            </div>
            <div className="sub-txt ">
            All markets exclude overtime unless otherwise stated.
            If a match is interrupted and continued within 48h after initial kickoff, all open bets will be settled with the final result (The bet can be voided, before 48 hours has passed, in agreement between the client). Otherwise, all undecided bets are considered void.
            Regular 80 Minutes: Markets are based on the result at the end of a scheduled 80 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time.
            </div>
            <div className="sub-txt">
            Settlement and cancellation rules
            </div>
            <div className="sub-txt">
            If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void betting.
            </div>
            <div className="sub-txt">
            If a match is interrupted or postponed and is not continued within 48h after initial kick-off date betting will be void.
            </div>
            <div className="sub-txt">
            If the team names or category are displayed incorrectly, we reserve the right to void betting.
            </div>
            <div className="sub-txt-heading pt-3">
            Kabaddi
            </div>
            <div className="sub-txt ">
            For the purpose of settlement, the terms Raiding and Defence Points shall include all points scored in the named raid or match excluding any extras assigned to the referenced team during a raid.
            All markets relating to players will be void if that player is not selected in the starting squad. If the player is selected but fails to score any points, then settlement will be zero.
            Points or action during extra time will not be considered in the settlement of any market, except for the Match Winner (2 Way) market that shall be settled on the final official result after any extra time or other qualifying process has been completed
            </div>
            <div className="sub-txt">
            All bets will be void if the match is postponed or cancelled and not replayed within 48 hours of original scheduled start
            </div>
            <div className="sub-txt pt-3">
               <ul>
                <li>Match Winner (2 Way) - Bets will be settled in accordance to the official match result. In the event of extra time, the settlement will be determined by the eventual winner, as per the official result. In the case of a tie, all bets will be void.</li>
                <li>Match Winner (3 Way) / DoubleChance (MainTime) - Bets will be settled in accordance to the official match result. Tied matches will be settled as a Tie, regardless of any extra time played.</li>
                <li>Match Winner Margin / Handicap - The official result at the end of normal play shall be used to settle this market.</li>
                <li>Half Time / Full Time Combinations - The official result at the end of each half shall be used to determine the result</li>
                <li>Total Match Points / Total Match Points (3 Way) / Total Match Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.</li>
                <li>Total Raids / Total Match Successful Raids / Raid Points / Total Match Unsuccessful Raids / Tackle Points / Total Match Empty Raids - Bets will be settled in accordance to the official match scoring.</li>
                <li>Total Match Bonus Points - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line while the other foot is in the air.</li>
                <li>Total Match Super Raids - Bets will be settled in accordance to our definitions. Super raids are defined as raids in which the raiding team score 3 points or more.</li>
                <li>Total Match Super Tackles - Bets will be settled in accordance to our definitions. Super tackles are defined as successful tackles in which the defending team has 3 or fewer players on the mat.</li>
                <li>Total Match All Outs - Bets will be settled in accordance to the official match scoring. All outs are when a team loses all of their players.</li>
                <li>Match Multiples (e.g. Telugu to Win and More Than 70 Points in Game) - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.</li>
                <li>First Half Winner (2 Way) - Bets will be settled in accordance to the official match scoring. The market will be void if points are equal at half time.</li>
                <li>First Half Winner (3 Way) / DoubleChance (Half1) - Bets will be settled in accordance to the official match scoring.</li>
                <li>First Half Points / First Half Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.</li>
                <li>Successful Raid / Raid Points (e.g Raid 10 : Mumba Touch Point) -</li>
                <li>Bets will be settled in accordance to official scoring. Extras do contribute to the settlement but technical points and penalties do not.</li>
                <li>Unsuccessful Raid / Tackle Points (e.g Raid 10: Telegu Tackle Point) - Bets will be settled in accordance to official scoring. Extras do contribute to the settlement but technical points and penalties do not.</li>
                <li>Raid Points (e.g. Raid 10: Total Points) - Bets will be settled in accordance to our definition. All points, for both teams, whether through open play or penalties will contribute to the settlement of this market.</li>
                <li>Bonus Point (e.g. Raid 10: Bonus Point) - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line.  Bets will be void if, due to insufficient defenders, it is not possible to score a bonus point in the named raid.</li>
                <li>Super Raid (e.g. Raid 10: Super Raid) - Bets will be settled in accordance to our definition. Super raids are defined as raids in which the raiding team score 3 points or more, not including any all out points earned.
                Bets will be void if, due to insufficient defenders, it is not possible to achieve a super raid in the named raid.</li>
                <li>Team Match Points / Team Half Points / Team Match Points, Odd Even - Bets will be settled in accordance to the official match scoring. Points are to include all methods of points scoring.</li>
                <li>Team Match Successful Raids / Team Match Raid Points - Bets will be settled in accordance to our definition. Raiding points are defined as points scored by the team raiding, regardless of method.</li>
                <li>Team Match Unsuccessful Raids / Team Match Tackle Points - Bets will be settled in accordance to our definition. Defence points are defined as points scored by the team defending a raid, regardless of method.</li>
                <li>Team Match Bonus Points - Bets will be settled in accordance to the official match scoring. Bonus points are defined as points scored by a raiding team by placing their foot over the bonus line. </li>
                <li>Player Raiding Points (e.g. Khan Player Points) - Bets will be settled in accordance to our definition. Player raiding points are defined as points earned by the named player through touch points and bonus points and do not include tackling points, penalties or all out bonuses. If the player is not present in the starting 7 line-up then the bets will be voided. </li>
                <li>Highest Scoring Raider - Player raiding points are defined as points earned by the named player through touch points and bonus points and do not include tackling points, penalties or all out bonuses. In the event of a tie, the following criteria, in order, shall be used to determine the top raider: most successful raids, most bonus points, first raider to score a point. </li>
                <li>Player Match Up (e.g. Praven v Narwal) - Raiding points only will count. This includes bonus points but not extras or penalties.</li>
                <li>Player Mash Up (e.g. Praven and Narwal) - Raiding points only will count. This includes bonus points but not extras or penalties.</li>
                <li>Player Multiples (e.g. Praven Super Ten and Telugu Win) - Raiding points only will count. This includes bonus points but not extras or penalties. In the event of a Tied match the settlement for this market will be 'No'.</li>
                <li>Toss Winner (2-Way) - Bets will be settled in accordance to the official toss result</li>
                <li>Home-away team market - Bets will be settled in accordance to the official match results of the teams for the game day</li>
                <li>Tournament Team Points/Tournament Player Points - At least 95% of the originally scheduled matches must be played or bets will be void, unless settlement is already determined.</li>
                
               </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Bowls
            </div>
            <div className="sub-txt">
            In case of a retirement and walk over of any player all undecided bets are considered void.
            If a match is interrupted and continued within 48h after initial start time, all open bets will be settled with the final result (The bet can be voided, before 48 hours has passed, in agreement between the client). Otherwise, all undecided bets are considered void.

            </div>
            <div className="sub-txt pt-3">
            Settlement and cancellation rules

            </div>
            <div className="sub-txt">
            1. If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.

            </div>
            <div className="sub-txt">
            2. If the players/teams are displayed incorrectly, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
            3. If a player retires all undecided markets are considered void.
            </div>
            <div className="sub-txt-heading pt-3">
            Biathlon
            </div>
            <div className="sub-txt pt-3">
            Wagering on winner. Winner is determined by the official protocol. If there is more than one winner - bets on these participants will be settled at the odds 1. If one of the participants mentioned in the list does not start - bets on him will be settled at the odds 1.
            </div>
            <div className="sub-txt">
            Wager "Who is higher". The Client predicts which competitor in a predetermined pair will finish at a higher position after the competition (team in relays). If both competitors fall out of the race or one of them does not start - the bets will be refunded.
            Bet on the determining the participant's finishing position. It is proposed to specify the place at which the participant will finish the race. Variants: 1-3 places, 4-10 places, 11+ (i.e. below the 11th place in final protocol).

            </div>
            <div className="sub-txt">
            Wagering on the total number of target misses of the race participants. If the number of target misses is equal to handicap, all bets will be refunded. The bets that have actually finished by the time the participant falls out of the race (or disqualified) are considered valid.

            </div>
            <div className="sub-txt">
            Wagering on the number of misses in each shooting round (1st round, 2nd, 3rd, 4th, etc.). The bets that have actually finished by the time the participant falls out of the race (or disqualified) are considered valid.
            </div>
            <div className="sub-txt">
            The participant has fallen out of the race after two shooting rounds. Bets on the number of misses in the 1st and 2nd shooting rounds are valid. Bets on the 3rd and 4th shooting rounds will be settled at the odds 1.
            </div>
            <div className="sub-txt">
            Comparison - which participant will make more target misses in a predetermined pair. The Client should predict which of the two competitors will make more target misses. If one of the competitors does not start or finish the competition - bets will be refunded.
            </div>
            <div className="sub-txt">
            Comparison "Member of which of the teams will be higher". Select a team whose member finishes higher on the final protocol.
            </div>
            <div className="sub-txt">
            The final results of the race participants will be determined by the official protocol immediately after the competition. Possible changes after that like disqualification will be ignored.
            </div>
            <div className="sub-txt">
            You can check the statistics on the official website:
            </div>
            <div className="sub-txt">
            <a href="www.biathlonworld.com" className='fw-normal anchor-text'>www.biathlonworld.com</a>
            </div>
            <div className="sub-txt-heading pt-3">
            League of Legends
            </div>
            <div className="sub-txt">
            The final settlement is made based on the data recorded immediately after the destruction of the main building (Fortress/Nexus) of one of the opponents. If one of the teams surrenders (the Fortress/Nexus is not destroyed by the opponent), the calculation is made in the same way. The opposing team of the surrendered is awarded a victory.
            Settlement with odds of "1" is possible only until the game start (exit of creeps / minions from the barracks on the line). Further bets in any unplanned situations (leave of player from any team, disconnect, etc.) or a technical defeat (TD) are calculated according to the results. When a technical defeat is appointed, or at any unplanned situations (disconnect, exit (leave) of players from either team, etc.), all further bets are settled according to the results.In case of a default loss, the map (round) won by the DL, is taken into account when maps are being numbered in the line. 
            Totals and handicaps in these disciplines are given for the time (minutes) of the map duration, destroyed fortresses, killed main characters, killed couriers, Roshans, maps, as well as any other, at the discretion of the Betting company.
            The advantage is added to the map before the event starts. According to the decision of the judges/ regulations, in some cases, one of the teams may be awarded a one-map victory "in absentia" (not to be confused with a handicap bet or a default loss). This "absentee" map is not counted in the line for the outcome of total maps (rounds) or for winning a specific map (in sequence). In this case, the first map actually played by the participants is considered the first map in the line.
            The "First Blood" bet is calculated if the game character is killed by the characters of the opposing team. The "First Blood" by neutral units or creeps/minions of the opponent is not taken into account on the map but is saved until the first character is killed by the opposing team. 
            The bet "First 10 Kills on the Map" is given to the team that first commits 10 kills of the opposing team's characters (champions) on the map.
            Re-host is a “rollback” of the game situation to the moment of auto-saving at a certain time (in case of network problems, DDoS attacks, server crashes, and other similar cases) is not a reason for refunding bets.
            Bets on the "Best Net Worth" market are accepted in the intervals from 1 to 18 minutes, from 1 to 12 minutes and from 1 to 5 minutes. The Client needs to guess which team will be ahead of the opponent in terms of game gold in the specified time intervals. The team that has more total game value based on "gold" is determined as a winner in these time periods. 
            In the case of the technical defeat in the round (on the map) or full match, outcomes that are not determined by gaming competition at the moment of the TD announcement are settled at the odds "1".

            </div>
            <div className="sub-txt-heading pt-3">
            Counter-Strike 

            </div>
            <div className="sub-txt-heading pt-3">
            Esport general rules.
            </div>
            <div className="sub-txt pt-3">
                <ul>
                    <li>The below rules are in addition to our general Esports rules. Where there is a clear conflict, the specific Esports rule will succeed the general Esports rule.</li>
                    <li>In the event of a technical defeat, all bets will be made void unless the result is already known. </li>
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Technical defeats can be declared for the following reasons:
            </div>
            <div className="sub-txt-heading pt-3">
            None appearance in the game
            </div>
            <div className="sub-txt">
                <ul>
                    <li>Early withdrawal within the tournament</li>
                    <li>Termination of the game before completion</li>
                    <li>Participation of an invalid player</li>
                    <li>Violation of a tournament rules or sporting conduct</li>
                    <li>Player injury or equipment malfunction preventing a game continuing</li>
                    <li>The game involving the player or streamer crashes </li>
                    <li>Disqualification of a player or team member.</li>
                </ul>
            </div>
            <div className="sub-txt pt-3">
            If a technical defeat is awarded after a game is completed, all bets will be settled upon the result as played.
            </div>
            <div className="sub-txt">
            In the event of evidential Unfair sporting competition, all bets will either be suspended for up to 72 hours or declared void upon our discretion. 

            </div>
            <div className="sub-txt ">
            Unfair sporting competition is defined by the following:

            </div>
            <div className="sub-txt">
                <ul>
                    <li>Gaining an advantage in a game through fraud, deception, collusion, cheats, playing banned player aids such as aimbots and exploitation of bugs in the game.</li>
                    <li>Other actions that call the integrity of the game into question</li>
                </ul>
            </div>
            <div className="sub-txt pt-3">
            Regular formats of esports matches sometimes include Bo1, Bo2, Bo3, etc. (Best of 1, 2, 3, 5, etc.) - the total number of maps in a match where it is necessary to achieve a majority of wins. The winner of the match is determined by the sum of won maps, for example, Bo3 - a minimum of 2 maps, for Bo5, 3 wins are required, and so on.
            In the event a significant advantage is given to one specific team at the start of an event, a decision can be made by the regulations or referee to award a map victory by default. For settlement purposes, bets are considered valid and played.
            For all markets where overtime is considered in the settlement, the market name must contain incl overtime. All other markets will be settled on the result of regulation play only. 
            In the event of a disqualification, all markets (match and period markets) are made void other than those where the result has already been confirmed and settled. 
            If a match/map is interrupted and will be replayed within the next 48 hours, all bets for which the result was known at the time of the interruption will remain valid and will be settled based on the current score. Undetermined bets on map markets will be made void. Bets on the match will remain valid and will be settled based on the final result of the match.
            If a match is interrupted and will be continued within the next 48 hours from the current score/specific moment of the game (resumption), all bets will remain valid and will be settled based on the final result of the match.
            If a change in match format refers to a change in the planned number of maps in the match, then bets placed on map markets will be settled accordingly, while bets on match markets (including match winner, exact match score, handicaps by maps, totals, and odd/even maps) will be made void. 
            A change in match format also includes a decision by the organizers to grant a player (or team) an advantage of one period (e.g., a CS game starting with a 1-0 map score). In this case, bets placed on the markets of all subsequent periods (after the first) will be settled accordingly, while bets on match markets (including match winner, exact match score, handicaps by maps, totals, and odd/even number of maps) will be made void.
            If the team name remains unchanged, but there are roster changes (over 50% of the team members) after the match has been listed, we reserve the right to void all bets

            </div>
            <div className="sub-txt-heading pt-3">
            Counter-Strike
            </div>
            <div className="sub-txt">
                <ul>
                    <li>For this type of sport, markets with and without overtime are possible. By default, all markets are without overtime (unless the market name specifically includes "with overtime").</li>
                    <li>Overtime. Victory on the map is achieved by winning at least 13 rounds. In the event of a tie on the map (when the score is 12-12 by rounds, tournaments usually provide for 6 additional rounds, the so-called "overtime"). Victory in overtime is awarded to the team that first wins 4 out of 6 additional rounds. In the event of a tie in overtime (both teams winning 3 rounds each), the next overtime is scheduled (6 additional rounds).</li>
                </ul>
            </div>
            <div className="sub-txt-heading pt-3">
            Markets
            </div>
            <div className="sub-txt pt-3">
            Winner: A bet on the winner of the match. The match is considered to have started after the first kill in the pistol round.
            </div>
            <div className="sub-txt">
            1x2: Similar to the Winner result, but a draw is considered as a possible outcome. Offered in matches where a draw is possible (for example, in bo2 format matches or in bo1 format matches without overtime).
            </div>
            <div className="sub-txt">
            Total Maps: The total number of maps played within the match.
            </div>
            <div className="sub-txt">
            Map Handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing maps.
            </div>
            <div className="sub-txt ">
            Correct map score: Offered to select the exact final score in the match by maps.
            </div>
            <div className="sub-txt">
            Odd/Even number of maps: A bet on the total even or odd number of maps played in the match.

            </div>
            <div className="sub-txt pt-3">
            Total: A bet on the total number of rounds played by both teams within the match.

            </div>
            <div className="sub-txt">
            Handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds in the match
            </div>
            <div className="sub-txt pt-3">
            Match - Team N total (With overtime) - A bet on whether Team N wins more or fewer than the specified number of rounds on the specified match.
            </div>
            <div className="sub-txt">
            Map X. - Winner: A bet on the winner of the specified map in the match including additional rounds / overtime.
            </div>
            <div className="sub-txt pt-3">
            Map X. - Winner of the first half: A bet on the team that will win in rounds in the first half (for MR15 format - 8 rounds, for MR12 - 7 rounds).
            </div>
            <div className="sub-txt">
            Map X. - Winner of the second half: A bet on the team that will win the second half on the specified map. The second half begins after the sides switch. This bet only considers the result of the second half of the game, regardless of the overall map result.
            </div>
            <div className="sub-txt">
            Map X. - First Half Correct Score: A bet on the first half to end with the indicated score
            </div>
            <div className="sub-txt">
            Map X. - Second Half Correct Score: A bet on the exact score of rounds with which the second half of the specified map will end.

            </div>
            <div className="sub-txt">
            Map X. - First half round handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds in the first half on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Second half round handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds in the second half on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - First half Team N Total: a bet on the total number of rounds won by the specified team (Team N) in the first half on the specified map (Map X). This includes only the rounds of the first half of the match, before the sides are switched.

            </div>
            <div className="sub-txt">
            Map X. - Second half Team N Total: a bet on the total number of rounds won by the specified team (Team N) in the second half on the specified map (Map X). This includes only the rounds of the second half of the match, after the sides have been switched.

            </div>
            <div className="sub-txt">
            Map X. - Second half Total: a bet on the total number of rounds played in the second half on the specified map (Map X). This includes all rounds played after the sides have switched roles, up until the end of the second half of the match, excluding any overtime
            </div>
            <div className="sub-txt">
            Map X. - Winner 1X2: A bet on the winner of the specified map or a draw in regular time.
            </div>
            <div className="sub-txt ">
            Map X. - Odd/Even: A bet on the odd or even number of rounds played on the specified map, without considering overtime.
            </div>
            <div className="sub-txt">
            Map X. Will there be overtime?: A bet on whether there will be overtime on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - Will there be a Team kill?: A bet on whether there will be a Team Kill from the selected team on the specified map. Team Kill refers to a player "killing" their teammate.
            </div>
            <div className="sub-txt">
            Map X. - Will there be a knife kill?: A bet on whether there will be a kill by knife from the selected team on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Round N - Will ACE be in the round?: a bet when a player kills the whole enemy team on his own in round N.
            </div>
            <div className="sub-txt">
            Map X. - Will there be a Zeus X27 kill? (With overtime): A bet on whether there will be a kill by Zeus X27 from the selected team on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Winner of the pistol round: A bet on which team will win the selected pistol round on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - Winner of both pistol rounds: A bet on which team will win both pistol rounds on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Bomb planted in the Nth pistol round: A bet on whether the bomb will be planted in the specified pistol round on the specified map. Pistol rounds refer to the first and thirteenth rounds on the map in MR12 format matches
            </div>
            <div className="sub-txt">
            Team N - Total: A bet on the total number of rounds won by the specified team within the match.
            </div>
            <div className="sub-txt pt-3">
            Example: a player bets on more than 21.5 for Team #2, and in a bo3 match, the mentioned team loses with scores of 13-11; 13-10. The total number of rounds won by Team #2 = 21 (11+10) - the bet loses because the number of rounds won is less than the total value. Conversely, if the bet was placed on less than 21.5 with a total of 21 rounds won in the match, it wins.
            </div>
            <div className="sub-txt">
            Team N - Total pistol round wins: A bet on whether the specified team wins the chosen number of pistol rounds in the match
            </div>
            <div className="sub-txt pt-3">
            Map X. - Correct pistol rounds score: Offered to select the exact final score on the map by pistols rounds (1 and 13).

            </div>
            <div className="sub-txt">
            Map X. - Total: A bet on the total number of rounds within the map, excluding overtime. For example, if a player bets on over 22.5 and there are a total of 20 rounds played on the map, the bet loses because the total number of rounds played is less than the total value. If the bet was placed on under 22.5 with 22 rounds played, it wins. The maximum number of rounds without overtime in MR12 format matches is 24.

            </div>
            <div className="sub-txt">
            Map X. - Total (3 way): A bet on the total number of rounds played in the map, with three possible outcomes: under a specified number of rounds, over a specified number of rounds, or exactly a specified number of rounds
            </div>
            <div className="sub-txt ">
            Map X. - Team N total rounds: A bet on whether Team N wins more or fewer than the specified number of rounds on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Team N total rounds as Terrorists/Counter-Terrorists: A bet on whether Team N wins the specified number of rounds on the specified map while playing on the specified side: attacking (Terrorists) or defending (Counter-Terrorists).

            </div>
            <div className="sub-txt">
            Map X. - Total bomb explosion rounds: A bet on the total number of rounds within the specified map that ended with a bomb explosion.
            </div>
            <div className="sub-txt">
            Map X. - Total kills in pistol rounds: A bet on the total number of kills from both teams in the selected pistol round on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - Round Handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Round Handicap (With overtime): Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds on the specified map. including overtime.
            </div>
            <div className="sub-txt">
            Map X. - Round handicap (3 way): A bet on which team will win a specific round with a handicap, offering three possible outcomes: Team A wins with the handicap, Team B wins with the handicap, or a tie considering the handicap.

            </div>
            <div className="sub-txt">
            Map X. - Asian total rounds: Asian total rounds involve betting on the total number of rounds played in a match using fractional values such as 20.25, 20.5, 20.75, etc. These bets are divided into two parts, allowing for partial returns or partial losses. Examples:.
            </div>
            <div className="sub-txt pt-3">
                <ul>
                    <li>If you bet on over 20.5 total rounds and 21 or more rounds are played, your bet wins</li>
                    <li>If you bet on over 21.75 total rounds and 21 rounds are played, half of your bet (on 20.5) wins, and the other half (on 21) is refunded.</li>
                </ul>
            </div>
            <div className="sub-txt">
            Map X. – Asian round handicap: is a type of bet used to balance the odds between two teams or players by adding or subtracting a certain number of rounds from their final score. When using quarter handicaps (e.g., -0.25 or +0.75), the bet is split into two parts: one with the nearest whole number, the other with the nearest half number, reducing the risk of a full loss.

            </div>
            <div className="sub-txt">
            Map X. - Round X Winner: A bet on the team's victory in the specified round on the specified map. Victory in the round is achieved by team kill, bomb explosion/defusal, or expiration of round time without the bomb being planted.

            </div>
            <div className="sub-txt">
            Map X. - Race to X rounds: A bet on which of the teams will first win the selected number of rounds on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - Correct score: A bet on the specified map to end with the indicated score. If the score on the map reaches 12-12 (15-15 for MR15), all bets on this event will be settled with a coefficient of 1.
            </div>
            <div className="sub-txt">
            Map X. - Round X - Method of victory: Offers to choose the exact method of victory in the selected round on the specified map. Victory in the round is achieved by one of the possible methods: team kill, bomb explosion/defusal, or expiration of round time without the bomb being planted.

            </div>
            <div className="sub-txt">
            Overtime - Victory on the map is achieved by winning at least 13 rounds. In the event of a tie on the map (when the score is 12-12 by rounds, tournaments usually provide for 6 additional rounds, the so-called "overtime"). Victory in overtime is awarded to the team that first wins 4 out of 6 additional rounds. In the event of a tie in overtime (both teams winning 3 rounds each), the next overtime is scheduled (6 additional rounds).

            </div>
            <div className="sub-txt">
            Map X. - 1x2 of Nth overtime: A bet on the winner of the selected overtime of the specified map, a draw is considered as an option.

            </div>
            <div className="sub-txt">
            Map X. - Overtime N - Round handicap: Advantage or disadvantage for one of the teams, expressed in the number of winning or losing rounds on the specified оvertime.
            </div>
            <div className="sub-txt">
            Map X. - Exact score of overtime N: A bet on the specified overtime on the specified map to end with the indicated score
            </div>
            <div className="sub-txt ">
            Map X. - Odd/Even number of rounds in overtime N: A bet on the odd or even number of rounds in overtime.

            </div>
            <div className="sub-txt">
            Map X. - Total rounds of overtime N: A bet on the total number of rounds played within overtime N.
            </div>
            <div className="sub-txt">
                6.18.64. Both Batsmen to Score ‘X’ Runs in Over
            </div>
            <div className="sub-txt">
            Map X. - Winner of the first half of overtime N: A bet on the team that wins the first 3 rounds in overtime N on the specified map.

            </div>
            <div className="sub-txt">
            Map X. - Total rounds in overtime. A bet on the number of rounds in overtime. For example, if a player bets on over 5.5 and there are a total of 6 rounds played in overtime, the bet wins because the number of rounds played is greater than the total value specified in the total. If the bet was placed on under with 4 or 5 rounds played, it wins.

            </div>
            <div className="sub-txt">
            Map X. - Total under + win: A bet on which team will win the map in regular time and the total sum of rounds played will be less than a certain total value. Bets are accepted without considering overtime (if overtime exists, all market results are settled as a loss).

            </div>
            <div className="sub-txt">
            Map X. - Total over + win: A bet on which team will win the map in regular time and the total sum of rounds played will be greater than a certain total value. Bets are accepted without considering overtime (if overtime exists, all market results are settled as a loss).

            </div>
            <div className="sub-txt">
            Map X. - Winning margin: A bet on the team's victory within a certain range of rounds. Team victory on the specified map with a margin of rounds within the selected range after the map ends. Example: Team A wins with a score of 13-10, for this result, the round advantage range of 2-4 rounds is suitable. If Team A wins with a score of 13-4, the round advantage range of 8-10 rounds is suitable
            </div>
            <div className="sub-txt pt-3">
            Map X. - Will there be a Molotov (Incendiary Grenade) kill (With overtime): A bet on whether there will be a kill by Incendiary Grenade by any of teams on the specified map. This outcome remains valid even if kill will be committed by Grenade directly. If someone will kill his enemy by Grenade but not by fire this outcome will be settled as “yes”.
            </div>
            <div className="sub-txt">
            Map X. - Player N - Total Kills (including overtime): a bet on the total number of kills by Player N on the specified map including overtime.

            </div>
            <div className="sub-txt">
            Map X. - Player N - Total Deaths (including overtime): a bet on the total number of kills by Player N on the specified map including overtime.
            </div>
            <div className="sub-txt">
            Map X. - Round N - Total Kills: a bet on the total number of kills by both team in round..
            </div>
            <div className="sub-txt">
            Map X. - Will there be a double kill in the Round N: a bet on whether any player will achieve a double kill (kill 2 enemies) in the specified round of the map.

            </div>
            <div className="sub-txt">
            Map X. - Will there be a triple kill in the Round N: a bet on whether any player will achieve a triple kill (kill 3 enemies) in the specified round of the map.
            </div>
            <div className="sub-txt">
            Map X. - First kill in round: a bet on the team that will make the first kill in the specified round.

            </div>
            <div className="sub-txt">
            Map X. - Team N total kills in the round: A bet on the total number of enemy kills from team N in the selected round on the specified map.
            </div>
            <div className="sub-txt">
            Map X. - Round N - Will there be a double kill in the round: – a bet when a player kills 2 or more enemies in round N.
            </div>
            <div className="sub-txt">
            Map X. - Duel of players - Winner by kills (With overtime): Player which will get more kills on the Map X will win. If the score is equal all bets will be refunded..
            </div>
            <div className="sub-txt">
            Map X. - Duel of players - 1X2 by kills (With overtime): Player which will get more kills on the Map X will win, a draw is considered as an option.

            </div>
            <div className="sub-txt ">
            Map X. - Duel of players - Handicap by kills (With overtime):

            </div>
            <div className="sub-txt">
            Map X. - Win First Half + Win Map: Chosen team should win at least 7 rounds on first half and after win a map.
            </div>
            <div className="sub-txt">
            Map X - Win First pistol + Win map: a bet on a team winning both the first pistol round and the entire map on the specified map (Map X). The bet is successful if the chosen team wins the initial pistol round at the start of the map and then goes on to win the entire map.
            </div>
            <div className="sub-txt">
            Map X - Win First pistol + Win First half: a bet on a team winning both the first pistol round and the first half of the game on the specified map (Map X). The bet is successful if the chosen team wins the initial pistol round at the start of the map and also wins the first half of the map.

            </div>
            <div className="sub-txt-heading py-3">
            Special settlement rules

            </div>
            <div className="sub-txt">
            All bets for Counter-Strike are accepted taking into account overtimes. If a team or one of the players for any reason leaving the match - player’s team is credited with losing all remaining maps/rounds. If the match is stopped by the referee and a replay is ordered after more than 36 hours - the result of the interrupted game is not taken into account. 
            The first kill in the "pistol" round is the actual start of the match. To win a round, the team shall destroy all opponents on the map, as well as either detonate or defuse a bomb. An alternative victory option is also possible by ending the game time on the timer in the round. 
            Winning at least 13 rounds (unless otherwise specified in the rules of the tournament) can ensure victory on one of the maps. If the score for the rounds is 12:12, the organizers can assign overtime of six or ten additional rounds (OT) to determine the winner. To win overtime, a team shall either get a two-round advantage or end the game early if the opponent cannot even the score before the end of overtime (for example, win four starts when assigning an additional six OT rounds). If a draw was recorded after overtime, the organizers can again assign six or ten additional rounds.
            If according to the rules or the decision of the judges, the event starts with the initial advantage in the account of one of the teams, the bets are calculated with odds equal to "1", except in cases when this information was initially specified in the line. Also, bets are calculated with odds equal to "1" in cases of changes in the match format (number of rounds, cards, or other rules), except for bets on already defined outcomes.
            Bets on outcomes that are determined by the moment of default loss or interruption of the game/refusal of teams are calculated based on the results. If the outcome cannot be determined at the moment of stopping the match, the bet is calculated with odds equal to "1".
            Bets are accepted on standard victories or draws, handicaps (rounds, maps, kills), and other specific outcomes, as well as on the winner of the tournament as a whole or the winner of a certain round on a certain map. Bets on handicaps and totals are accepted on maps, rounds, and kills in a given period.
            Handicaps and Totals in matches are calculated by rounds, unless otherwise indicated in the market itself.

            </div>
            <div className="sub-txt">
            In Duel matches, Handicap and Total are calculated based on the results of kills, taking into account OT.
            </div>
            <div className="sub-txt-heading py-3">
            Special settlement rules

            </div>
            <div className="sub-txt">
                6.19.6. If penalty point(s) are awarded by the umpire, all bets on that game will stand.
            </div>
            <div className="sub-txt pt-3">
                6.20. Aussie rules
            </div>
            <div className="sub-txt">
                6.20.1. All markets exclude overtime unless otherwise stated
            </div>
            <div className="sub-txt">
                6.20.2. Regular 80 Minutes: Markets are based on the result at the end of a scheduled 80 minutes play unless otherwise stated. This includes any added injury or stoppage time but does not include extra-time.
            </div>
            <div className="sub-txt">
                6.20.3. If odds were offered with an incorrect match time (more than 2 minutes), we reserve the right to void the betting.
            </div>
            <div className="sub-txt">
                6.20.4. If the team names or category are displayed incorrectly, we reserve the right to void betting
            </div>
            <div className="sub-txt pt-3">
                6.21. Counter-Strike: Global Offensive  
            </div>
            <div className="sub-txt">
                6.21.1. If no bomb is planted, market ([mapNr!] Map [roundNr!] Round - Bomb defused) will be considered void
            </div>
            <div className="sub-txt">
                6.21.2. Markets do not consider overtime unless otherwise stated.
            </div>
            <div className="sub-txt">
                6.21.3. Markets will be settled based on officially published results.
            </div>
            <div className="sub-txt">
                6.21.4. If the fixture is listed incorrectly, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
                6.21.5. In case of a walkover or retirement, all undecided markets are void.
            </div>
            <div className="sub-txt">
                6.21.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately. 
            </div>
            <div className="sub-txt py-3">
                6.21.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
                6.22. Dota 2
            </div>
            <div className="sub-txt">
                6.22.1. Xth map – 1st aegis: Settlement is determined by the team which picks up the Aegis of the Immortal, and not who slays Roshan
            </div>
            <div className="sub-txt">
                6.22.2. Xth map – 1st tower and Xth map – 1st barracks: For settlement purposes every method of tower destruction will be taken into account (Opponent & Creep destroy; destroy by Deny)
            </div>
            <div className="sub-txt">
                6.22.3. Markets will be settled based on officially published results.
            </div>
            <div className="sub-txt">
                6.22.4. If the fixture is listed incorrectly, we reserve the right to void betting.
            </div>
            <div className="sub-txt">
                6.22.5. In case of a walkover or retirement, all undecided markets are void.
            </div>
            <div className="sub-txt">
                6.22.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately.
            </div>
            <div className="sub-txt">
                6.22.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.
            </div>
            <div className="sub-txt pt-3">
                6.23. League of Legends
            </div>
            <div className="sub-txt">
                6.23.1. Xth map – 1st inhibitor and Xth map – 1st tower: For settlement purposes every method of destruction will be taken into account 
            </div>
            <div className="sub-txt">
                6.23.2. Markets do not consider overtime unless otherwise stated. 
            </div>
            <div className="sub-txt">
                6.23.3. Markets will be settled based on officially published results. 
            </div>
            <div className="sub-txt">
                6.23.4. If the fixture is listed incorrectly, we reserve the right to void betting. 
            </div>
            <div className="sub-txt">
                6.23.5. In case of a walkover or retirement, all undecided markets are void. 
            </div>
            <div className="sub-txt">
                6.23.6. If a match or map is replayed due to a disconnection, or technical issues which are not player-related, all undecided markets will be void. The replayed match or map will be handled separately. 
            </div>
            <div className="sub-txt">
                6.23.7. If the standard number of maps is changed or differs from those offered for betting purposes, we reserve the right to void betting.
            </div>
            <div className="sub-txt pt-3">
                6.24. eSports FIFA
            </div>
            <div className="sub-txt">
                6.24.1. eSports Battle match duration - 2x4 minutes.
            </div>
            <div className="sub-txt">
                6.24.2. Liga Pro EFootball match duration - 2x6 minutes.
            </div>
            <div className="sub-txt">
                6.24.3.  All Markets will be settled as set out in the General and Soccer Market Rules.
            </div>
            <div className="sub-txt pt-3">
                6.25. eSports NBA2K
            </div>
            <div className="sub-txt">
                6.25.1. Match duration – 4x5 minutes. This includes overtime.
            </div>
            <div className="sub-txt">
                6.25.2. All Markets will be settled as set out in the General and Basketball Market Rules.
            </div>
            <div className="sub-txt pt-3">
                6.26.1.All race bets are settled on the official FIA classification at the time of the podium presentation, with subsequent disqualifications/penalties disregarded. 
            </div>
            <div className="sub-txt">
                6.26.2. All drivers who complete 90% of the race laps are deemed as classified finishers in line with the official FIA classification. However all drivers are given a ranking, and for the purpose of head to head betting this ranking shall apply.
            </div>
            <div className="sub-txt-heading py-3">
                7. Virtual sports
            </div>
            <div className="sub-txt">
                7.1. Virtual football
            </div>
            <div className="sub-txt">
                7.1.1. The Virtual Football Modes provide 24/7/365 real money betting experience on virtual football. Competitions are generated continuously and bets can be placed at any time, even within a season.
            </div>
            <div className="sub-txt">
                7.1.2. Game structureEach mode has a different tournament structure:
            </div>
            <div className="sub-txt py-3">
                Virtual Football League Mode VFLM:
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt'>
                        16 Teams
                    </li>
                    <li className='sub-txt'>
                        Home & away matches
                    </li>
                    <li className='sub-txt'>
                        30 match days
                    </li>
                    <li className='sub-txt'>
                        8 concurrent matches per match day
                    </li>
                    <li className='sub-txt'>
                        240 matches per season Group Stage 
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-3">
                Virtual Football World Cup VFWC:
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt'>
                        32 Teams (8 groups of 4 teams per group)
                    </li>
                    <li className='sub-txt'>
                        12 match day chunks (3 match days of 4 chunks per match day)
                    </li>
                    <li className='sub-txt'>
                        4 concurrent matches per match day chunk
                    </li>
                    <li className='sub-txt'>
                        48 matches per group stage
                    </li>
                    <li className='sub-txt'>
                        Knock-Out-Stage
                    </li>
                    <li className='sub-txt'>
                        16 Teams
                    </li>
                    <li className='sub-txt'>
                        5 round (R16[1..4]; R16[5...8]; R8; Semi Finals; Final & 3rd Place)
                    </li>
                    <li className='sub-txt'>
                        4 concurrent matches (R16[1..4]; R16[5...8]; R8);
                    </li>
                    <li className='sub-txt'>
                        2 concurrent matches (Semi Finals; Final & 3rd Place)
                    </li>
                    <li className='sub-txt'>
                        16 matches per knock-out-stage.
                    </li>
                </ul>
            </div>
            <div className="sub-txt pt-3">
                7.2. Virtual basketball
            </div>
            <div className="sub-txt">
                7.2.1. The VBL provides 24/7/365 real money betting experience on virtual basketball. The league consists of 16 teams and seasons run continuously. Each season comprises 30 match days (home and away matches). Bets can be placed at any time – even within a season.
            </div>
            <div className="sub-txt">
                7.2.2. Season Details.
            </div>
            <div className="sub-txt">
                For the online version one season lasts 106:30 minutes in total, separated into a ‘Pre-League’ period, a ‘Match day Loop’, and a ‘Post league’ period. The ‘Pre-League’ period runs prior to the start of a season and lasts 60 seconds. All match days are summarised as the ‘Match day Loop’ period with a total duration of 105:00 minutes. At the end of every season there is a 30 second ‘Post Season’ period.
            </div>
            <div className="sub-txt">
                7.2.3. Betting on a VBL match is allowed up to 10 seconds before tip-off. Betting markets for future match days of the current season remain open. When a future match day from the ‘Match Day’ bar at the bottom is selected, the matches related to that day along with the odds will be displayed in the lower odds section.
            </div>
            <div className="sub-txt">
                7.3. Virtual horses
            </div>
            <div className="sub-txt">
                7.3.1. The VHK provides 24/7/365 real money betting experience on virtual horse races. Bets can be placed up to 10 seconds prior to the start of the next upcoming race as well as on all future races of the current race days at any time.
            </div>
            <div className="sub-txt">
                7.3.2. Races are generated continuously - a new one will be started as soon as the current one has finished. Betting is possible in the next 10
            </div>
            <div className="sub-txt">
                upcoming races.:
            </div>
            <div className="list py-3">
                <ul>
                    <li className='sub-txt'>
                        2 minute total event cycle
                    </li>
                    <li className='sub-txt'>
                        40 seconds betting phase,
                    </li>
                    <li className='sub-txt'>
                        65 seconds event phase,
                    </li>
                    <li className='sub-txt'>
                        15 seconds results phase
                    </li>
                    <li className='sub-txt'>
                        2 grass and 1 dirt track with a 1000m race randomly scheduled
                    </li>
                    <li className='sub-txt'>
                        8, 10, 12, 14 runners randomly assigned
                    </li>
                </ul>
            </div>
            <div className="sub-txt">
                7.3.3. Markets
            </div>
            <div className="list py-3">
                <ul>
                    <li className='sub-txt'>
                        Win - select the runner which will finish first
                    </li>
                    <li className='sub-txt'>
                        Place - select the runner which will finish either 1st, and 2nd (6-7 Runners), select the runner which will finish either 1st, 2nd and 3rd (7+ runners)
                    </li>
                    <li className='sub-txt'>
                        Forecast (Correct Order) - select the runners that will finish 1st and 2nd in the correct order (exacta)
                    </li>
                    <li className='sub-txt'>
                        Forecast (Any Order) - select the runners that will finish 1st and 2nd in any order (quinella)
                    </li>
                    <li className='sub-txt'>
                        Tricast (Correct Order) - select the runners that will finish 1st, 2nd and 3rd in the correct order (trifecta)
                    </li>
                    <li className='sub-txt'>
                        Tricast (Any Order) - select the runners that will finish 1st, 2nd and 3rd in any order (trio)
                    </li>
                </ul>
            </div>
            <div className="sub-txt">
                7.4. Virtual dogs
            </div>
            <div className="sub-txt">
                7.4.1. The VDK provides 24/7/365 real money betting experience on virtual dog races.  Bets can be placed up to 10 seconds prior to the start of the next upcoming race as well as on the ten future races at any time.
            </div>
            <div className="sub-txt">
                7.4.2. Game information. Races are generated continuously - a new one will be started as soon as the current one has finished. 
            </div>
            <div className="list py-3">
                <ul>
                    <li className='sub-txt'>
                        2 minute total event cycle
                    </li>
                    <li className='sub-txt'>
                        37 seconds or 67 seconds betting phase,
                    </li>
                    <li className='sub-txt'>
                        38 seconds or 68 seconds event phase,
                    </li>
                    <li className='sub-txt'>
                        15 seconds results phase
                    </li>
                    <li className='sub-txt'>
                        night and day track with distance 360m and 720m randomly scheduled
                    </li>
                    <li className='sub-txt'>
                        6 or 8 runners randomly assigned
                    </li>
                </ul>
            </div>
            <div className="sub-txt pt-3">
                7.4.3. Markets
            </div>
            <div className="list py-3">
                <ul>
                    <li className='sub-txt'>
                        Win - select the runner which will finish first
                    </li>
                    <li className='sub-txt'>
                        Place - select the runner which will finish either 1st, and 2nd (6-7 Runners), select the runner which will finish either 1st, 2nd and 3rd (7+ runners)
                    </li>
                    <li className='sub-txt'>
                        Forecast (Correct Order) - select the runners that will finish 1st and 2nd in the correct order (exacta)
                    </li>
                    <li className='sub-txt'>
                        Forecast (Any Order) - select the runners that will finish 1st and 2nd in any order (quinella)
                    </li>
                    <li className='sub-txt'>
                        Tricast (Correct Order) - select the runners that will finish 1st, 2nd and 3rd in the correct order (trifecta)
                    </li>
                    <li className='sub-txt'>
                        Tricast (Any Order) - select the runners that will finish 1st, 2nd and 3rd in any order (trio)
                    </li>
                </ul>
            </div>
            <div className="sub-txt-heading py-3">
                8. Bonuses
            </div>
            <div className="sub-txt">
                8.1. Comboboost.
            </div>
            <div className="sub-txt">
                8.1.1.
            </div>
            <div className="row pt-4 pb-5 justify-content-center">
                <div className="col-5">
                    <table class="sports-ponit-table table  table-bordered">
                        <thead>
                            <tr>
                            <th scope="col">Selections</th>
                            <th scope="col">Multiplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2</td>
                                <td>1.01</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1.02</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>1.04</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>1.05</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>1.07</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>1.1</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>1.15</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>1.17</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>1.2</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>1.25</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>1.3</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>1.35</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>1.4</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>1.45</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>1.5</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>1.65</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>1.75</td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>1.85</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>2</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="sub-txt">
                8.1.2. The final bonus amount calculation is based on the final odds of the Combo once all outcomes are settled.
            </div>
            <div className="sub-txt">
                8.1.3. Cashed Out bets are not eligible to have a Combo boost.
            </div>
            <div className="sub-txt">
                8.1.4. Operator reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.
            </div>
            <div className="sub-txt">
                8.1.5. Combo boost is only available on selections with odds of 1.50 or above.
            </div>
            <div className="sub-txt">
                8.2. Freebets, freemoney, bet no risk.
            </div>
            <div className="sub-txt">
                8.2.1. Freebet - player get just a winning part of the bet. For example, freebet 5 on odd 3.5 player get on account 5*3.5 - 5 = 12.5
            </div>
            <div className="sub-txt">
                8.2.2. Freemoney - player gets a stake and wins a part on the account. For example, freemoney 5 on the odd 3.5 the player get on account 5*3.5 = 17.50
            </div>
            <div className="sub-txt">
                8.2.3. Bet no risk - usual bet, but if player loses he get back his money on account
            </div>
            <div className="row py-5 justify-content-center">
                <div className="col-6">
                    <table class="table sports-ponit-table   table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row">type of freebet</th>
                                <td>Refunded or voided</td>
                                <td>Half lost</td>
                                <td>Half win</td>
                            </tr>
                            <tr>
                                <th scope="row">freebet</th>
                                <td>count as a lost bet</td>
                                <td>count as a lost bet</td>
                                <td>As a usual bet, but without the stake (2.5*3.5+2.5*1)-5=6.25</td>
                            </tr>
                            <tr>
                                <th scope="row">free money</th>
                                <td>The player gets stake amount on his account</td>
                                <td>As an usual bet</td>
                                <td>As an usual bet</td>
                            </tr>
                            <tr>
                                <th scope="row">Bet no risk</th>
                                <td>As an usual bet</td>
                                <td>The player gets stake amount on his account</td>
                                <td>As an usual bet</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="sub-txt">
                9. Betby Games Rules
            </div>
            <div className="sub-txt">
                9.1. General Rules.
            </div>
            <div className="sub-txt">
                9.1.1. Sportsbook platform reserves the right to cancel any bet made on obviously “bad” odds, switched odds or a bet made after an event has started or match was affected by obvious technical problems.
            </div>
            <div className="sub-txt">
                9.1.2. All bets will be settled, when the outcome of the market is decided.
            </div>
            <div className="sub-txt">
                9.2. FIFA
            </div>
            <div className="sub-txt">
                9.2.1. Match duration – 2x6 minutes. This includes injury time but does not include extra time or penalty shootouts.
            </div>
            <div className="sub-txt">
                9.2.2. All Markets will be settled as set out in the General Market Rules.
            </div>
            <div className="sub-txt">
                9.3. NBA 2K
            </div>
            <div className="sub-txt">
                9.3.1. Match duration – 4x6 minutes. This includes overtime.
            </div>
            <div className="sub-txt">
                9.3.2. All Markets will be settled as set out in the General and Basketball Market Rules.
            </div>
            <div className="sub-txt">
                9.4. eTennis
            </div>
            <div className="sub-txt">
                9.4.1. The winner of the match is the first player to win 2 sets.
            </div>
            <div className="sub-txt">
                9.4.2. Player must win 3 games to win a set. If the score is tied at 2-2, then a player can win 4-2, or if players are still tied at 3-3 then the set is decided by a tie-break.
            </div>
            <div className="sub-txt">
                9.4.3. The winner of the tie-break is the first player to win 5 points with a minimum 2 points difference. If the score is tied at 5-5, the player can win 7-5, 8-6, 9-7, etc.
            </div>
            <div className="sub-txt">
                9.5. Rocket League
            </div>
            <div className="sub-txt">
                9.5.1.Match duration – 5 minutes. This doesn’t include overtime.
            </div>
            <div className="sub-txt">
                9.5.2.All Markets will be settled as set out in the General Market Rules.
            </div>
            <div className="sub-txt">
                9.6. eFighting
            </div>
            <div className="sub-txt">
                9.6.1.The winner of the match is the character who wins the fight.
            </div>
            <div className="sub-txt">
                9.6.2. Explanation of eFighting market terms.
            </div>
            <div className="sub-txt">
                Health Bar - Each character has 2 Health Bars. The second bar is active only after the first is completely spent.
            </div>
            <div className="sub-txt">
                First damage – first successful attack.
            </div>
            <div className="sub-txt">
                Clash – Situation in the fight, when both characters challenge each other on special occasions. to increase hit points. Both fighters can win the clash, but there can also be a draw.
            </div>
            <div className="sub-txt">
                Supermove – Special move for each character, that occurs very rarely.
            </div>
            <div className="sub-txt">
                9.6.3. All markets will be settled according to the definitions above.
            </div>
            <div className="sub-txt">
                9.7. eCricket
            </div>
            <div className="sub-txt">
                9.7.1. Match consists of two innings - one for each team.
            </div>
            <div className="sub-txt">
                9.7.2. Each inning consists of five overs with 6 deliveries of each. 
            </div>
            <div className="sub-txt">
                9.7.3. All Markets will be settled as set out in the Cricket Market Rules.
            </div>
        </div>
    )
}

export default SportsPolicy
