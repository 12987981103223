import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SignWhite from "./../../../assets/images/signWhite.png";
import { useLogoutModal } from "../../../hooks/useLogout";
import {
  CLICK_ID,
  NEW_CLICK_ID,
  SPIN_STRING,
  SPIN_TOKEN,
  TOKEN_NAME,
} from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";
import { useBtAuth } from "../../../hooks/useBtAuth";
import { useDispatch } from "react-redux";
import { getAuth, signOut } from "firebase/auth";
import { getUserDetails } from "../../../store/action";
import { useTranslation } from "react-i18next";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { succesToaster } from "../../../utils/toaster";
import { useAccount, useDisconnect } from "wagmi";

const LogoutModal = () => {
  const { isLogoutOpen, setIsLogoutOpen } = useLogoutModal();
  const handleClose = () => setIsLogoutOpen(false);
  const { setToken, token } = useAuth();
  const { setBtToken } = useBtAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const headerLabels = t("header", { returnObjects: true });
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();


  const logoutFunction = async () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem("middlePart");
    // localStorage.removeItem(BT_TOKEN_NAME);
    localStorage.removeItem(NEW_CLICK_ID);
    sessionStorage.removeItem(CLICK_ID); // setUp in appHeader
    sessionStorage.removeItem(SPIN_STRING); // setUp in appHeader
    sessionStorage.removeItem(SPIN_TOKEN); // setUp in appHeader
    localStorage.removeItem("intercom.intercom-state"); //remove intercom
    setToken("");
    setBtToken("");
    if (isConnected) {
      await disconnect()
      // disconnectAsync()
      localStorage.removeItem('wagmi.connected');
      localStorage.removeItem('wagmi.store');
      localStorage.removeItem('rk.connectors');
      window.location.reload() //remove if rainbow kit auto login issue is fixed
    }
    const auth = getAuth();
    dispatch(getUserDetails({}));
    signOut(auth)
      .then(() => {
        succesToaster("Logged Out");
        handleClose();
      })
      .catch((error) => {
        // An error happened.
      });
    // const provider = await EthereumProvider.init({
    //   projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID, // Get this from WalletConnect Cloud
    //   chains: [1], // Ethereum mainnet
    //   showQrModal: true, // Enable QR code modal
    // });
    // await provider.disconnect();
    setIsLogoutOpen(false);
  };

  return (
    <>
      <Modal
        show={isLogoutOpen}
        onHide={handleClose}
        centered
        className="signout-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="signModalTitle">
            <div className="d-flex align-items-center gap-3">
              {/* <div className="SignIcon">
                  <img src={SignWhite} alt="" />
                </div> */}
              <div className="heading">
                {headerLabels.ACCOUNT_OPTIONS.Log_Out}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody text-center">
          {headerLabels.Dont_forget}
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            variant="secondary"
            onClick={logoutFunction}
            className="signoutBtn"
          >
            {headerLabels.ACCOUNT_OPTIONS.Log_Out}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutModal;
