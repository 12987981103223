import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import allGame from "../../assets/images/allgame.png";
import dambleFavert from "../../assets/images/dambleFavert.png";
import sloat from "../../assets/images/sloat.png";
import SearchIocn from "../../assets/images/searchIcon.png";
import closeModal from "../../assets/images/closeModal.png";
import GameCard from "../GameCard/GameCard";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getAPI, getAPIAuth } from "../../services/apiInstance";
import { Spinner } from "react-bootstrap";
import { useGames } from "./../../hooks/useGame";
import { GAME_OPTION_TABS } from "../../constants";

const SearchPopNew = ({
  close,
  resultObject,
  setResultObject,
  loader,
  gameList,
  searchGame,
  setSearchGame,
  handleButtonClick1,
  setSortBy,
  sortBy,
  selectedProvider,
  setSelectedProvider,
  noData,
  loadMore,
  paging,
  isLoadMore,
}) => {
  const { t } = useTranslation();
  const resultModal = t("resultModal", { returnObjects: true });
  const allGamesLabels = t("allGamesLabels", { returnObjects: true });
  const commonText = t("commonText", { returnObjects: true });
  const { gameObject, setGameObject } = useGames();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [providerList, setProviderList] = useState([]);
  const location = useLocation();
  const providerid = location ? location.state?.provider_id : "";
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);

  // Provider code start from here...
  // Function for getting provider data
  const getProvider = async () => {
    try {
      const result = await getAPI(
        `crypto/get-provider?casinoCatId=${gameObject?.selectedId}`
      );
      if (result?.data?.success) {
        setProviderList(result.data.data);
        setFilterData(result.data.data);
      } else {
        //
      }
    } catch (error) {}
  };

  // Function for adding Providers
  const changeHandler = (e, data) => {
    if (e.target.checked) {
      setSelectedProvider((pre) => [...pre, e.target.id]);
    } else {
      const filterData = selectedProvider.filter(
        (item) => item !== e.target.id
      );
      setSelectedProvider(filterData);
    }
  };
  useEffect(() => {
    if (gameObject?.selectedId) {
      getProvider();
    }
  }, [providerid, gameObject]);

  useEffect(() => {
    const providerfilterdata = providerList.filter((item) =>
      item._id?.toLowerCase().includes(providerid?.toLowerCase())
    );
    if (providerfilterdata) {
      setSelectedProvider(providerid ? [providerfilterdata[0]?._id] : []);
    }
  }, [providerid, providerList]);

  useEffect(() => {
    // search
    if (search) {
      const filter_data = providerList.filter((item) =>
        item._id?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
      );
      setFilterData(filter_data);
    } else {
      setFilterData(providerList);
    }
  }, [search]);

  // sidenav code start from here...........

  const getCategory = async () => {
    setLoading(true);
    try {
      const res = await getAPI(`get-all-category`);
      if (res?.data?.success) {
        setCategories(res?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div
        className={`${resultObject.isResultOpen ? "seachNewPop" : "d-none"}`}
      >
        <div className="innerContainer">
          <div className="row h-100 gap-lg-0 gap-3 flex-nowrap flex-lg-row flex-column overflow-hidden">
            <div className="col-lg-3 col-sm-12 col-12 ">
              <div className="sideBar">
                <div className=" h-100 sideMenuBar">
                  <div className="row">
                    <div className="col-12">
                      <ul className="sideMenu list-unstyled mb-0  gap-2 ">
                        <li
                          className="w-lg-100"
                          onClick={() =>
                            setGameObject((prev) => ({
                              selectedTab: GAME_OPTION_TABS?.LOBBY,
                              selectedId: "",
                            }))
                          }
                        >
                          <div
                            className={`sideMenuLink rounded-pill gap-2  ${
                              gameObject?.selectedTab ===
                              GAME_OPTION_TABS?.LOBBY
                                ? "active"
                                : ""
                            }`}
                          >
                            <span className="linkIcon">
                              <img
                                src={allGame}
                                alt="allGame"
                                className="img-fluid"
                              />
                            </span>{" "}
                            All Games
                          </div>
                        </li>
                        {!loading
                          ? categories?.length > 0
                            ? categories?.map((item) => (
                                <li
                                  className={`w-lg-100`}
                                  onClick={() => {
                                    setGameObject((prev) => ({
                                      selectedTab: item?.category_name,
                                      selectedId: item?._id,
                                    }));
                                  }}
                                >
                                  <div
                                    className={`sideMenuLink rounded-pill gap-2  ${
                                      gameObject?.selectedTab ===
                                      item?.category_name
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <span className="linkIcon">
                                      <img
                                        src={item?.image}
                                        alt="allGame"
                                        className="img-fluid"
                                      />
                                    </span>{" "}
                                    {item?.category_name?.toLowerCase()}
                                  </div>
                                </li>
                              ))
                            : ""
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg h-100 overflow-hidden flex11auto">
              <div className="mainContent h-100">
                <div className="text-end">
                  <button className="closeBtn cursor-pointer" onClick={close}>
                    <img src={closeModal} alt="" />
                  </button>
                </div>
                <div className="innerSection">
                  <div className="innerSection2">
                    <div className="innerSection3">
                      <div className="row h-100 overflow-hidden d-flex flex-column flex-nowrap mx-0 gx-0">
                        <div className="col-12 mb-3">
                          <div className="row g-2">
                            <div className="col-md">
                              <div class="input-group SerachGroup mb-0">
                                <span class="input-group-text iconSearch">
                                  <img src={SearchIocn} alt="" className="" />
                                </span>
                                <input
                                  type="search"
                                  value={searchGame}
                                  onChange={(e) =>
                                    setSearchGame(e.target.value)
                                  }
                                  class="form-control inputSearch"
                                  placeholder={resultModal.Search_for_games}
                                />
                              </div>
                            </div>
                            <div className="col-md-auto col-sm-6 col-6">
                              <Dropdown className="dropDown">
                                <Dropdown.Toggle
                                  className="dropBtn"
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {/* Provider : <span> 0</span> */}
                                  {resultModal.Provider} :{" "}
                                  <span>{selectedProvider?.length}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropMenu ">
                                  <div className="col-12">
                                    <div className="dropHeader position-relative">
                                      <input
                                        class="dropSearch"
                                        type="search"
                                        onChange={(e) =>
                                          setSearch(e.target.value)
                                        }
                                        placeholder={resultModal?.Search_here}
                                        name=""
                                        id=""
                                        value={search}
                                      />
                                      <div class="serachImg">
                                        <img src={SearchIocn} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                  {resultObject?.isResultOpen ? (
                                    <div className="col-12 mt-2 bodyWrapper">
                                      <div className="dropBody d-flex align-items-center">
                                        <div className="form-check formCheck">
                                          <div
                                            className="dropdownLabel d-flex align-items-center"
                                            onClick={() =>
                                              setSelectedProvider([])
                                            }
                                          >
                                            <div className="dropdownTxt">
                                              {resultModal.CLEAR_ALL}
                                            </div>
                                            <hr className="divider bg-light" />
                                          </div>
                                        </div>
                                      </div>
                                      {filterData.map((item) => (
                                        <div
                                          key={item._id}
                                          className="dropBody mt-2 d-flex align-items-center"
                                        >
                                          <div class="form-check formCheck">
                                            <input
                                              class="dropdownInp  d-none"
                                              type="checkbox"
                                              name={`${item._id}`}
                                              id={`${item._id}`}
                                              onChange={(e) => {
                                                changeHandler(e, item);
                                              }}
                                              checked={selectedProvider.includes(
                                                item._id
                                              )}
                                            />
                                            <label
                                              class="dropdownLabel d-flex align-items-center"
                                              htmlFor={`${item._id}`}
                                            >
                                              <div class="dropdownCheckbox"></div>
                                              <div class="dropdownTxt">
                                                {item._id}
                                              </div>
                                              <div class="dropdownValue ms-auto">
                                                {item.count}
                                              </div>
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="col-md-auto col-sm-6 col-6">
                              <Dropdown className="dropdownsimple">
                                <Dropdown.Toggle
                                  className="dropBtn"
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {/* SORT BY : <span>A-Z</span> */}
                                  {resultModal.SORT_BY} :{" "}
                                  <span>
                                    {sortBy ? sortBy : allGamesLabels.Select}
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="DropMenuSimple">
                                  {["Popular", "A-Z", "Z-A", "New"].map(
                                    (item) => (
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdownTxt w-100"
                                        onClick={() => {
                                          setSortBy(item);
                                        }}
                                        key={`${Math.random()}--${Math.random()}`}
                                      >
                                        {item}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 h-100 overflow-hidden bottomCont">
                          {loader && gameList.length === 0 ? (
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                              <Spinner
                                animation="border"
                                variant="light"
                                size="lg"
                              />
                            </div>
                          ) : gameList.length === 0 ? (
                            <div className="text-light">
                              {commonText.NoDataAvailable}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="bottomSectionCard h-100 ">
                            <div className="h-100 overflow-y-auto scrollPart w-100 ">
                              <div className="row row-cols-xl-6 row-cols-lg-5 row-cols-md-6 row-cols-sm-5 row-cols-3 g-2 searchColsWrapper">
                                {
                                  //     [1,1,1,1,1,1,1,1,1,1,1,1]?.map((item)=> (
                                  //         <div className='col'>
                                  //             {/* remove the card div and call gameCard comp here */}
                                  //             <div className='card'>
                                  //                 <img src="https://gis-static.com/games/Mascot/2e76b7ea49e300617b9edbc94db5b57c78ff0821.png" alt="" />
                                  //             </div>
                                  //         </div>
                                  //     ))
                                  // }
                                  gameList.length === 0 ? (
                                    <>{/* // <div>No data available</div> */}</>
                                  ) : (
                                    gameList?.map((item, index) => (
                                      <div
                                        className="a"
                                        onClick={() =>
                                          setResultObject((prev) => ({
                                            isResultOpen: false,
                                          }))
                                        }
                                      >
                                        <GameCard data={item} />
                                      </div>
                                    ))
                                  )
                                }
                              </div>
                              <div className="col-12 mt-4 d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-center loadmoremain">
                                  {!loader ? (
                                    <span
                                      onClick={() => {
                                        if (noData) return;
                                        if (gameList.length === 0) {
                                          setSearch("");
                                          setSortBy("");
                                          setSelectedProvider([]);
                                        } else {
                                          loadMore(paging + 1);
                                        }
                                      }}
                                      className="text-decoration-none loadBtn text-light rounded-2 cursor-pointer"
                                    >
                                      {isLoadMore ? (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                          <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                          />
                                        </div>
                                      ) : noData ? (
                                        commonText.NoDataAvailable
                                      ) : gameList.length === 0 ? (
                                        allGamesLabels.REMOVE_FILTERS
                                      ) : (
                                        // 'REMOVE FILTERS'
                                        allGamesLabels.LOAD_MORE
                                        // 'LOAD MORE'
                                      )}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPopNew;
