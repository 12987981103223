import React from "react";
import ReactDOM from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Buffer } from "buffer";
// import 'bootstrap/dist/css/bootstrap.min.css';

import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  getWalletConnectConnector,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import Providers from "./providers/Providers";
import "./i18n";
import "swiper/css";
import "swiper/css/navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import "@rainbow-me/rainbowkit/styles.css";
import "./assets/styles/index.scss";
import { rainBowConfig } from "./rainbowConfig";
import { mainnet, sepolia } from "wagmi/chains";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Polyfill Buffer
if (typeof window !== "undefined") {
  window.Buffer = Buffer;
}

// Clear Existing Sessions for rainbow kit



const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <WagmiProvider config={rainBowConfig} reconnectOnMount={false}>
      <QueryClientProvider client={queryClient}>
        {/* <RainbowKitProvider> */}
          <Providers>
            <App />
          </Providers>
        {/* </RainbowKitProvider> */}
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
