import React, { useEffect, useState } from 'react'
import Banner from './../../../assets/images/modalBanner.png'
import modalBannerMobile from './../../../assets/images/signinmobile.png'
import close from './../../../assets/images/closeModal.png'
import { getAPI } from '../../../services/apiInstance'
import SignupModal from './../../../assets/images/ship.svg'


const AuthBanner = ({ handleClose }) => {
  const [bannerData, setBannerData] = useState([])
  const [mobileBanner, setMobileBanner] = useState('')
  const [desktopBanner, setDesktopBanner] = useState('')

  const getBannerImage = async () => {
    try {
      const res = await getAPI('banner/getBannerByFilter?type=signup')
      if (res?.data?.success) {
        setBannerData(res?.data?.data)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getBannerImage()
  }, [])

  useEffect(() => {
    if (bannerData?.length) {
      const desktopData = bannerData?.filter((item) => item?.viewType === 'desktop')
      setDesktopBanner(desktopData?.[0]?.image)
      const mobileData = bannerData?.filter((item) => item?.viewType === 'mobile')
      setMobileBanner(mobileData?.[0]?.image)

    }
  }, [bannerData])


  return (
    <>
      <div className="modalBanner d-md-block d-none">
        <img src={desktopBanner} alt="" />
      </div>
      <div className="modalBanner d-md-none d-block position-relative">
        <img src={mobileBanner} alt="" />
        <div className="closeBtnmobile"><img src={close} alt="" /></div>
      </div>
      {/* <div className="damble d-md-block d-none mb-5">
          <img src={dambletext} alt="" />
      </div> */}
    </>
  )
}

export default AuthBanner