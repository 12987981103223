import React, { useEffect, useState } from 'react'
import logo from './../../assets/images/logo.jpeg'
import ThunderIcon from './../../assets/images/profile/thunder-icon.svg'
import memeCoinLogo from './../../assets/images/profile/memeCoinLogo.svg'

const LoadingScreen = ({ timer }) => {
    // const [percentage, setPercentage] = useState(timer)

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       setPercentage(prevPercentage => {
    //         if (prevPercentage < 100) {
    //           return prevPercentage + 1;
    //         } else {
    //           clearInterval(interval);
    //           return 100;
    //         }
    //       });
    //     }, 50);

    //     return () => clearInterval(interval);
    //   }, []);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 10 : 100));
        }, 500);

        return () => clearInterval(interval);
    }, []);

     const [dots, setDots] = useState("...");

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => (prev === "..." ? "...." : prev === "...." ? "....." :prev === "....." ? "......" : "..."));
        }, 300);

        return () => clearInterval(interval);
    }, []);



    return (
        <>
            {/* <div className="container-fluid loadingScreen">
            <div className="container h-100">
                <div className="row align-items-center justify-content-center h-100">
                    <div className="col-12">
                        <div>
                            <div className='logoLoading mx-auto'>
                                <img src={logo} alt="loaderlogo" className='h-100 w-100' />
                            </div>
                            <div className='loadingheading'>Loading {percentage}%</div>
                            <div className='progressSection position-relative mx-auto'>
                                    <span className='corner1'></span>
                                    <span className='corner2'></span>
                                    <span className='corner3'></span>
                                    <span className='corner4'></span>

                                <div class="progress mx-auto position-relative" role="progressbar" aria-label="Basic example" aria-valuenow="26.74" aria-valuemin="0" aria-valuemax="100">
                                    <div style={{'--percentage': `${percentage}%`}} class="progress-bar "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}



            <div className="loadingScreen d-flex flex-column align-items-center justify-content-center w-100">
               
                    <div className="col-12  d-flex flex-column align-items-center justify-content-center w-100">
                        {/* <div className='lodingDiv '> */}
                            {/* <span className='z-3'>
                                <svg width="54" height="76" viewBox="0 0 54 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.3059 23.3887H53.138L19.2461 74.9486L26.3941 34.9877L26.4167 34.8613H26.2883H0.560973L14.9796 0.357422H38.7599L30.2053 23.2436L30.1511 23.3887H30.3059Z" fill="#CFEFFF" stroke="#C2FB01" stroke-width="0.214844" />
                                </svg>
                            </span>
                            <span className='lodingscreen_bg absolute -right-1 top-[11px]'>
                                <svg width="82" height="93" viewBox="0 0 82 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.6938 60.2174L25.7214 60.1511L25.6706 60.1003L0.695507 35.1252L39.0508 0.550487L81.6582 43.1579C79.3319 68.0943 60.3308 88.1868 35.9119 92.1698L19.3148 75.5726L25.6938 60.2174Z" fill="url(#paint0_linear_561_13954)" stroke="#C2FB01" stroke-width="0.214844" />
                                    <defs>
                                        <linearGradient id="paint0_linear_561_13954" x1="13.3915" y1="22.2692" x2="66.9521" y2="75.8319" gradientUnits="userSpaceOnUse">
                                            <stop stop-opacity="0.5" />
                                            <stop offset="1" stop-opacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span> */}
                            <img src={memeCoinLogo} alt="" />
                        {/* </div> */}
                        <div className='progress_div  mt-4'>
                            <div
                                className="progress_bar"
                                style={{ width: `${progress}%` }}
                                 >
                            </div>
                        </div>
                        <div className='loadingheading position-relative mt-3 text-white'>Loading <span className='position-absolute load_dots ms-1'>{dots}</span></div>

                    </div>
              
            </div>






  
        </>
    )
}

export default LoadingScreen