import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import banner from "../../../assets/images/weeklyBouns.png";
import moment from "moment/moment";
import { useNotification } from "../../../hooks/useNotification";
import { useAuth } from "../../../hooks/useAuth";
import { getAPIAuth } from "../../../services/apiInstance";
import { useTranslation } from "react-i18next";
import NoProductFound from "../../../assets/images/no-data-found.svg"

const MessageComp = ({ message, id }) => {
  useEffect(() => {
    const element = document.getElementById(id);
    element.innerHTML = message;
  }, [message]); //react-hooks/exhaustive-deps

  return (
    <div className="text" id={id}>
      {message}
    </div>
  );
};

const returnShortMsg = (msg) => {
  const data = msg.substring(0, 70);
  if (msg?.length > 70) {
    return data + "...";
  } else {
    return data;
  }
};

const System = () => {
  const [notification, setNotification] = useState([]);
  const [perPage, setPerPage] = useState(2);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const { isNotificationOpen } = useNotification();
  const { token } = useAuth();
  const { t } = useTranslation();
  const notificationModal = t("notificationModal", { returnObjects: true });
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const getNotification = async () => {
    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
      const res = await getAPIAuth(
        `user/get-global-notification?page=${pageNo}&perPage=${perPage}&notificationType=system`,
        token
      );
      if (res?.data?.success) {
        setNotification(res.data?.data);
        setTotalPage(res?.data?.pagination?.[0]?.totalPages);
      }
    } catch (error) {}
  };

  const loadMore = async () => {
    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
      const res = await getAPIAuth(
        `user/get-global-notification?page=${
          pageNo + 1
        }&perPage=${perPage}&notificationType=system`,
        token
      );
      if (res?.data?.success) {
        setNotification([...notification, ...res.data?.data]);
        setPageNo(pageNo + 1);
        setTotalPage(res?.data?.pagination?.[0]?.totalPages);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (token && isNotificationOpen) {
      getNotification();
    }
  }, [token, isNotificationOpen]);

  

  return (
    <>
      <div className="col-12 flex-fill">
      <div className={`row ${notification?.length ? '' : 'h-100'}`}>
                {
                    notification && notification?.length ? (
                        <>
                        {
                        notification?.map((item,  index) => (
                            <div className="col-12 mb-3" key={item?._id + index + item?.seen}>
                                <div className="offcanvasTextWrapper">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="date">
                                                {/* 23/12/2024, 10:56:19 */}
                                                {item?.createdAt ? moment(item?.createdAt).format('DD/MM/YYYY , HH:mm:ss') : '----:---'}
                                            </div>
                                        </div>
                                        <div className="col-12"><div className="time text-capitalize">
                                            {/* 🏀 Its Weekly Sports Bonus Time! ⚽️ */}
                                            {item?.title}
                                        </div></div>
                                        {
                                            item?.image ? 
                                            <div className="col-12">
                                                <div className="banner">
                                                    <img src={item?.image} alt="" />
                                                    {/* <img src={banner} alt="" /> */}
                                                </div>
                                            </div> : ''
                                        }
                                        {
                                            item?.message ? 
                                                <div className="col-12 mt-1 collapse show" id={`collapseExample${index}`}>
                                                    <div className="text text-capitalize overflow-hidden"  style={{height: '36px'}}>
                                                        <MessageComp
                                                            message={returnShortMsg(item?.message)}
                                                            id={item?._id}
                                                        />
                                                    </div>
                                                </div>
                                            : ''
                                        }
                                        {
                                            item?.message?.length > 70 ?
                                                <div className="col-12">
                                                    <div class="text-end w-100 collapseBtn upperCollapsedBtn p-0 mb-2 collapsed" type='button'
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapseExample${index}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapseExample${index}`}>
                                                        {/* Show all */}
                                                        {notificationModal.Show_all}

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                                            <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                    <div class="collapse" id={`collapseExample${index}`}>
                                                        <div class="card card-body collapseBody p-0">
                                                            <div className="row mx-0">
                                                                <div className="col-12 px-0">
                                                                    <MessageComp
                                                                        message={item?.message}
                                                                        id={item?._id + 1}
                                                                    />
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="text">Damble</div>
                                                                        <div>
                                                                            <div class="text-end w-100 collapseBtn p-0 collapsed" data-bs-toggle="collapse" data-bs-target={`#collapseExample${index}`} aria-expanded="false" aria-controls={`collapseExample${index}`}>
                                                                                {/* Hide all */}
                                                                                {notificationModal.Hide_all}
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                                                                    <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="white" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            : ''
                                        }
                                        {
                                            item?.linkName && item?.link ? <>
                                                <div className="col-12 collapseBody">
                                                    <Link to={item?.link} target='_blank' className='d-flex mt-3 justify-content-between w-100 align-items-center text-decoration-none'>
                                                        <div className='textAnchor'>{item?.linkName}</div>
                                                        <div className="arrow text-center">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="7"
                                                                height="13"
                                                                viewBox="0 0 7 13"
                                                                fill="none"
                                                            >
                                                                <path d="M6.99999 6.12543C7.00065 6.01034 6.97859 5.89624 6.93507 5.78969C6.89155 5.68314 6.82742 5.58622 6.74637 5.5045L1.49906 0.257198C1.33438 0.092517 1.11102 4.85644e-08 0.878129 3.83843e-08C0.645235 2.82041e-08 0.421879 0.092517 0.257198 0.257198C0.092517 0.421879 -2.82041e-08 0.645235 -3.83843e-08 0.878129C-4.85644e-08 1.11102 0.0925169 1.33438 0.257198 1.49906L4.89232 6.12543L0.265943 10.7518C0.122669 10.9191 0.0478016 11.1343 0.0563032 11.3544C0.0648049 11.5745 0.156049 11.7833 0.311802 11.9391C0.467555 12.0948 0.676344 12.1861 0.896448 12.1946C1.11655 12.2031 1.33176 12.1282 1.49906 11.9849L6.74637 6.73762C6.90793 6.57473 6.99902 6.35486 6.99999 6.12543Z" fill="#EFB90B" />
                                                            </svg>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                        {
                            pageNo < totalPage && 
                            <button className='loadMoreNotiBtn mb-3' onClick={()=>loadMore()}>{notificationModal.load_more}</button>
                        }
                        </>
                    ) : (
                        <>
                            <div className="col-12 noNotificationCard flex-fill d-flex align-items-center justify-content-center flex-column">
                                <img src={NoProductFound} className='w-100' alt="" />
                                <div className="txxxtttt mt-3">
                                {notificationModal.No_Notifications_Yet}
                                </div>
                            </div>
                        </>
                    )
                }
                {/* 
                <div className="col-12 mb-3">
                    <div className="offcanvasTextWrapper">
                        <div className="row">
                            <div className="col-12"><div className="date">23/12/2024, 10:56:19</div></div>
                            <div className="col-12"><div className="time">🛑 Update: 10 Free Spins on Sea Boat Adventure 🛑</div></div>
                            <div className="col-12 mt-1"><div className="text">Due to technical issues with Sea Boat Adventures free rounds,</div></div>
                            <div className="col-12">
                                <div class="text-end w-100 collapseBtn p-0 collapsed" data-bs-toggle="collapse" data-bs-target="#collapseExamplee" aria-expanded="true" aria-controls="collapseExamplee">
                                    Show all
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                        <path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="white" />
                                    </svg>
                                </div>
                                <div class="collapse show" id="collapseExamplee">
                                    <div class="card card-body collapseBody p-0">
                                        <div className="row mx-0">
                                            <div className="col-12 px-0">
                                                <div className="anchorDivider my-3"></div>
                                                <Link to={'/'} className='d-flex justify-content-between w-100 align-items-center text-decoration-none'>
                                                    <div className='textAnchor'>Refer to the complete update here</div>
                                                    <div className="arrow text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                                                            <path d="M6.99999 6.12543C7.00065 6.01034 6.97859 5.89624 6.93507 5.78969C6.89155 5.68314 6.82742 5.58622 6.74637 5.5045L1.49906 0.257198C1.33438 0.092517 1.11102 4.85644e-08 0.878129 3.83843e-08C0.645235 2.82041e-08 0.421879 0.092517 0.257198 0.257198C0.092517 0.421879 -2.82041e-08 0.645235 -3.83843e-08 0.878129C-4.85644e-08 1.11102 0.0925169 1.33438 0.257198 1.49906L4.89232 6.12543L0.265943 10.7518C0.122669 10.9191 0.0478016 11.1343 0.0563032 11.3544C0.0648049 11.5745 0.156049 11.7833 0.311802 11.9391C0.467555 12.0948 0.676344 12.1861 0.896448 12.1946C1.11655 12.2031 1.33176 12.1282 1.49906 11.9849L6.74637 6.73762C6.90793 6.57473 6.99902 6.35486 6.99999 6.12543Z" fill="#EFB90B" />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        {/* <div className="col-12 d-flex align-item-center ">
          <div className="col-12 d-flex flex-column  notification_section">
            <span className="date"> 23/12/2024, 10:56:19</span>
            <h2 className="heading">Its Weekly Sports Bonus Time!</h2>
            <div className="notification_heading_image">
              <img src="assets\img\notification_heading.jpeg" alt="" />
            </div>
            <p className="heading_p">
              Weekly Sports bonus has been dropped successfully!
            </p>

            <div className="d-flex justify-content-end ">
              <button
                className="show_btn text-white"
                onClick={() => setIsOpen1(!isOpen1)}
              >
                {isOpen1 ? "Hide all" : "Show all"}
              </button>
            </div>

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="headingOne"
                  style={{ borderBottom: "none " }}
                >
                  <button
                    className="accordion-button px-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne1"
                    aria-expanded="true"
                    aria-controls="collapseOne1"
                  ></button>
                </h2>
                <div
                  id="collapseOne1"
                  className={`accordion-collapse collapse ${
                    isOpen1 ? "show" : ""
                  }`}
                  aria-labelledby="headingOne1"
                >
                  <div className="accordion-body">
                    <ul className="notification_ul p-0">
                      <li className="item">
                        - Wager period Saturday January 6th to Friday January
                        12th
                      </li>
                      <li className="item">- Minimum VIP level 22+</li>
                      <li className="item">- Wager $500 or more = $5 bonus</li>
                      <li className="item">
                        - Wager $2,500 or more = $30 bonus
                      </li>
                      <li className="item">
                        - Wager $5,000 or more = $70 bonus
                      </li>
                      <li className="item">
                        - Wager $10,000 or more = $150 bonus
                      </li>
                      <p className="mt-2">
                        Grab your weekly bonus boost every Saturday with{" "}
                        <strong>DAMBLE Sports Club!</strong>
                      </p>
                      <span className="mt-3">DAMBLE</span>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button px-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded={isOpen1}
                    aria-controls="collapseTwo"
                  >
                    <p>
                      Weekly Sports Bonus? What is this? How can I take part?
                    </p>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${
                    isOpen1 ? "show" : ""
                  }`}
                  aria-labelledby="headingTwo"
                >
                  <div className="accordion-body">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempora perspiciatis illum ullam nesciunt odit ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div className="col-12 d-flex flex-column  notification_section">
            <span className="date"> 23/12/2024, 10:56:19</span>
            <h2 className="heading">
              Update: 10 Free Spins on Sea Boat Adventure
            </h2>
            <h4 className="heading_p">
              Due to technical issues with Sea Boat Adventures free rounds,
            </h4>
            <div className="d-flex justify-content-end ">
              <button
                className="show_btn text-white"
                onClick={() => setIsOpen2(!isOpen2)}
              >
                {isOpen2 ? "Hide all" : "Show all"}
              </button>
            </div>

            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingthree">
                  <button
                    class="accordion-button px-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsethree"
                    aria-expanded={isOpen2}
                  >
                    <p>Refer to the complete update here</p>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="collapsethree"
                  className={`accordion-collapse collapse ${
                    isOpen2 ? "show" : ""
                  }`}
                  aria-labelledby="headingthree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempora perspiciatis illum ullam nesciunt odit ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="col-12 d-flex flex-column  notification_section">
            <span className="date"> 23/12/2024, 10:56:19</span>
            <h2 className="heading">
              Update: 10 Free Spins on Sea Boat Adventure
            </h2>
            <h4 className="heading_p">
              Due to technical issues with Sea Boat Adventures free rounds,
            </h4>
            <div className="d-flex justify-content-end ">
              <button
                className="show_btn text-white"
                onClick={() => setIsOpen3(!isOpen3)}
              >
                {isOpen3 ? "Hide all" : "Show all"}
              </button>
            </div>

            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button px-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded={isOpen3}
                  >
                    <p>Refer to the complete update here</p>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 320 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                      </svg>
                    </span>
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  className={`accordion-collapse collapse ${
                    isOpen3 ? "show" : ""
                  }`}
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Tempora perspiciatis illum ullam nesciunt odit ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div> */}
      </div>
    </>
  );
};

export default System;
