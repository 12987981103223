import React, { useEffect, useState } from "react";

import { Link, useSearchParams } from "react-router-dom";
import crypto from "./../../assets/images/depositeCrypto.png";
import { useWalletModal } from "../../hooks/useWallet";
import { useDispatch, useSelector } from "react-redux";

import { Spinner } from "react-bootstrap";
import closeIcon from "./../../assets/images/depositModal/close.webp";
import coinLogo6 from "./../../assets/images/depositModal/coinLogo6.webp";
import coinLogo7 from "./../../assets/images/depositModal/coinLogo7.webp";
import coinLogo8 from "./../../assets/images/depositModal/coinLogo8.webp";
import coinLogo9 from "./../../assets/images/depositModal/coinLogo9.webp";
import down from "./../../assets/images/depositModal/downIcon.webp";

import giftIcon from "./../../assets/images/depositModal/giftIcon.webp";

import copyIcon from "./../../assets/images/depositModal/copyIcon.webp";
import infoIcon from "./../../assets/images/depositModal/infoIcon.webp";

import { getAPIAuth } from "../../services/apiInstance";
import { useAuth } from "../../hooks/useAuth";
import { walletBuyAction } from "../../store/action";
import parse from "html-react-parser";
import { succesToaster } from "../../utils/toaster";
import QRCode from "react-qr-code";
const DepositWalletTab = () => {
  const [query, setQuery] = useSearchParams();
  const dispatch = useDispatch();
  const { setWalletModalObject, walletModalObject } = useWalletModal();
  const userDetails = useSelector((state) => state.userDetails);
  const { token } = useAuth();
  const [search, setSearch] = useState("");
  const [networkList, setNetworkList] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [isHideZero, setIsHideZero] = useState(false);
  const [finalCryptoList, setFinalCryptoList] = useState([]);
  const [selectedCryptoList, setSelectedCryptoList] = useState("");
  const [isActive1, setIsActive1] = useState(false);
  const [selectedNetworkList, setSelectedNetworkList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [depositAddData, setDepositAddData] = useState({});
  const [favCrypto, setFavCrypto] = useState([]);
  const [isAddressLoading, setisAddressLoading] = useState(false);
  const [] = useState({});
  const [cryptoOffer, setCryptoOffer] = useState(null);

  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-crypto?userId=${
          userDetails?.id
        }&crypto=${search.toUpperCase()}`,
        token
      );
      // const res = await getAPIAuth(`crypto/get-crypto?crypto=${search.toUpperCase()}`, token);
      if (res?.data?.success) {
        setCryptoList(res.data.data);
        setFinalCryptoList(res.data.data);
        if (!crypto?._id) {
          setNetworkList(res.data?.data[1]?.allNetworks);
        }
        // setNetworkList(cryptoList[0]?.allNetworks)
        if (!search && !crypto?._id) {
          setSelectedCryptoList(res.data?.data[1]);
          setSelectedNetworkList(
            res.data?.data[1]?.allNetworks[
              res.data?.data[1]?.allNetworks.findIndex(
                (item) => (item.name = "BEP20")
              )
            ]
          );
        }
      }
    } catch (error) {}
  };

  const getFavCrypto = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-fav-currency-crypto?type=crypto&userId=${userDetails?.id}`,
        token
      );

      if (res.data.success) {
        setFavCrypto(res.data.crypto);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAvailableCrypto();
    // getNetworkCrypto();
  }, [search, userDetails?.id]);

  const handleButtonClick = () => {
    setIsActive(!isActive);
    setIsActive1(false);
  };

  const filterCryptoNetworks = async (index, list) => {
    setSelectedCryptoList(list);
    const newList = cryptoList[index]?.allNetworks;
    setNetworkList(newList);
    setSelectedNetworkList(newList[0]);
    handleButtonClick();
  };

  const getWalletAddress = async () => {
    setisAddressLoading(true);
    try {
      const res = await getAPIAuth(
        `getAddress?coinId=${selectedCryptoList?._id}&networkId=${selectedNetworkList?._id}`
      );
      if (res.data?.success) {
        setDepositAddData(res.data.data);
        dispatch(walletBuyAction(res.data.data.address));
      } else {
        setDepositAddData({});
      }
    } catch (error) {
      // err
      setDepositAddData({});
    } finally {
      setisAddressLoading(false);
    }
  };

  const handleClose = () => {
    setWalletModalObject((pre) => ({ ...pre, isWalletOpen: false }));
    if (query.get("action") == "wallet" && !walletModalObject.isWalletOpen) {
      setQuery({ action: "" });
    }
  };

  const handleButtonClick1 = () => {
    setIsActive1(!isActive1);
    setIsActive(false);
  };

  useEffect(() => {
    if (selectedCryptoList?._id && selectedNetworkList?._id) {
      getWalletAddress();
    }
  }, [selectedNetworkList, selectedCryptoList]);
  const getCrytoOffer = async () => {
    try {
      const res = await getAPIAuth("crypto/get-crypto-offer", token);
      if (res.data.success) {
        setCryptoOffer(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCrytoOffer();
  }, []);

  useEffect(() => {
    if (userDetails) {
      getFavCrypto();
    }
  }, [userDetails]);

  const handleCopy = () => {
    if (depositAddData?.address?.length) {
      navigator.clipboard.writeText(depositAddData?.address);
      succesToaster("Address copied");
    }
  };

  return (
    <div className="depositModalWrapper">
      <div className="modal-dialog">
        <div className="modal-content h-100">
          <div className="depositHeader d-flex align-items-center justify-content-between text-white ">
            <div className="text fw-bold">Deposit</div>
          </div>
          <div className="depositMain">
            <ul
              class="nav nav-tabs gap-1 flex-nowrap"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item rounded-pill" role="presentation">
                <button
                  class="nav-link w-100 active rounded-pill"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Crypto
                </button>
              </li>
              <li class="nav-item rounded-pill" role="presentation">
                <button
                  class="nav-link w-100 rounded-pill"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Fiat
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {favCrypto.length ? (
                  <div className="coinParent d-flex align-items-center justify-content-between gap-2 pb-2">
                    {favCrypto.map((crypto) => {
                      return (
                        <div
                          key={crypto._id}
                          className=" cursor-pointer coinParentInner d-flex align-items-center gap-1 rounded-pill"
                        >
                          <div className="coinIconparent">
                            <img
                              className="h-100 w-100 object-fit-cover d-flex"
                              src={crypto.icon}
                              alt=""
                            />
                          </div>
                          <div className="coinText">{crypto.name}</div>
                        </div>
                      );
                    })}

                    <div
                      onClick={handleButtonClick}
                      className=" cursor-pointer coinMoreBtn d-flex align-items-center justify-content-between gap-2 rounded-pill flex-grow-1"
                    >
                      <div className="iconsParent position-relative">
                        <img
                          className="position-absolute w-100 h-100"
                          src={coinLogo8}
                          alt=""
                        />
                        <img
                          className="coinLogo9 position-absolute w-100 h-100"
                          src={coinLogo9}
                          alt=""
                        />
                        <img
                          className="coinLogo8 position-absolute w-100 h-100"
                          src={coinLogo8}
                          alt=""
                        />
                        <img
                          className="coinLogo7 position-absolute w-100 h-100"
                          src={coinLogo7}
                          alt=""
                        />
                        <img
                          className="coinLogo6 position-absolute w-100 h-100"
                          src={coinLogo6}
                          alt=""
                        />
                      </div>
                      <div className="d-flex align-items-center gap-2 ps-3">
                        <div className="text text-white">More</div>
                        <img className="downIcon" src={down} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="light" size="sm" />
                  </div>
                )}

                {/* <div className="addHere d-flex align-items-center">
                  <div className="">Didn’t see your currency?</div>
                  <a href="#javascript"> Add here</a>
                </div> */}
                <div className="dropdownParent d-flex align-items-center">
                  {/* bitcoin dropdown */}

                  <div className="col-12 mb-3 px0">
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-sm-0 mb-2">
                        <div
                          className={` px-0 position-relative bitCoindropBtn ${
                            isActive ? "bitCoinDrop" : ""
                          }`}
                        >
                          <div className="currency-heading mb-2">
                            Deposit Currency
                          </div>
                          <div
                            onClick={handleButtonClick}
                            className="bitCoinBtn d-flex align-items-center justify-content-between"
                          >
                            <div className="d-flex align-items-center gap-2">
                              {selectedCryptoList?.name ? (
                                <>
                                  <div className="selected-icon">
                                    <img
                                      className="img-fluid"
                                      src={selectedCryptoList?.icon}
                                      alt=""
                                    />
                                  </div>
                                  <div className="text-white">
                                    {selectedCryptoList?.name}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                </>
                              )}
                            </div>

                            <div className="arrow-svg">
                              <svg
                                stroke="currentColor"
                                fill="#fff"
                                stroke-width="0"
                                viewBox="0 0 512 512"
                                height="20px"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                              </svg>
                            </div>
                          </div>
                          <div className="bitCoinDropDown">
                            <div className="row mx-0 overflow-hidden h-100">
                              <div className="col-12 px-0 overflow-y-auto bitListHeight">
                                <div className="searchInpDrop">
                                  <input
                                    className="inp"
                                    type="text"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                  />
                                  <svg
                                    className="searchImgg"
                                    stroke="#93A6C4"
                                    fill="#93A6C4"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                  </svg>
                                </div>
                                <ul className="m-0 p-0 d-flex flex-column gap-2 pb-2">
                                  {cryptoList?.map((item, index) => {
                                    console.log(
                                      item,
                                      isHideZero &&
                                        parseFloat(item?.totalBalance) === 0 &&
                                        parseFloat(item?.totalBonus) === 0
                                    );
                                    return (
                                      <li
                                        key={index}
                                        className={`cursor-pointer d-flex justify-content-between align-items-center dropList ${
                                          selectedCryptoList?._id === item._id
                                            ? "border-white"
                                            : ""
                                        } ${
                                          isHideZero &&
                                          parseFloat(item?.totalBalance) ===
                                            0 &&
                                          parseFloat(item?.totalBonus) === 0
                                            ? "d-none"
                                            : ""
                                        } `}
                                        onClick={() =>
                                          filterCryptoNetworks(index, item)
                                        }
                                      >
                                        <div className="btcText d-flex align-items-center">
                                          <img src={item.icon} alt="" />
                                          {item.name}
                                        </div>
                                        {/* <div className='btcDigit'>0.<span>00000000</span></div> */}
                                      </li>
                                    );
                                  })}
                                  {/* <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b2} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b3} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b4} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b5} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b6} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b7} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b8} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b9} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b10} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li>
                                                                <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b11} alt="" />BTC</div>
                                                                    <div className='btcDigit'>0.<span>00000000</span></div>
                                                                </li> */}
                                </ul>
                              </div>
                              <div className="col-12 px-0">
                                <div className="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                                  {/* <div className="footerTxt">
                                        Hide 0 Balance
                                      </div>
                                      <div>
                                        <Form>
                                          <Form.Check
                                            className="footerToggle"
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            checked={isHideZero}
                                            onChange={(e) =>
                                              setIsHideZero(e.target.checked)
                                            }
                                          />
                                        </Form>
                                      </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-12 mb-sm-0 mb-2">
                        <div
                          className={`px-0 position-relative nativedropBtn ${
                            isActive1 ? "nativeDrop" : ""
                          }`}
                        >
                          <div className="currency-heading mb-2">
                            Choose Network
                          </div>
                          <div
                            onClick={handleButtonClick1}
                            className="bitCoinBtn bitCoinBtnSecond border-0 active d-flex align-items-center justify-content-between"
                          >
                            {/* <img src={bitCoin} alt="" /> */}
                            <div>
                              {/* <span className="networkSpan text-white">Network</span> */}
                              <span className="nativeSpan text-white">
                                {selectedNetworkList?.name ? (
                                  selectedNetworkList?.name
                                ) : (
                                  <>
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="arrow-svg">
                              <svg
                                stroke="currentColor"
                                fill="#fff"
                                stroke-width="0"
                                viewBox="0 0 512 512"
                                height="20px"
                                width="20px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                              </svg>
                            </div>
                          </div>
                          <div className="nativeDropDown">
                            <div className="row mx-0 overflow-hidden h-100">
                              <div className="col-12 px-0 overflow-y-auto bitListHeight">
                                <ul className="m-0 p-0 d-flex flex-column gap-2 pb-2">
                                  {networkList?.map((item, index) => (
                                    <li
                                      key={index}
                                      className={`cursor-pointer d-flex justify-content-between align-items-center dropList
                                                                    
                                                                    `}
                                      onClick={() => {
                                        setSelectedNetworkList(item);
                                        handleButtonClick1();
                                      }}
                                    >
                                      <div className="btcText d-flex align-items-center">
                                        {/* <img src={bitCoin} alt="" /> */}
                                      </div>
                                      <div className="btcDigit">
                                        <span>{item.name}</span>
                                      </div>
                                    </li>
                                  ))}
                                  {/* <li className='d-flex justify-content-between align-items-center dropList'>
                                                                    <div className='btcText d-flex align-items-center'><img src={b2} alt="" /></div>
                                                                    <div className='btcDigit'><span>BNB Chain</span></div>
                                                                </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* network dropDown */}

                  {/* <div className="dropdowninner">
                        <div className="dropdownText">Deposit Currency</div>
                        <div className="dropdown rounded-pill">
                          <button
                            className="btn  dropdown-toggle d-flex align-items-center justify-content-between w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="iconParent d-flex align-items-center">
                              <img src={dropdownIcon} alt="" />
                              <div className="text-white">SHIP</div>
                            </div>
                            <div className="iconParent2 d-flex align-items-center justify-content-center rounded-circle">
                              <img src={down} alt="" />
                            </div>
                          </button>
                          <ul
                            className="dropdown-menu w-100"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Action
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Another action
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="dropdowninner">
                        <div className="dropdownText">Choose Network</div>
                        <div className="dropdown rounded-pill">
                          <button
                            className="btn  dropdown-toggle d-flex align-items-center justify-content-between w-100"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <div className="iconParent d-flex align-items-center">
                              <div className="text-white">ERC20</div>
                            </div>
                            <div className="iconParent2 d-flex align-items-center justify-content-center rounded-circle">
                              <img src={down} alt="" />
                            </div>
                          </button>
                          <ul
                            className="dropdown-menu w-100"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Action
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Another action
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item text-white"
                                href="#javascript"
                              >
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                </div>
                <div className="bonusBox d-flex align-items-center ">
                  <img src={giftIcon} alt="" />
                  <div className="text text-white">
                    {cryptoOffer?.description
                      ? parse(cryptoOffer?.description)
                      : ""}
                  </div>
                </div>
                <div className="scannerSection d-flex align-items-center">
                  <div className="scanner  d-flex align-items-center justify-content-center  ">
                    {
                      isAddressLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : depositAddData?.address ? (
                        <QRCode
                          size={100}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={depositAddData?.address}
                          viewBox={`0 0 256 256`}
                        />
                      ) : (
                        ""
                      )
                      // <img className="h-100 w-100" src={scanner} alt="" />
                    }
                  </div>

                  <div className="rightSection flex-grow-1">
                    <div className="childFirst">
                      <div className="text">Deposit Currency</div>

                      <div className="inputContainer d-flex align-items-center justify-content-center  rounded-pill">
                        {isAddressLoading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          <div className="input-address">
                            {depositAddData?.address}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={handleCopy}
                      className="childSecond d-flex align-items-center justify-content-center rounded-pill"
                    >
                      <div className="text text-white">Copy Address</div>
                      <img src={copyIcon} alt="" />
                    </button>
                  </div>
                </div>
                <div className="infoBox d-flex align-items-center">
                  <img src={infoIcon} alt="" />
                  <div className="text text-white">
                    Send only {selectedCryptoList?.name?.toUpperCase()} to this
                    address. Coins will be deposited after 2 network
                    confirmations. Transfers below 0.00001{" "}
                    {selectedCryptoList?.name?.toUpperCase()} will not be
                    credited.
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                {/* <div >
                        <div className="DepositCurrency d-flex align-items-center justify-content-between gap-2">
                        <div className="text text-white">Deposit Currency</div>
                        <div className="dropdownParent">
                            <div className="dropdown rounded-pill">
                            <button
                                className="btn  dropdown-toggle d-flex align-items-center justify-content-between w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="iconParent d-flex align-items-center">
                                <img src={EURicon} alt="" />
                                <div className="text-white">EUR</div>
                                </div>
                                <div className="iconParent2 d-flex align-items-center justify-content-center rounded-circle">
                                <img src={down} alt="" />
                                </div>
                            </button>
                            <ul
                                className="dropdown-menu w-100 "
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Action
                                </a>
                                </li>
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Another action
                                </a>
                                </li>
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Something else here
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="DepositMethod">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="text1">Deposit Method</div>
                            <a className="text2 text-white" href="#javascript">
                            How to make deposit?
                            </a>
                        </div>
                        <div className="dropdownParent">
                            <div className="dropdown rounded-pill">
                            <button
                                className="btn  dropdown-toggle d-flex align-items-center justify-content-between w-100"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div className="iconParent d-flex align-items-center">
                                <img src={depositMethod} alt="" />
                                </div>
                                <div className="iconParent2 d-flex align-items-center justify-content-center rounded-circle">
                                <img src={down} alt="" />
                                </div>
                            </button>
                            <ul
                                className="dropdown-menu w-100"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Action
                                </a>
                                </li>
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Another action
                                </a>
                                </li>
                                <li>
                                <a
                                    className="dropdown-item text-white"
                                    href="#javascript"
                                >
                                    Something else here
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        <div className="bonusBox2 d-flex align-items-center ">
                        <img src={giftIcon} alt="" />
                        <div className="text text-white">
                            Get extra<span className="goldenText"> 180%</span>  bonus on
                            minimum of <span className="goldenText">f €9.59</span>
                              deposit
                        </div>
                        </div>
                        <div className="inputBox d-flex flex-column">
                        <div className="box1">
                            <label htmlFor="email">Email</label>
                            <div className="inputParent1 rounded-pill">
                            <input
                                className="w-100 text-white h-100"
                                type="text"
                                id="email"
                            />
                            </div>
                        </div>
                        <div className="box2">
                            <label htmlFor="number">100 - 1,000 EUR</label>
                            <div className="inputParent2 d-flex align-items-center gap-2 rounded-pill">
                            <input
                                className="h-100 text-white"
                                type="text"
                                id="number"
                            />
                            <div className="text">Extra +€180.00</div>
                            </div>
                        </div>
                        </div>
                        <div className="amountBoxParent d-flex align-items-center">
                        <div className="amountBox1 active rounded-pill d-flex align-items-center">
                            <div className="amount d-flex align-items-center justify-content-end gap-2 text-white rounded-start-pill">
                            <img src={currencySign} alt="" />
                            <div>$100.00</div>
                            </div>
                            <div className="percentage d-flex align-items-center justify-content-center text-white rounded-end-pill">
                            +180%
                            </div>
                        </div>
                        <div className="amountBox1 rounded-pill d-flex align-items-center">
                            <div className="amount d-flex align-items-center justify-content-end gap-2 text-white rounded-start-pill">
                            <img src={currencySign} alt="" />
                            <div>$100.00</div>
                            </div>
                            <div className="percentage d-flex align-items-center justify-content-center text-white rounded-end-pill">
                            +180%
                            </div>
                        </div>
                        </div>
                        <div className="infoBox d-flex align-items-center">
                        <img src={infoIcon} alt="" />
                        <div className="text-white">
                            Please verify your identity and phone and mail before making
                            a deposit.
                        </div>
                        </div>
                        <button className="depositBtn rounded-pill text-white w-100">
                        Deposit
                        </button>
                        <div className="discriptionBox">
                        <div className="">
                            1. Your transfer amount has to MATCH the submission amount.
                        </div>
                        <div className="">
                            2. Each Order ID can ONLY be used once to avoid duplicates.
                        </div>
                        <div className="">
                            3. Please follow the deposit guideline to make deposit,
                            otherwise your deposit will be missing.
                        </div>
                        </div>
                    </div> */}
                <div>
                  <h2 className="text-white text-center mt-5">Coming Soon</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositWalletTab;
