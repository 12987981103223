import React, { useState } from "react";
import dollar from "./../../assets/images/dollarBonus.png";
import rightArrow from "./../../assets/images/rightArrowgreen.png";
import right from "./../../../src/assets/images/bonus/rightbtn.png";
import qImg from "../../assets/images/bonus/rightbtn.png";
import infoicon from "../../assets/images/bonus/info.png";
import infoicon2 from "../../assets/images/bonus/info2.png";
import box1 from "../../assets/images/bonus/bonusbox.png";
import box2 from "../../assets/images/bonus/surprizebox.png";
import locker from "../../assets/images/bonus/locker.png";
import open from "../../assets/images/bonus/Open.png";
import card_1 from "../../assets/images/bonus/card_1.png";
import emailVarification from "../../assets/images/bonus/emailVarification.png";
import phoneVerification from "../../assets/images/bonus/phoneVerification.png";
import telegramVerification from "../../assets/images/bonus/telegramVerification.png";
import spin from "../../assets/images/bonus/spin.png";
import star from "../../assets/images/bonus/star.png";
import pbank from "../../assets/images/bonus/pigybank.png";
import money from "../../assets/images/bonus/moneybag.png";
import vip from "../../assets/images/bonus/vip.png";
import lock from "../../assets/images/bonus/lock.png";
import rocket from "../../assets/images/bonus/rocket.png";
import football from "../../assets/images/bonus/football.png";
import calender_1 from "../../assets/images/bonus/calender-1.png";
import calender_2 from "../../assets/images/bonus/calender.png";
import fileEmpty from "../../assets/images/fileEmpty.svg";

import bonus from "../../assets/images/bonus/bonus.png";
import huntImg from "../../assets/images/bonushunt.png";
import spinwheel from "../../assets/images/spinWheel.png";
import clock from "../../assets/images/clock.png";
import upVip from "../../assets/images/upVip.png";
import arrowplan from "../../assets/images/arrowplan.png";
import vipcalender from "../../assets/images/vipcalender.png";
import greencalendervip from "../../assets/images/greencalendervip.png";
import ball from "../../assets/images/ball.png";
import IsolationMode from "../../assets/images/Isolation_Mode.png";
import BonusDetails from "../../components/ModalSection/BonusDetails";
import DepositBonusRules from "../../components/ModalSection/DepositBounseRoule";
import Hunthub from "../../components/ModalSection/Hunthub";
import OnekRace from "../../components/ModalSection/OnekRace";
import RaceStats from "../../components/ModalSection/RaceStats";
import DBLRackBack from "../../components/ModalSection/Dblrackback";
import DBLBonusHistory from "../../components/ModalSection/DblBounseHistory";
import { errorToaster } from "../../utils/toaster";
import { useSelector } from "react-redux";
import { useClaimBonus } from "../../hooks/useClaimBonus";
import {
  CLAIM_BONUS_OPTIONS,
  GAME_OPTION_TABS,
  ROUTE_CONST,
  WALLET_MODAL_TABS,
} from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWalletModal } from "../../hooks/useWallet";
import { useGames } from "../../hooks/useGame";
import TimerComponent from "../../components/timerComponent/TimerComponent";

function Bonus() {
  const [isBonusDetails, setIsBonusDetails] = useState(false);
  const [isBonusRules, setIsBonusRules] = useState(false);
  const [isHuntsHub, setIsHuntsHub] = useState(false);
  const [isOneRake, setIsOneRake] = useState(false);
  const [isRaceStats, setIsRaceStats] = useState(false);
  const [isDBKRaceBack, setIsDBKRaceBack] = useState(false);
  const [isDBLBonusHistory, setIsDBLBonusHistory] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails);
  const userBonusDetail = useSelector((state) => state?.userBonusDetail);
  const { setClaimBonusObject } = useClaimBonus();
  const [query, setQuery] = useSearchParams();
  const { setWalletModalObject } = useWalletModal();
  const { setGameObject } = useGames();
  const navigate = useNavigate();

  return (
    <>
      <div className="bonus-page">
        <div className="row justify-content-between align-items-center py-3 py-md-4 gy-3">
          <div className="col-12">
            <div className="heading">BONUS</div>
          </div>
        </div>
        <div className="row d-flex align-items-stretch gy-4 gy-md-0">
          <div className="col-md-6 col-12 h-auto">
            <div className="bonuscard row position-relative h-100 mx-0">
              <div className="col-lg-8 col-10">
                <div className="bg-layer">
                  <div className="tittle position-relative z-2">
                    Total Bomus Claimed (USD)
                  </div>
                  <div className="text-base position-relative	z-2">$0.00</div>
                  <div className="d-flex justify-content-between mt-sm-3 mt-2 position-relative z-2">
                    <div>
                      <div className="textxs">Total VIP Bonus</div>
                      <div className="text-sm">$0.00</div>
                    </div>
                    <div>
                      <div className="textxs">Total VIP Bonus</div>
                      <div className="text-sm">$0.00</div>
                    </div>
                    <div>
                      <div className="textxs">Total general Bonus</div>
                      <div className="text-sm">$0.00</div>
                    </div>
                  </div>

                  <div className="layer"></div>
                </div>

                <button className="detailbtn">
                  Details
                  <span className="right">
                    <img src={right} alt="" className="h-100 w-100" />
                  </span>
                </button>
              </div>
              <div className="col-lg-4"></div>
              <div className="position-absolute bonus-img">
                <img src={bonus} alt="" className="h-100 w-100" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 h-auto">
            <div className="bonuscard h-100">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex  align-items-end gap-4">
                  <div className="text-base">Deposit Bonus</div>
                  <div className="text-2 d-flex align-items-center gap-1">
                    Get up to : <span className="text-3"> 20,000 USD</span>
                  </div>
                </div>
                <div className="iconinfo">
                  <img src={infoicon} alt="" className="w-100 h-100" />
                </div>
              </div>
              <div className="process">
                <div>
                  <div className="treasure">
                    <img src={box1} alt="" className="h-100 w-100" />
                  </div>
                </div>
                <div>
                  <div className="surprize">
                    <img src={box2} alt="" className="h-100 w-100" />
                  </div>
                </div>
                <div>
                  <div className="surprize">
                    <img src={box2} alt="" className="h-100 w-100" />
                  </div>
                </div>
                <div>
                  <div className="surprize">
                    <img src={box2} alt="" className="h-100 w-100" />
                  </div>
                </div>
              </div>
              <div
                className="proceesbar d-flex flex-row-reverse justify-content-between"
                style={{ "--width": "33%" }}
              >
                {/* width can be 0%, 33%, 66%, 100% */}
                <div className="one active"></div>
                <div className="one "></div>
                <div className="one "></div>
                <div className="one treasure-2"></div>
              </div>
              <div className="d-flex justify-content-between bonusnum">
                <div>+180%</div>
                <div>+240%</div>
                <div>+300%</div>
                <div>+360%</div>
              </div>
              <div className="bonusEnd d-flex justify-content-between">
                <div className="text-2 d-flex align-items-center gap-1">
                  Get up to : <span className="text-3"> 20,000 USD</span>
                </div>
                <button className="depositBtn">Deposit Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3 general-bonus-container">
          <div className="heading py-4">General Bonus</div>
          <div className="col-12">
            <div className="row g-3 mx-0 align-items-stretch">
              <div className="col-md-5 col-12 h-auto">
                <div className="claimCard h-100">
                  <div className="voult mx-auto">
                    <img src={locker} alt="voult img" className="h-100 w-100" />
                  </div>
                  <div className="claimDetail">
                    <div className="atext">
                      Bank{" "}
                      <span>
                        <img src={infoicon2} alt="" className="h-100 w-100" />
                      </span>
                    </div>
                    <div className="btext">$0.00</div>
                  </div>
                  <div className="claimbar">
                    <div className="dulltext">$0.00</div>
                    <button className="claimbtn">Claim</button>
                  </div>
                  <div className="claimtext">Claim every 8 hours</div>
                </div>
              </div>
              <div className="col-md-7 col-12 h-auto">
                <div className="claimshedule h-100">
                  <div className="d-flex justify-content-between">
                    <div className="">Bank Schedule</div>
                    <div className="d-flex">
                      <div className="dropbtn">
                        <img src={open} alt="" className="h-100 w-100" />
                      </div>
                      <div className="dropbtn rotate-90">
                        <img src={open} alt="" className="h-100 w-100" />
                      </div>
                    </div>
                  </div>
                  <div className="bonusDay">
                    <div className="bonusplate d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="number">1</div>
                        <div className="save">
                          <div className="day">Saturday</div>
                          <div className="month">January</div>
                        </div>
                      </div>
                      <div className="nobonus">No Bonus</div>
                    </div>
                    <div className="bonusplate d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="number">1</div>
                        <div className="save">
                          <div className="day">Saturday</div>
                          <div className="month">January</div>
                        </div>
                      </div>
                      <div className="nobonus">No Bonus</div>
                    </div>
                    <div className="bonusplate d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="number">1</div>
                        <div className="save">
                          <div className="day">Saturday</div>
                          <div className="month">January</div>
                        </div>
                      </div>
                      <div className="nobonus">No Bonus</div>
                    </div>
                    <div className="bonusplate d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="number">1</div>
                        <div className="save">
                          <div className="day">Saturday</div>
                          <div className="month">January</div>
                        </div>
                      </div>
                      <div className="nobonus">No Bonus</div>
                    </div>
                    <div className="bonusplate d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="number">1</div>
                        <div className="save">
                          <div className="day">Saturday</div>
                          <div className="month">January</div>
                        </div>
                      </div>
                      <div className="nobonus">No Bonus</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row general-bonus-container depositBounsModal pt-2 pb-3">
          <div className="col-12">
            <div className="row g-3 m-0">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img
                      src={emailVarification}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                  <div className="dep_text">Email Verification</div>
                  <div className="earnUsd">Earn 1 USDT</div>
                  <div className="  mt-0 bonus_detail">
                    Verify your email now to protect your account.
                    <button className="deposit_btn common-top">Verify</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img
                      src={phoneVerification}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                  <div className="dep_text">Phone Verification </div>
                  <div className="earnUsd">Earn 1 USDT</div>
                  <div className="  mt-0 bonus_detail">
                    Verify your phone number now to protect your account.
                    <button className="deposit_btn common-top">Verify</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img
                      src={telegramVerification}
                      alt=""
                      className="h-100 w-100"
                    />
                  </div>
                  <div className="dep_text">Telegram Verification </div>
                  <div className="earnUsd">Earn 1 USDT</div>
                  <div className="  mt-0 bonus_detail">
                    Verify your phone number now to protect your account.
                    <button className="deposit_btn common-top">Verify</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img src={card_1} alt="" className="h-100 w-100" />
                  </div>
                  <div className="dep_text">Quests</div>
                  <div className="bonus_detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Daily Quests</div>
                      <div>
                        0<span className="fadeText">/3</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <div>Weekly Quests</div>
                      <div>
                        0<span className="fadeText">/1</span>
                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-center align-items-center stopwatch'>
                                            <div>Ready in <span className='fadeText'>12h 22m 57s</span></div>
                                        </div> */}
                    <button className="deposit_btn common-top">Claim</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img src={spin} alt="" className="h-100 w-100" />
                  </div>
                  <div className="dep_text">Free Spins</div>
                  <div className="bonus_detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>VIP Spin:</div>
                      <div>
                        Reach VIP 1<span className="fadeText"></span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <div>Daily Spin:</div>
                      <div>
                        $0.00<span className="fadeText">/$200.00</span>
                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-center align-items-center stopwatch'>
                                            <div>Ready in <span className='fadeText'>12h 22m 57s</span></div>
                                        </div> */}
                    <button className="deposit_btn common-top">Claim</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img src={star} alt="" className="h-100 w-100" />
                  </div>
                  <div className="dep_text">Roll Competition</div>
                  <div className="bonus_detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Daily Quests</div>
                      <div>
                        0<span className="fadeText">/3</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center stopwatch">
                      <div>
                        Ready in <span className="fadeText">12h 22m 57s</span>
                      </div>
                    </div>

                    <button className="deposit_btn watchtop">Claim</button>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img src={pbank} alt="" className="h-100 w-100" />
                  </div>
                  <div className="dep_text">Quests</div>
                  <div className="bonus_detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Daily Quests</div>
                      <div>
                        0<span className="fadeText">/3</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <div>Weekly Quests</div>
                      <div>
                        0<span className="fadeText">/1</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center stopwatch">
                      <div>
                        Ready in <span className="fadeText">12h 22m 57s</span>
                      </div>
                    </div>
                    <button className="deposit_btn common-top">Claim</button>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="deposit_card">
                  <div className="dImage">
                    <img src={money} alt="" className="h-100 w-100" />
                  </div>
                  <div className="dep_text">Quests</div>
                  <div className="bonus_detail">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>Daily Quests</div>
                      <div>
                        0<span className="fadeText">/3</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      <div>Weekly Quests</div>
                      <div>
                        0<span className="fadeText">/1</span>
                      </div>
                    </div>
                    <button className="deposit_btn common-top">Claim</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-between align-items-center py-3 py-md-4 gy-3">
          <div className="col-12">
            <div className="heading">VIP Bonus</div>
          </div>
        </div>
        <div className="row general-bonus-container depositBounsModal pt-2 pb-3">
          <div className="col-12">
            <div className="row g-3 m-0 align-items-stretch">
              <div className="col-xl-6 col-lg-4 col-md-6 col-12 h-auto">
                <div className="vipcard h-100">
                  <div className="text ms-auto d-flex justify-content-end">
                    View Level Up Details
                  </div>
                  <div className="row m-0 align-items-center">
                    <div className="col-3">
                      <div className="vipbonus">
                        <img src={vip} alt="" className="w-100 h-100" />
                      </div>
                    </div>
                    <div className="col-9">
                      <div classname="vipDetail">
                        <div className="viptext">VIP 0</div>
                        <div className="d-flex justify-content-between">
                          <div className="viptext-sm">Your VIP Progress</div>
                          <div className="viptext-sm d-flex align-items-center text-white">
                            0.00%
                            <span className=" infoImg ms-1">
                              <img
                                src={infoicon2}
                                alt=""
                                className="h-100 w-100"
                              />
                            </span>
                          </div>
                        </div>
                        <div class="progress progressBar">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          <div className="processbtn">
                            <div className="btninner"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between vipbottom">
                    <div className="viptext-sm">Your VIP Progress</div>
                    <div className="viptext-sm d-flex align-items-center">
                      0.00%
                    </div>
                  </div>
                  <div className="vipbutton">
                    <span className="d-flex lock">
                      <img src={lock} alt="" className="w-100 h-100" />
                    </span>
                    Available at VIP 22
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 h-auto">
                <div className="vipcard h-100">
                  <div className="rocketimg">
                    <img src={rocket} alt="" className="h-100 w-100" />
                  </div>
                  <div className="vipcardtext">Recharge</div>
                  <div className="cardbottom">
                    <div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <span className="d-flex lock">
                          <img src={lock} alt="" className="h-100 w-100" />
                        </span>
                        Available at VIP 22
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 h-auto">
                <div className="vipcard h-100">
                  <div className="position-relative">
                    <div className="rocketimg">
                      <img src={calender_1} alt="" className="h-100 w-100" />
                    </div>
                    <div className="position-absolute info-icon">
                      <svg
                        stroke="#fff"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="21px"
                        width="21px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="vipcardtext">Weekly Cashback</div>
                  <div className="cardbottom">
                    <div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <span className="d-flex lock">
                          <img src={lock} alt="" className="h-100 w-100" />
                        </span>
                        Available at VIP 22
                      </div>
                    </div>
                    <div>
                      Ends in <span className="text-white">12d 22h 57m</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 h-auto">
                <div className="vipcard h-100">
                  <div className="position-relative">
                    <div className="rocketimg">
                      <img src={calender_2} alt="" className="h-100 w-100" />
                    </div>
                    <div className="position-absolute info-icon">
                      <svg
                        stroke="#fff"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="21px"
                        width="21px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="vipcardtext">Monthly Cashback</div>
                  <div className="cardbottom">
                    <div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <span className="d-flex lock">
                          <img src={lock} alt="" className="h-100 w-100" />
                        </span>
                        Available at VIP 22
                      </div>
                    </div>
                    <div>
                      Ends in <span className="text-white">12d 22h 57m</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 h-auto">
                <div className="vipcard h-100">
                  <div className="position-relative">
                    <div className="rocketimg">
                      <img src={football} alt="" className="h-100 w-100" />
                    </div>
                    <div className="position-absolute info-icon">
                      <svg
                        stroke="#fff"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="21px"
                        width="21px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="vipcardtext">Sports Weekly Bonus</div>
                  <div className="cardbottom">
                    <div>
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <span className="d-flex lock">
                          <img src={lock} alt="" className="h-100 w-100" />
                        </span>
                        Available at VIP 22
                      </div>
                    </div>
                    <div>
                      Ends in <span className="text-white">12d 22h 57m</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row general-bonus-container depositBounsModal pb-md-5 pb-3">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="">
                <div className="heading ">Special Bonus</div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="special-arrow">
                  <i class="fa-solid fa-chevron-left "></i>
                </div>
                <div className="special-arrow">
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
              <div className="empty_img">
                <img src={fileEmpty} alt="" className="img-fluid" />
              </div>
              <div className="text-center">
                <div className="no-rewards-text">
                  Oops! There is no data yet!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BonusDetails isOpen={isBonusDetails} setIsOpen={setIsBonusDetails} />
      <DepositBonusRules isOpen={isBonusRules} setIsOpen={setIsBonusRules} />
      <Hunthub isOpen={isHuntsHub} setIsOpen={setIsHuntsHub} />
      <OnekRace
        isOpen={isOneRake}
        setIsOpen={setIsOneRake}
        isRaceStats={isRaceStats}
        setIsRaceStats={setIsRaceStats}
      />
      <RaceStats isOpen={isRaceStats} setIsOpen={setIsRaceStats} />
      <DBLRackBack
        // isOpen={isDBKRaceBack}
        // setIsOpen={setIsDBKRaceBack}
        // isDBLBonusHistory={isDBLBonusHistory}
        setIsDBLBonusHistory={setIsDBLBonusHistory}
      />
      <DBLBonusHistory
        isOpen={isDBLBonusHistory}
        setIsOpen={setIsDBLBonusHistory}
      />
    </>
  );
}

export default Bonus;
