import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis minus pariatur reprehenderit, velit dolore, molestias porro possimus natus, ut impedit iste similique? Rem eveniet earum rerum temporibus est doloremque similique aliquid ipsam quibusdam, veritatis quaerat cum iste deleniti nam repellat sapiente doloribus, qui at cupiditate illo repellendus! Fugiat reprehenderit officia aliquam ratione perferendis vitae earum!
    </div>
  )
}

export default PrivacyPolicy
