import { useEffect, useState } from "react";
import RoutesComp from "./Routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_MODAL_TABS,
  DEFAULT_LANG,
  isDev,
  ROUTE_CONST,
  SESSION_TOKEN,
  USER_LANG,
} from "./constants";
import { postAPIAuth } from "./services/apiInstance";
import { useAuth } from "./hooks/useAuth";
import i18next from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthModal } from "./hooks/useAuthModal";
import MasterInput from "./components/MasterInput/MasterInput";
import Intercom from "@intercom/messenger-js-sdk";

function App() {
  const { token } = useAuth();
  const userDetails = useSelector((state) => state.userDetails);
  const location = useLocation();
  const [referraldata, setReferral] = useState("");
  const [isAuth, setIsAuth] = useState(() =>
    sessionStorage.getItem(SESSION_TOKEN, SESSION_TOKEN) ? true : false
  );
  const navigate = useNavigate();
  const { setAuthModalObject } = useAuthModal();
  const dispatch = useDispatch();
  const referralFun = () => {
    setReferral(location.pathname);
  };
  const splitBySlash = referraldata.split("/");
  const lastPart = splitBySlash[splitBySlash.length - 1];
  const extractedStart = lastPart.startsWith("i-") ? "i-" : "";
  const extractedEnd = lastPart.endsWith("-n") ? "-n" : "";
  const middlePart = lastPart.substring(2, lastPart.length - 2);
  const modifiedLink = referraldata.replace(
    "/" + extractedStart + middlePart + extractedEnd,
    ""
  );

  // referral signup

  useEffect(() => {
    if (extractedStart && extractedEnd) {
      localStorage.setItem("middlePart", middlePart);
      setAuthModalObject((pre) => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.SIGN_UP,
      }));
      // navigate("/games", { state: { extractedStart: extractedStart } });
      navigate(ROUTE_CONST.HOME_PAGE, {
        state: { extractedStart: extractedStart },
      });
    } else {
      localStorage.setItem("middlePart", "");
    }
    referralFun();
  }, [extractedStart, extractedEnd]);

  // intercom

  // useEffect(() => {
  //   if (userDetails?.id) {
  //     const unixTimestamp = userDetails?.createdAt
  //       ? Math.floor(new Date(userDetails?.createdAt).getTime() / 1000)
  //       : "";
  //     const payload = {
  //       api_base: "https://api-iam.intercom.io",
  //       app_id: "zj9jr0kk",
  //       // user_hash: "a5ed72f1b71dbf5307eb71340bf8ca0818da3327c399bc8edce12c173a85fa48" // an Identity Verification user hash for your user
  //     };
  //     if (userDetails?.username) payload.name = userDetails?.username;
  //     if (userDetails?.email) payload.email = userDetails?.email;
  //     if (userDetails?.createdAt) payload.created_at = unixTimestamp;
  //     if (userDetails?.id) payload.user_id = userDetails?.id;
  //     // if (userDetails?.userHash) payload.user_hash = userDetails?.userHash;
  //     if (userDetails?.userHash)
  //       payload.user_hash =
  //         "ac8b667175e99060289f2f3eef39c1bb9bc169feb334d403e33d1564905bcdd3";

  //     console.log({ payload });
  //     // window?.Intercom("boot", payload);
  //     // Intercom.boot(payload);
  //     return () => {
  //       // Intercom.shutdown()
  //     };
  //   }
  // }, [userDetails?.id]);

  // useEffect(() => {
  //   const initialPayload = {
  //     api_base: "https://api-iam.intercom.io",
  //     app_id: "zj9jr0kk",
  //   };
  //   Intercom.boot(initialPayload);
  //   return () => {
  //     Intercom.shutdown();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (userDetails?.id) {
  //     const unixTimestamp = userDetails?.createdAt
  //       ? Math.floor(new Date(userDetails?.createdAt).getTime() / 1000)
  //       : "";
  //     const userPayload = {};
  //     if (userDetails?.username) userPayload.name = userDetails.username;
  //     if (userDetails?.email) userPayload.email = userDetails.email;
  //     if (userDetails?.createdAt) userPayload.created_at = unixTimestamp;
  //     if (userDetails?.id) userPayload.user_id = userDetails.id;
  //     if (userDetails?.userHash) userPayload.user_hash = userDetails.userHash;
  //     window.Intercom("update", userPayload);
  //   }
  // }, [userDetails]);

  // useEffect(() => {
  //   window?.Intercom("update");
  // }, [location.pathname]);

  const newLanguageHandler = async () => {
    const lang = localStorage.getItem(USER_LANG);
    if (userDetails?.id) {
      // setting users language from local storage
      try {
        const body = {
          language: lang,
        };
        const res = await postAPIAuth(
          `user/change-user-lang?userId=${userDetails?.id}`,
          body,
          token
        );
        if (res.data.success) {
          i18next.changeLanguage(lang);
          localStorage.setItem(USER_LANG, lang);
        }
      } catch (error) {
        // error
      }
    } else {
      if (!token && lang) {
        // using the cached language
        i18next.changeLanguage(lang);
      } else if (!token && !lang) {
        // setting default language as en
        i18next.changeLanguage(DEFAULT_LANG.symbol);
        localStorage.setItem(USER_LANG, DEFAULT_LANG.symbol);
      }
    }
  };

  useEffect(() => {
    if (userDetails?.id) {
      const unixTimestamp = userDetails?.createdAt
        ? Math.floor(new Date(userDetails?.createdAt).getTime() / 1000)
        : "";
      Intercom({
        app_id: "k2xn7821",
        user_id: userDetails?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: userDetails?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: userDetails?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: unixTimestamp, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    }
  }, [userDetails]);

  useEffect(() => {
    // language
    newLanguageHandler();
  }, [userDetails?.id, token]);

  if (isDev && !isAuth) {
    return <MasterInput isAuth={isAuth} setIsAuth={setIsAuth} />;
  }
  return (
    <>
      <RoutesComp />
    </>
  );
}

export default App;
